<template>
  <div class="VipWindow">
    <div class="VipImageDiv">
      <a-row>
        <a-space>
          <img src="../assets/vip.svg" style="width: 40px" />
          <h1>尊享永久会员</h1>
        </a-space>
      </a-row>
      <a-row>
        <a-typography-text type="secondary" style="color: #9f9f9f">
          解锁所有面试题解，一次性买断
        </a-typography-text>
        <a-typography-text type="secondary" style="color: #9f9f9f">
          使用AI生成题目和AI智能判题
        </a-typography-text>
      </a-row>
      <a-space style="margin-top: -10px">
        <a-typography-title bold style="color: #fcefc2">
          <!--          ¥29.9-->
          免费
        </a-typography-title>
        <a-badge
          text="限时优惠"
          style="margin-top: -20px; position: absolute"
        ></a-badge>
        <a-typography-text style="padding-top: 32px; color: #9f9f9f">
          /永久
        </a-typography-text>
        <a-typography-text style="padding-top: 32px; color: #9f9f9f" delete>
          ¥99
        </a-typography-text>
      </a-space>
      <a-row>
        <a-button
          shape="round"
          size="large"
          style="width: 300px; margin: 0 auto"
          @click="openVip"
          >立即开通
        </a-button>
      </a-row>
      <a-row style="margin-top: 10px">
        <a-typography-text type="secondary" style="color: #9f9f9f">
          开通前请先阅读
        </a-typography-text>
        <a-typography-text style="color: #fcefc2">
          《易答会员协议》
        </a-typography-text>
      </a-row>
    </div>
  </div>
</template>

<script setup lang="ts">
import { openVipUsingPost } from "@/request/api/userController";
import message from "@arco-design/web-vue/es/message";

const openVip = () => {
  openVipUsingPost().then((res) => {
    if (res.data.code == 0) {
      if (res.data.data) {
        message.success("开通成功");
      } else {
        message.success("已经开通过了");
      }
    } else {
      message.error("开通失败");
    }
  });
};
</script>

<style scoped>
.VipImageDiv {
  width: 260px;
  height: 260px;
  padding: 10px 20px 20px 20px;
  color: #fcefc2;
  background-color: rgb(66, 64, 57);
  border-radius: 20px;
}

h1 {
  margin-bottom: 20px;
  font-size: 24px;
}

h2 {
  margin-bottom: 10px;
  font-size: 18px;
}

.benefit-item h3 {
  margin-bottom: 10px;
  font-size: 16px;
}

button {
  padding: 10px 20px;
  color: #fff;
  background-color: #007bff;
  border: none;
  cursor: pointer;
}
</style>
