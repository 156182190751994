<template>
  <a-form
    :model="formParams"
    layout="inline"
    style="margin: 0 0 10px auto"
    label-align="left"
    auto-label-width
    @submit="search"
  >
    <a-form-item field="id" label="ID：">
      <a-input v-model="formParams.id" placeholder="请输入ID..." allow-clear />
    </a-form-item>
    <a-form-item label="排序：">
      <a-select
        v-model="searchParams.sortOrder"
        placeholder="排序..."
        default-value="descend"
        style="width: 80px"
      >
        <a-option value="ascend">正序</a-option>
        <a-option value="descend">倒序</a-option>
      </a-select>
    </a-form-item>
    <a-form-item field="userId" label="用户ID：">
      <a-input
        v-model="formParams.userId"
        placeholder="请输入..."
        allow-clear
      />
    </a-form-item>
    <a-form-item field="questionContent" label="题目内容：">
      <a-input
        v-model="formParams.questionContent"
        placeholder="请输入..."
        allow-clear
      />
    </a-form-item>
    <a-form-item field="appId" label="应用ID：">
      <a-input v-model="formParams.appId" placeholder="请输入..." allow-clear />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" html-type="submit" style="width: 120px"
        >搜索
      </a-button>
    </a-form-item>
  </a-form>
  <a-table
    :columns="columns"
    :data="dataList"
    :pagination="{
      showTotal: true,
      pageSize: searchParams.pageSize,
      current: searchParams.current,
      total,
    }"
    :stripe="true"
    :bordered="{ cell: true }"
    @page-change="onPageChange"
  >
    <template #questionContent="{ record }">
      <a-collapse>
        <a-collapse-item header="查看问题">
          <a-collapse
            accordion
            v-for="question in JSON.parse(record.questionContent as string)"
            :key="question.title"
          >
            <a-collapse-item :header="question.title">
              <div v-for="option in question.options" :key="option.key">
                {{ option.key }}:{{ option.value }} {{ option.score ?? "" }}
                {{ option.result ?? "" }}
              </div>
            </a-collapse-item>
          </a-collapse>
        </a-collapse-item>
      </a-collapse>
    </template>
    <template #createTime="{ record }">
      {{ dayjs(record.createTime).format("YYYY-MM-DD") }}
    </template>
    <template #updateTime="{ record }">
      {{ dayjs(record.updateTime).format("YYYY-MM-DD") }}
    </template>
    <template #optional="{ record }">
      <a-space>
        <a-button type="primary" @click="doUpdate(record.appId)">修改</a-button>
        <a-popconfirm content="确定要删除吗" @ok="doDelete(record.id)">
          <a-button status="danger">删除</a-button>
        </a-popconfirm>
      </a-space>
    </template>
    <template #tags="{ record }">
      <a-space wrap>
        <a-tag v-for="(tag, index) of record.tags" :key="index" color="green"
          >{{ tag }}
        </a-tag>
      </a-space>
    </template>
  </a-table>
</template>
<script setup lang="ts">
import { ref, watchEffect } from "vue";
import API from "@/api";
import {
  deleteQuestionUsingPost,
  listQuestionByPageUsingPost,
} from "@/api/questionController";
import message from "@arco-design/web-vue/es/message";
import dayjs from "dayjs";
import router from "@/router";

//初始值
const initParams = {
  current: 1,
  pageSize: 10,
  sortOrder: "ascend",
  sortField: "createTime",
};
//搜索参数
const formParams = ref<API.QuestionQueryRequest>({
  ...initParams,
});
//传递参数
const searchParams = ref<API.QuestionQueryRequest>({});
//数据
const dataList = ref<API.Question[]>([]);
//总值
const total = ref<number>(0);
/**
 * 加载数据
 */
const loadData = async () => {
  const response = await listQuestionByPageUsingPost(searchParams.value);
  if (response.data.code === 0) {
    dataList.value = response.data.data?.records || [];
    total.value = response.data.data?.total || 0;
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
};
/**
 * 搜索
 */
const search = () => {
  searchParams.value = { ...initParams, ...formParams.value };
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * 当分页变化时，改变搜索条件，触发数据加载
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

/**
 * 更新
 */
const doUpdate = (id: number) => {
  router.push(`/add/question/${id}`);
};

/**
 * 删除
 */
const doDelete = async (id: number) => {
  const res = await deleteQuestionUsingPost({ id });
  if (res.data.code === 0) {
    loadData();
  } else {
    message.error("删除失败，" + res.data.message);
  }
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "问题内容",
    dataIndex: "questionContent",
    slotName: "questionContent",
  },
  {
    title: "应用ID",
    dataIndex: "appId",
  },
  {
    title: "用户ID",
    dataIndex: "userId",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    slotName: "createTime",
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    slotName: "updateTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
  {
    title: "标签",
    slotName: "tags",
  },
];
</script>
