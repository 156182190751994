// @ts-ignore
/* eslint-disable */
import request from '@/request';

/** addInterviewQuestionBank POST /api/interviewQuestionBank/add */
export async function addInterviewQuestionBankUsingPost(
  body: API.InterviewQuestionBankAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseLong_>('/api/interviewQuestionBank/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** deleteInterviewQuestionBank POST /api/interviewQuestionBank/delete */
export async function deleteInterviewQuestionBankUsingPost(
  body: API.DeleteRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/interviewQuestionBank/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** getInterviewQuestionBankVOById GET /api/interviewQuestionBank/get/vo */
export async function getInterviewQuestionBankVoByIdUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getInterviewQuestionBankVOByIdUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseInterviewQuestionBankVO_>('/api/interviewQuestionBank/get/vo', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** listAllInterviewQuestionBank POST /api/interviewQuestionBank/list */
export async function listAllInterviewQuestionBankUsingPost(options?: { [key: string]: any }) {
  return request<API.BaseResponseListInterviewQuestionBank_>('/api/interviewQuestionBank/list', {
    method: 'POST',
    ...(options || {}),
  });
}

/** listInterviewQuestionBankByPage POST /api/interviewQuestionBank/list/page */
export async function listInterviewQuestionBankByPageUsingPost(
  body: API.InterviewQuestionBankQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageInterviewQuestionBank_>(
    '/api/interviewQuestionBank/list/page',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    },
  );
}

/** listInterviewQuestionBankVOByPage POST /api/interviewQuestionBank/list/page/vo */
export async function listInterviewQuestionBankVoByPageUsingPost(
  body: API.InterviewQuestionBankQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageInterviewQuestionBankVO_>(
    '/api/interviewQuestionBank/list/page/vo',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    },
  );
}

/** listInterviewQuestionBankByQuestionId GET /api/interviewQuestionBank/list/question */
export async function listInterviewQuestionBankByQuestionIdUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.listInterviewQuestionBankByQuestionIdUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseListInterviewQuestionBank_>(
    '/api/interviewQuestionBank/list/question',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** updateInterviewQuestionBank POST /api/interviewQuestionBank/update */
export async function updateInterviewQuestionBankUsingPost(
  body: API.InterviewQuestionBankUpdateRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/interviewQuestionBank/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
