<template>
  <a-form
    :model="updateUser"
    label-align="left"
    style="width: 50%"
    auto-label-width
  >
    <a-form-item label="用户名称">
      <a-input v-model="updateUser.userName" placeholder="请输入用户名称..." />
    </a-form-item>
    <a-form-item label="用户头像">
      <a-space size="large">
        <a-image
          v-if="updateUser.userAvatar"
          :src="updateUser.userAvatar"
          width="80px"
        ></a-image>
        <picture-upload
          biz="user_avatar"
          v-model="updateUser.userAvatar"
          :onChange="(url) => (updateUser.userAvatar = url)"
        />
      </a-space>
    </a-form-item>
    <a-form-item label="手机号">
      <a-space size="large">
        <a-typography-text>
          {{ loginUser.userPhone == null ? "未绑定" : loginUser.userPhone }}
        </a-typography-text>
        <a-link @click="updatePhone">
          {{ loginUser.userPhone == null ? "添加手机号" : "修改手机号" }}
        </a-link>
      </a-space>
    </a-form-item>
    <a-form-item label="用户简介">
      <a-input
        v-model="updateUser.userProfile"
        placeholder="请输入用户简介..."
      />
    </a-form-item>
    <a-form-item>
      <a-space>
        <a-button @click="reset">重置</a-button>
        <a-popconfirm
          content="你确定要修改吗?"
          @ok="submitUser"
          position="bottom"
        >
          <a-button type="primary" :loading="updateLoading">修改信息</a-button>
        </a-popconfirm>
      </a-space>
    </a-form-item>
  </a-form>
  <a-modal v-model:visible="phoneVisible" draggable :footer="false">
    <template #title>
      {{ loginUser.userPhone == null ? "添加手机号" : "修改手机号" }}
    </template>
    <a-form label-align="left" auto-label-width>
      <a-form-item field="手机号" label="手机号" required>
        <a-input v-model="phoneForm.userPhone" placeholder="请输入手机号..." />
      </a-form-item>
      <a-form-item field="验证码" label="验证码" required>
        <a-space size="large">
          <a-input
            v-model="phoneForm.phoneCode"
            placeholder="请输入手机验证码..."
          />
          <a-button id="getCodeButton" @click="getPhoneCode()" required>
            获取验证码
          </a-button>
        </a-space>
      </a-form-item>
      <a-form-item field="code" label="图形验证码">
        <a-space>
          <a-input v-model="imgCode" placeholder="请输入图形验证码..." />
          <img style="width: 120px; height: 35px" :src="codeMap.image" />
          <a-button @click="getCode()">刷新</a-button>
        </a-space>
      </a-form-item>
      <a-form-item>
        <a-popconfirm
          content="你确定要修改吗?"
          @ok="submitUserPhone"
          position="bottom"
        >
          <a-button type="primary" html-type="submit"
            >{{ loginUser.userPhone == null ? "添加" : "修改" }}
          </a-button>
        </a-popconfirm>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script setup lang="ts">
import API from "@/api";
import { ref } from "vue";
import message from "@arco-design/web-vue/es/message";
import {
  codeUsingGet,
  editMyUserPhoneUsingPost,
  editMyUserUsingPost,
  getCodeUsingGet,
} from "@/request/api/userController";
import PictureUpload from "@/components/picture/PictureUpload.vue";
import { useLoginUserStore } from "@/store/userStore";
//查询登录用户
const loginUserStore = useLoginUserStore();
const loginUser = ref<API.UserVO>(loginUserStore.loginUser);

/**
 * 修改
 */
//要修改的数据
const updateUser = ref<API.UserUpdateMyRequest>({ ...loginUser.value });
const updateVisible = ref(false);

const reset = () => {
  updateUser.value = {};
  updateUser.value = {
    ...loginUser.value,
  };
};
/**
 * 提交用户信息
 */
const submitUser = async () => {
  updateLoading.value = true;
  if (updateUser.value.userName == "") {
    message.error("请输入用户名");
    return;
  }
  if (updateUser.value.userAvatar == "") {
    message.error("请输入头像");
    return;
  }
  const response = await editMyUserUsingPost(updateUser.value);
  if (response.data.code === 0) {
    await loginUserStore.fetchLoginUser();
    loginUser.value = {
      ...loginUserStore.loginUser,
    };
    message.success("修改成功");
    updateVisible.value = false;
  } else {
    message.error("修改失败，" + response.data.message);
  }
  updateLoading.value = false;
};

/**
 * 手机号修改
 */
const phoneForm = ref<API.UserPhoneRequest>({});
const phoneVisible = ref(false);

const updatePhone = () => {
  phoneVisible.value = true;
  getCode();
};

const updateLoading = ref(false);
/**
 * 修改手机号
 */
const submitUserPhone = async () => {
  updateLoading.value = true;
  // 验证参数
  if (
    phoneForm.value.userPhone == undefined ||
    phoneForm.value.userPhone == ""
  ) {
    message.warning("请输入手机号");
    return;
  }
  if (
    phoneForm.value.phoneCode == undefined ||
    phoneForm.value.phoneCode == ""
  ) {
    message.warning("请输入验证码");
    return;
  }
  // 发送请求
  const response = await editMyUserPhoneUsingPost(phoneForm.value);
  if (response.data.code === 0) {
    await loginUserStore.fetchLoginUser();
    loginUser.value = {
      ...loginUserStore.loginUser,
    };
    message.success("修改成功");
    phoneVisible.value = false;
  } else {
    message.error("修改失败，" + response.data.message);
  }
  updateLoading.value = false;
};

/**
 * 获取手机验证码
 */
const loading = ref(false);
const getPhoneCode = async () => {
  if (loading.value == true) return;
  if (phoneForm.value.userPhone == undefined) {
    message.warning("请输入手机号");
    return;
  }
  if (imgCode.value == "") {
    message.error("请输入图形验证码");
    return;
  }
  const phone = ref<API.codeUsingGETParams>({
    userPhone: phoneForm.value.userPhone as string,
    imgCodeKey: codeMap.value.key,
    imgCode: imgCode.value,
  });
  const response = await codeUsingGet(phone.value);
  if (response.data.code === 0) {
    message.success("验证码已发送");
    loading.value = true;
    // 设置倒计时
    countdown(60);
  } else {
    message.error(response.data.message as string);
  }
};
/**
 * 图形验证码
 */
const imgCode = ref("");
const codeMap = ref<any>({
  key: "",
  image: "",
});
const getCode = async () => {
  const res = await getCodeUsingGet({
    key: codeMap.value.key,
  });
  if (res.data.code === 0) {
    codeMap.value = res.data.data;
  } else {
    message.error(res.data.message as string);
  }
};

/**
 * 倒计时
 * @param t
 */
function countdown(t: number): void {
  loading.value = true;
  const timer = t > 0 ? t : 0;
  const button = document.getElementById("getCodeButton") as any;
  button.innerText = `${timer}秒后重新获取`;
  if (timer > 0) {
    setTimeout(() => countdown(timer - 1), 1000);
  } else {
    button.innerText = `获取验证码`;
    loading.value = false;
    return;
  }
}
</script>
<style scoped></style>
