<template>
  <a-layout>
    <a-layout-header class="header">
      <GlobalHeader></GlobalHeader>
    </a-layout-header>
    <a-layout class="layout-content">
      <a-layout-sider collapsible breakpoint="xl">
        <div class="logo" />
        <a-menu
          :default-open-keys="['/admin/user']"
          :default-selected-keys="['/admin/user']"
          :style="{ width: '100%' }"
          @menu-item-click="onClickMenuItem"
          breakpoint="xl"
        >
          <a-sub-menu key="/admin/user">
            <template #title>
              <icon-user />
              用户管理
            </template>
            <a-menu-item key="/admin/user">
              <a-typography-text>用户管理</a-typography-text>
            </a-menu-item>
          </a-sub-menu>

          <a-sub-menu key="/admin/app">
            <template #title>
              <icon-apps />
              问答管理
            </template>
            <a-menu-item key="/admin/app">
              <a-typography-text>应用管理</a-typography-text>
            </a-menu-item>
            <a-menu-item key="/admin/question">
              <a-typography-text>问题管理</a-typography-text>
            </a-menu-item>
            <a-menu-item key="/admin/scoringResult">
              <a-typography-text>评分结果管理</a-typography-text>
            </a-menu-item>
            <a-menu-item key="/admin/userAnswer">
              <a-typography-text>用户答案管理</a-typography-text>
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="/admin/interview">
            <template #title>
              <icon-book />
              面试题管理
            </template>
            <a-menu-item key="/admin/interview">
              <a-typography-text>面试题管理</a-typography-text>
            </a-menu-item>
            <a-menu-item key="/admin/interviewQuestionBank">
              <a-typography-text>题库管理</a-typography-text>
            </a-menu-item>
            <a-menu-item key="/admin/interviewQuestionBankQuestion">
              <a-typography-text>面试题库关联表管理</a-typography-text>
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="/admin/post">
            <template #title>
              <icon-bookmark />
              帖子管理
            </template>
            <a-menu-item key="/admin/post">
              <a-typography-text>帖子管理</a-typography-text>
            </a-menu-item>
            <a-menu-item key="/admin/comment">
              <a-typography-text>评论管理</a-typography-text>
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="/admin/appStatistic">
            <template #title>
              <icon-bar-chart />
              统计管理
            </template>
            <a-menu-item key="/admin/appStatistic">
              <a-typography-text>应用统计</a-typography-text>
            </a-menu-item>
            <a-menu-item key="/admin/hotInterviewQuestionHot">
              <a-typography-text>热门题目统计</a-typography-text>
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="/admin/bug">
            <template #title>
              <icon-bug />
              BUG管理
            </template>
            <a-menu-item key="/admin/bug">
              <a-typography-text> BUG管理</a-typography-text>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
        <!-- trigger -->
        <template #trigger="{ collapsed }">
          <IconCaretRight v-if="collapsed"></IconCaretRight>
          <IconCaretLeft v-else></IconCaretLeft>
        </template>
      </a-layout-sider>
      <a-layout>
        <a-layout-header style="padding-left: 20px">
          <a-breadcrumb :style="{ margin: '16px 0' }">
            <a-breadcrumb-item v-for="path in hostPath.split('/')" :key="path"
              >{{ path }}
            </a-breadcrumb-item>
          </a-breadcrumb>
        </a-layout-header>
        <a-layout style="padding: 24px">
          <a-layout-content style="padding: 20px; border-radius: 20px">
            <router-view></router-view>
          </a-layout-content>
          <a-layout-footer class="footer">
            <a-link>易答 By FYX</a-link>
          </a-layout-footer>
        </a-layout>
      </a-layout>
    </a-layout>
  </a-layout>
</template>
<script setup lang="ts">
import router from "@/router";
import GlobalHeader from "@/layouts/GlobalHeader.vue";
import { useRoute } from "vue-router";
import { ref, watchEffect } from "vue";

const onClickMenuItem = (path: string) => {
  router.push(path);
};

const route = useRoute();
const hostPath = ref<string>(route.path);
watchEffect(() => {
  hostPath.value = route.path;
});
</script>
<style scoped>
.layout-content {
  min-width: 1400px;
  min-height: 100vh;
  background: linear-gradient(to bottom, #f8f8f8, #f8f7f7);
  border: 1px solid var(--color-border);
}

.header {
  min-width: 1400px;
  margin-bottom: 16px;
  background-color: white;
  box-shadow: #eee 1px 1px 5px;
}

.layout-content :deep(.arco-layout-sider) .logo {
  height: 32px;
  margin: 12px 8px;
  background: rgba(255, 255, 255, 0.2);
}

.layout-content :deep(.arco-layout-sider-light) .logo {
  background: var(--color-fill-2);
}

.layout-content :deep(.arco-layout-header) {
  height: 64px;
  line-height: 64px;
  background: var(--color-bg-3);
}

.layout-content :deep(.arco-layout-footer) {
  height: 48px;
  color: var(--color-text-2);
  font-weight: 400;
  font-size: 14px;
  line-height: 48px;
}

.layout-content :deep(.arco-layout-content) {
  color: var(--color-text-2);
  font-weight: 400;
  font-size: 14px;
  background: var(--color-bg-3);
}

.layout-content :deep(.arco-layout-footer),
.layout-content :deep(.arco-layout-content) {
  display: flex;
  flex-direction: column;
  color: var(--color-white);
  font-size: 16px;
  font-stretch: condensed;
  text-align: center;
}

</style>
