<template>
  <a-form
    :model="searchParams"
    layout="inline"
    style="margin: 0 0 10px auto"
    label-align="left"
    auto-label-width
    @submit="search"
  >
    <a-form-item field="appType" label="应用类型：">
      <a-select
        v-model="searchParams.appType"
        placeholder="请输入..."
        allow-clear
        style="width: 100px"
      >
        <a-option :value="APP_TYPE_ENUM.SCORE"
          >{{ APP_TYPE_MAP["0"] }}
        </a-option>
        <a-option :value="APP_TYPE_ENUM.TEST"
          >{{ APP_TYPE_MAP["1"] }}
        </a-option>
      </a-select>
    </a-form-item>
    <a-form-item field="scoringStrategy" label="评分策略：">
      <a-select
        v-model="searchParams.scoringStrategy"
        placeholder="请输入..."
        allow-clear
        style="width: 100px"
      >
        <a-option :value="APP_SCORING_STRATEGY_ENUM.CUSTOM"
          >{{ APP_SCORING_STRATEGY_MAP["0"] }}
        </a-option>
        <a-option :value="APP_SCORING_STRATEGY_ENUM.AI"
          >{{ APP_SCORING_STRATEGY_MAP["1"] }}
        </a-option>
      </a-select>
    </a-form-item>
    <a-form-item field="resultName" label="结果名称：">
      <a-input
        v-model="searchParams.resultName"
        placeholder="请输入..."
        allow-clear
      />
    </a-form-item>
    <a-form-item field="resultDesc" label="结果描述：">
      <a-input v-model="searchParams.resultDesc" placeholder="请输入..." />
    </a-form-item>

    <a-form-item>
      <a-button type="primary" html-type="submit" style="width: 120px"
        >搜索
      </a-button>
    </a-form-item>
  </a-form>
  <a-list
    :bordered="false"
    :data="dataList"
    :pagination-props="{
      pageSize: searchParams.pageSize,
      current: searchParams.current,
      total,
    }"
    :stripe="true"
    @page-change="onPageChange"
    :loading="loading"
  >
    <template #item="{ item }">
      <a-list-item action-layout="vertical">
        <a-card
          :bordered="false"
          @click="onClickMenuItem('/answer/result/' + item.id)"
        >
          <a-row>
            <a-col :span="14" class="contentWrapper" style="margin-left: 50px">
              <h2>{{ item.resultName }}</h2>
              <p>结果描述：{{ item.resultDesc }}</p>
              <p v-if="item.appType == APP_TYPE_ENUM.SCORE">
                结果得分：{{ item.resultScore }}
              </p>
              <p>我的选择：{{ item.choices }}</p>
              <p>应用类型：{{ APP_TYPE_MAP[item.appType] }}</p>
              <p>
                评分策略：{{ APP_SCORING_STRATEGY_MAP[item.scoringStrategy] }}
              </p>
              <div :style="{ display: 'flex', alignItems: 'center' }">
                <span>答题人：</span>
                <a-avatar
                  :size="24"
                  :style="{ marginRight: '8px' }"
                  :image-url="item.user?.userAvatar"
                />
                <a-typography-text>{{ item.user?.userName }}</a-typography-text>
              </div>
              <p>
                答题时间：{{
                  dayjs(item.createTime).format("YYYY-MM-DD HH:mm:ss")
                }}
              </p>
            </a-col>
            <a-col :span="8">
              <a-image
                width="300"
                style="margin: 50px"
                :src="item?.resultPicture"
              />
            </a-col>
          </a-row>
          <a-popconfirm
            content="你确定要删除吗?"
            @ok="remove(item.id)"
            position="bottom"
          >
            <span v-if="isMy" class="action" style="margin-left: 50px">
              <icon-delete />
            </span>
          </a-popconfirm>
        </a-card>
      </a-list-item>
    </template>
  </a-list>
</template>
<script setup lang="ts">
import dayjs from "dayjs";
import router from "@/router";
import { defineProps, ref, watchEffect, withDefaults } from "vue";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import { useLoginUserStore } from "@/store/userStore";
import {
  deleteUserAnswerUsingPost,
  listMyUserAnswerVoByPageUsingPost,
} from "@/api/userAnswerController";
import {
  APP_SCORING_STRATEGY_ENUM,
  APP_SCORING_STRATEGY_MAP,
  APP_TYPE_ENUM,
  APP_TYPE_MAP,
} from "../../constant/appEnum";

interface Props {
  userId: number;
}

const props = withDefaults(defineProps<Props>(), {
  userId: () => {
    return 0;
  },
});
//查询参数
const searchParams = ref<API.UserAnswerQueryRequest>({
  current: 1,
  pageSize: 10,
  userId: props.userId,
  sortField: "createTime",
  sortOrder: "descend",
});
//总值
const total = ref<number>(0);
//是否显示加载
const loading = ref(true);
// 答案列表
const dataList = ref<API.UserAnswerVO[]>([]);
//搜索关键词
const searchText = ref<string>("");
//总值
const loginUser = ref<API.UserVO>(useLoginUserStore().loginUser);
const isMy = ref(props.userId == loginUser.value.id);
/**
 * 搜索
 */
const search = async () => {
  if (searchText.value == "") {
    return;
  }
};
/**
 * 加载数据
 */
const loadData = async () => {
  loading.value = true;
  const response = await listMyUserAnswerVoByPageUsingPost(searchParams.value);
  if (response.data.code === 0 && response.data.data) {
    dataList.value = response.data.data?.records || [];
    total.value = response.data.data?.total || 0;
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
  loading.value = false;
};

/**
 * 当分页变化时，改变搜索条件，触发数据加载
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

/**
 * 点击菜单项
 * @param id
 */
const onClickMenuItem = async (url: string) => {
  router.push(url);
};

/**
 * 删除答案
 */
const remove = async (id: number) => {
  const response = await deleteUserAnswerUsingPost({ id: id });
  if (response.data.code === 0) {
    message.success("删除成功");
    loadData();
  } else {
    message.error("删除失败，" + response.data.message);
  }
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  if (searchParams.value) {
    loadData();
  }
});
</script>
<style scoped></style>
