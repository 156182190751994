<template>
  <div class="appHome">
    <div style="font-size: 24px; font-weight: bold">问答应用</div>
    <div style="margin-bottom: 30px">
      <a-form
        :model="formParams"
        layout="inline"
        auto-label-width
        @submit="search"
      >
        <a-form-item field="appName" style="margin: 0 auto">
          <a-input
            v-model="formParams.appName"
            placeholder="快速发现答题应用"
            style="width: 250px"
            allow-clear
          />
          <a-button type="primary" html-type="submit" style="width: 100px"
            >搜索
          </a-button>
        </a-form-item>
      </a-form>
      <a-button @click="click('/add/app')" type="primary">创建应用</a-button>
    </div>
    <div id="contextDiv">
      <a-list
        class="list-demo-action-layout"
        :grid-props="{ gutter: [20, 20], md: 6 }"
        :bordered="false"
        :data="dataList"
        :pagination-props="{
          pageSize: searchParams.pageSize,
          current: searchParams.current,
          total,
        }"
        :loading="loading"
        @page-change="onPageChange"
      >
        <template #item="{ item }">
          <app-cart :app="item"></app-cart>
        </template>
      </a-list>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watchEffect } from "vue";
import API from "@/api";
import { listAppVoByPageUsingPost } from "@/api/appController";
import message from "@arco-design/web-vue/es/message";
import { REVIEW_STATUS_ENUM } from "@/constant/appEnum";
import AppCart from "@/components/list/AppCart.vue";
import router from "@/router";

//初始值
const initParams = {
  current: 1,
  pageSize: 8,
  reviewStatus: REVIEW_STATUS_ENUM.PASS,
  sortField: "id",
  sortOrder: "descend",
};
//搜索参数
const searchParams = ref<API.AppQueryRequest>({
  ...initParams,
});
//传递参数
const formParams = ref<API.AppQueryRequest>({});
//数据
const dataList = ref<API.AppVO[]>([]);
//总值
const total = ref<number>(0);
const loading = ref(false);

/**
 * 加载数据
 */
const loadData = async () => {
  loading.value = true;
  const response = await listAppVoByPageUsingPost(searchParams.value);
  if (response.data.code === 0) {
    dataList.value = response.data.data?.records || [];
    total.value = response.data.data?.total || 0;
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
  loading.value = false;
};
/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});
/**
 * 搜索
 */
const search = () => {
  searchParams.value = { ...initParams, ...formParams.value };
};
/**
 * 当分页变化时，改变搜索条件，触发数据加载
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};
const click = (url: string) => {
  router.push({ path: url });
};
</script>

<style scoped>
.appHome {
  max-width: 1400px;
  margin: 24px auto 0;
}

#contextDiv {
  margin: 20px auto;
}
</style>
