import { defineStore } from "pinia";
import { ref } from "vue";
import { getLoginUserUsingGet } from "@/api/userController";
import AccessEnum from "@/access/accessEnum";

/**
 * 登录用户信息全局状态
 */
export const useLoginUserStore = defineStore("loginUser", () => {
  // 定义一个响应式变量loginUser，用于存储登录用户信息
  const loginUser = ref<API.LoginUserVO>({
    userName: AccessEnum.NOT_LOGIN,
  });

  // 定义一个异步函数fetchLoginUser，用于获取登录用户信息
  async function fetchLoginUser() {
    // 调用getLoginUserUsingGet函数获取登录用户信息
    const res = await getLoginUserUsingGet();
    // 如果获取成功，将获取到的登录用户信息赋值给loginUser
    if (res.data.code === 0 && res.data.data) {
      loginUser.value = res.data.data;
    } else {
      loginUser.value = { userRole: AccessEnum.NOT_LOGIN };
    }
  }

  // 定义一个函数setLoginUser，用于设置登录用户信息
  function setLoginUser(newLoginUser: API.LoginUserVO) {
    // 将设置的登录用户信息赋值给loginUser
    loginUser.value = newLoginUser;
  }

  // 返回loginUser、setLoginUser和fetchLoginUser
  return { loginUser, setLoginUser, fetchLoginUser };
});
