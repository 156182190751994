// @ts-ignore
/* eslint-disable */
import request from '@/request';

/** addInterviewQuestion POST /api/interviewQuestion/add */
export async function addInterviewQuestionUsingPost(
  body: API.InterviewQuestionAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseLong_>('/api/interviewQuestion/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** deleteInterviewQuestion POST /api/interviewQuestion/delete */
export async function deleteInterviewQuestionUsingPost(
  body: API.DeleteRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/interviewQuestion/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** doInterviewQuestionFavour POST /api/interviewQuestion/favour */
export async function doInterviewQuestionFavourUsingPost(
  body: API.FavourRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseInt_>('/api/interviewQuestion/favour', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** listInterviewQuestionFavourPostByPage POST /api/interviewQuestion/favour/list/page */
export async function listInterviewQuestionFavourPostByPageUsingPost(
  body: API.InterviewQuestionQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageInterviewQuestionVO_>(
    '/api/interviewQuestion/favour/list/page',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    },
  );
}

/** listMyInterviewQuestionFavourPostByPage POST /api/interviewQuestion/favour/my/list/page */
export async function listMyInterviewQuestionFavourPostByPageUsingPost(
  body: API.InterviewQuestionQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageInterviewQuestionVO_>(
    '/api/interviewQuestion/favour/my/list/page',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    },
  );
}

/** getInterviewQuestionVOById GET /api/interviewQuestion/get/vo */
export async function getInterviewQuestionVoByIdUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getInterviewQuestionVOByIdUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseInterviewQuestionVO_>('/api/interviewQuestion/get/vo', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** listAllInterviewQuestion GET /api/interviewQuestion/list */
export async function listAllInterviewQuestionUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.listAllInterviewQuestionUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseListInterviewQuestion_>('/api/interviewQuestion/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** listInterviewQuestionByPage POST /api/interviewQuestion/list/page */
export async function listInterviewQuestionByPageUsingPost(
  body: API.InterviewQuestionQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageInterviewQuestion_>('/api/interviewQuestion/list/page', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** listInterviewQuestionVOByPage POST /api/interviewQuestion/list/page/vo */
export async function listInterviewQuestionVoByPageUsingPost(
  body: API.InterviewQuestionQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageInterviewQuestionVO_>('/api/interviewQuestion/list/page/vo', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** doReview POST /api/interviewQuestion/review */
export async function doReviewUsingPost(body: API.ReviewRequest, options?: { [key: string]: any }) {
  return request<API.BaseResponseBoolean_>('/api/interviewQuestion/review', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** searchQuestionVOByPage POST /api/interviewQuestion/search/page/vo */
export async function searchQuestionVoByPageUsingPost(
  body: API.InterviewQuestionQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageInterviewQuestionVO_>(
    '/api/interviewQuestion/search/page/vo',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    },
  );
}

/** doInterviewQuestionThumb POST /api/interviewQuestion/thumb */
export async function doInterviewQuestionThumbUsingPost(
  body: API.ThumbRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseInt_>('/api/interviewQuestion/thumb', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** updateInterviewQuestion POST /api/interviewQuestion/update */
export async function updateInterviewQuestionUsingPost(
  body: API.InterviewQuestionUpdateRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/interviewQuestion/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
