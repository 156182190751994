import axios from "axios";
import message from "@arco-design/web-vue/es/message";
import router from "@/router";
import { ref } from "vue";

// 是否是开发环境
export const isDev = process.env.NODE_ENV === "development";
const dev = ref("http://localhost:8101");
const prod = ref("https://yida.uno");
export const url = prod.value;
// 创建 Axios 实例
const myAxios = axios.create({
  baseURL: url,
  timeout: 60000,
  withCredentials: true,
});

// 统一请求拦截器
myAxios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 统一响应拦截器
myAxios.interceptors.response.use(
  function (response) {
    const { data } = response;
    // 未登录
    if (data.code === 40100) {
      // 不是获取用户信息接口，或者不是登录页面，则跳转到登录页面
      if (
        !response.request.responseURL.includes("user/get/login") &&
        !window.location.pathname.includes("/user/login")
      ) {
        router.push({ path: `/user/login?redirect=${window.location.href}` });
      }
    }
    // 不是VIP
    if (data.code === 40102) {
      // 不是获取用户信息接口，或者不是登录页面，则跳转到登录页面
      message.error("您不是VIP用户，请先购买VIP");
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default myAxios;
