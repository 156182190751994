<template>
  <div id="addPage">
    <h2>应用{{ titleText }}</h2>
    <br />
    <a-form
      :model="form"
      :style="{ width: '400px' }"
      label-align="left"
      auto-label-width
      @submit="submitApp"
      :rules="rules"
    >
      <a-form-item field="appName" label="应用名称" required>
        <a-input v-model="form.appName" placeholder="请输入应用名称..." />
      </a-form-item>
      <a-form-item field="appDesc" label="应用描述">
        <a-input v-model="form.appDesc" placeholder="请输入应用描述..." />
      </a-form-item>
      <a-form-item field="appIcon" label="应用图标" tooltip="输入图标链接">
        <a-space size="large">
          <a-image
            v-show="form.appIcon"
            :src="form.appIcon"
            width="80px"
          ></a-image>
          <picture-upload
            biz="app_icon"
            :value="form.appIcon"
            :onChange="(url) => (form.appIcon = url)"
          />
        </a-space>
      </a-form-item>
      <a-form-item field="appType" label="应用类型" required>
        <a-radio-group v-model="form.appType">
          <a-radio :value="1">
            {{ APP_TYPE_MAP[1] }}
          </a-radio>
          <a-radio :value="0" @click="form.scoringStrategy = 0">
            {{ APP_TYPE_MAP[0] }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item field="scoringStrategy" label="评分策略" required>
        <a-radio-group v-model="form.scoringStrategy">
          <a-radio :value="0">{{ APP_SCORING_STRATEGY_MAP[0] }}</a-radio>
          <a-radio :value="1" v-show="form.appType == 1">
            <a-space>
              {{ APP_SCORING_STRATEGY_MAP[1] }}
              <img src="../../../assets/vip.svg" style="width: 20px" />
            </a-space>
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-button
        type="primary"
        html-type="submit"
        style="width: 100px; margin: 0 auto 10px"
        >{{ titleText }}应用
      </a-button>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, watchEffect, withDefaults } from "vue";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import {
  addAppUsingPost,
  editAppUsingPost,
  getAppVoByIdUsingGet,
} from "@/api/appController";
import {
  APP_SCORING_STRATEGY_MAP,
  APP_TYPE_MAP,
} from "../../../constant/appEnum";
import PictureUpload from "@/components/picture/PictureUpload.vue";

const router = useRouter();
const form = ref({
  appType: 1,
  scoringStrategy: 0,
} as API.AppAddRequest);

interface Props {
  appId: number;
}

const props = withDefaults(defineProps<Props>(), {
  appId: () => {
    return 0;
  },
});

const titleText = ref("创建");
const oldApp = ref<API.AppVO>({});
/**
 * 加载数据
 */
const loadData = async () => {
  if (!props.appId) {
    return;
  }
  titleText.value = "修改";
  const response = await getAppVoByIdUsingGet({
    id: props.appId,
  });
  if (response.data.code === 0 && response.data.data) {
    oldApp.value = response.data.data;
    form.value = response.data.data;
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * 创建或修改应用
 */
const submitApp = async () => {
  if (!form.value.appName) {
    message.error("应用名称不能为空");
    return;
  }
  let response: any;
  //有就是修改
  if (props.appId) {
    response = await editAppUsingPost({
      id: props.appId,
      ...form.value,
    });
    //有就是修改
  } else {
    response = await addAppUsingPost(form.value);
  }
  if (response.data.code === 0) {
    message.success("操作成功!");
    router.push(
      `/app/detail/${props.appId == 0 ? response.data.data : props.appId}`
    );
  } else {
    message.error("操作失败，" + response.data.message);
  }
};
const rules = {
  appName: [
    {
      required: true,
    },
  ],
  appType: [
    {
      required: true,
    },
  ],
  scoringStrategy: [
    {
      required: true,
    },
  ],
};
</script>

<style scoped>
#addPage {
  width: 500px;
  margin: 30px auto;
  padding: 50px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 1px 1px #e8e8e8;
}
</style>
