<template>
  <a-affix :offsetBottom="100" style="margin-left: 98%">
    <a-button
      type="primary"
      shape="circle"
      size="large"
      @click="bugVisible = true"
    >
      <IconBug />
    </a-button>
  </a-affix>
  <a-modal v-model:visible="bugVisible" @ok="handleOk">
    <template #title>BUG反馈</template>
    <a-typography-text>
      <icon-info-circle-fill />
      在哪个页面，进行了什么操作，出现了什么问题
    </a-typography-text>
    <a-textarea
      v-model="content"
      placeholder="反馈BUG"
      :autoSize="{ minRows: 3 }"
      style="margin-top: 10px"
    >
      <template #label>BUG描述</template>
    </a-textarea>
  </a-modal>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { addBugUsingPost } from "@/request/api/bugController";
import message from "@arco-design/web-vue/es/message";

const bugVisible = ref(false);
const content = ref();
const handleOk = async () => {
  bugVisible.value = false;
  const response = await addBugUsingPost({
    content: content.value,
  });
  if (response.data.code === 0) {
    message.success("反馈成功");
    content.value = "";
  } else {
    message.error("反馈失败，" + response.data.message);
  }
};
</script>
<style scoped></style>
