// @ts-ignore
/* eslint-disable */
import request from '@/request';

/** addBug POST /api/bug/add */
export async function addBugUsingPost(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.addBugUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseLong_>('/api/bug/add', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** deleteBug POST /api/bug/delete */
export async function deleteBugUsingPost(
  body: API.DeleteRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/bug/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** listBugByPage POST /api/bug/list/page */
export async function listBugByPageUsingPost(
  body: API.BugQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageBug_>('/api/bug/list/page', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** listMyBugVOByPage POST /api/bug/my/list/page/vo */
export async function listMyBugVoByPageUsingPost(
  body: API.BugQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageBug_>('/api/bug/my/list/page/vo', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
