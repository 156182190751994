<template>
  <div class="sider">
    <div>
      <a-row style="padding: 20px 0px 0 20px">
        <a-col flex="auto">
          <div v-if="loginUser.id">
            <a-space size="large" @click="onClickMenuItem('/my/user')">
              <a-avatar :image-url="loginUser.userAvatar" />
              <a-space direction="vertical">
                <a-space>
                  <a-typography-text style="font-size: 16px">
                    {{ loginUser?.userName ?? "无名" }}
                  </a-typography-text>
                  <img src="../../assets/vip.svg" style="width: 20px" />
                </a-space>
                <a-typography-text type="secondary" class="des">
                  {{ loginUser.userProfile }}
                </a-typography-text>
              </a-space>
            </a-space>
          </div>
          <div v-else style="margin-top: 10px">
            <a-button type="outline" @click="onClickMenuItem('/user/login')"
              >去登录
            </a-button>
          </div>
        </a-col>
        <a-col flex="100px">
          <check-in-button style="margin: 15px 10px 10px 10px" />
        </a-col>
      </a-row>
      <a-divider />
      <a-row style="padding-bottom: 20px">
        <a-col :span="8" style="display: grid; justify-items: center">
          <a-space direction="vertical" @click="onClickMenuItem('/')">
            <a-button shape="circle">
              <template #icon>
                <icon-bookmark />
              </template>
            </a-button>
            帖子
          </a-space>
        </a-col>
        <a-col :span="8" style="display: grid; justify-items: center">
          <a-space direction="vertical" @click="onClickMenuItem('/interview')">
            <a-button shape="circle">
              <template #icon>
                <icon-book />
              </template>
            </a-button>
            题目
          </a-space>
        </a-col>
        <a-col :span="8" style="display: grid; justify-items: center">
          <a-space direction="vertical" @click="onClickMenuItem('/app')">
            <a-button shape="circle">
              <template #icon>
                <icon-apps />
              </template>
            </a-button>
            问答
          </a-space>
        </a-col>
      </a-row>
    </div>

    <div v-if="useLoginUserStore().loginUser.id" style="margin-top: -20px">
      <a-divider />
      <date-picker />
    </div>
  </div>
  <div class="hotPost">
    <div class="hotPostTitle">
      <a-typography-text style="font-size: 18px">
        🔥热门{{ props.bizType == "question" ? "题目" : "文章" }}
      </a-typography-text>
    </div>
    <div>
      <a-list
        :bordered="false"
        :data="dataList"
        :split="false"
        size="small"
        :loading="loading"
        style="margin: 5px"
      >
        <a-list-item
          v-for="data in dataList"
          :key="data.id"
          class="action"
          @click="clickHot(data.id)"
        >
          <a-row>
            <a-col :span="20">
              <a-typography-text style="font-size: 16px; margin: 0" ellipsis>
                {{ data.title }}
              </a-typography-text>
            </a-col>
            <a-col :span="3" :offset="1">
              <icon-fire />
              <a-typography-text type="secondary">
                {{ data.viewNum }}
              </a-typography-text>
            </a-col>
          </a-row>
        </a-list-item>
      </a-list>
    </div>
  </div>
</template>
<script setup lang="ts">
import CheckInButton from "@/components/checkIn/CheckInButton.vue";
import { useLoginUserStore } from "@/store/userStore";
import router from "@/router";
import { defineProps, ref, watchEffect, withDefaults } from "vue";
import API from "@/api";
import { listPostVoByPageUsingPost } from "@/api/postController";
import message from "@arco-design/web-vue/es/message";
import DatePicker from "@/components/checkIn/DatePicker.vue";
import { listInterviewQuestionVoByPageUsingPost } from "@/request/api/interviewQuestionController";

const loginUser = ref<API.LoginUserVO>(useLoginUserStore().loginUser);

interface Props {
  bizType: string;
}

const props = withDefaults(defineProps<Props>(), {
  bizType: () => {
    return "";
  },
});

/**
 * 点击菜单项
 * @param id
 */
const onClickMenuItem = async (url: string) => {
  router.push(url);
};

const dataList = ref<any[]>([]);
const loading = ref(false);
const clickHot = async (id: number) => {
  if (props.bizType == "post") {
    router.push("/post/detail/" + id);
  } else if (props.bizType == "question") {
    router.push("/interviewQuestion/detail/" + id);
  }
};
/**
 * 加载数据
 */
const loadData = async () => {
  loading.value = true;
  let response: any;
  if (props.bizType == "post") {
    response = await listPostVoByPageUsingPost({
      current: 1,
      pageSize: 5,
      sortField: "thumbNum",
      sortOrder: "descend",
    });
  } else if (props.bizType == "question") {
    response = await listInterviewQuestionVoByPageUsingPost({
      current: 1,
      pageSize: 5,
      sortField: "viewNum",
      sortOrder: "descend",
    });
  }
  if (response.data.code === 0 && response.data.data) {
    dataList.value = response.data.data?.records || [];
    if (dataList.value.length == 0) {
      message.warning("暂无数据");
    }
    dataList.value.forEach((item: any) => {
      if (item.title == undefined || item.title == "" || item.title == null) {
        item.title = item.content;
      }
    });
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
  loading.value = false;
};
/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});
/**
 * hotPost
 */
</script>
<style scoped>
.sider {
  margin-left: 20px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 1px 1px #e8e8e8;
}

.hotPost {
  margin: 20px 0 0 20px;
  padding-bottom: 5px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 1px 0px #e8e8e8;
}

.hotPostTitle {
  padding: 14px;
  background: linear-gradient(to bottom, #165dff, white);
  border-radius: 20px 20px 0 0;
}

.action {
  padding: 0 4px;
  color: var(--color-text-1);
  line-height: 24px;
  background: transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.1s ease;
}

.action:hover {
  background: var(--color-fill-3);
}
</style>
