<template>
  <div class="editPost">
    <div>
      <a-typography-title :heading="4">写文章</a-typography-title>
      <a-form layout="vertical">
        <a-spin dot :loading="loading">
          <a-form-item label="标题">
            <a-input
              v-model="form.title"
              placeholder="请输入帖子标题，最多80字"
            />
          </a-form-item>
          <a-form-item label="内容">
            <md-editor v-model="form.content" />
          </a-form-item>
          <a-form-item label="图片">
            <a-space size="large">
              <a-image
                :key="index"
                v-for="(img, index) in form.imgList"
                :src="img"
                width="200"
              >
                <template #extra>
                  <span class="action" @click="form.imgList.splice(index, 1)">
                    <icon-delete />
                  </span>
                </template>
              </a-image>
              <PostPictureUpload
                v-show="form.imgList.length < 3"
                biz="post_img"
                :on-change="(url) => form.imgList.push(url)"
              />
            </a-space>
          </a-form-item>
          <a-form-item label="话题">
            <topic-select
              v-model="form.tags"
              placeholder="选择你的话题"
              :limit="2"
            />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="submitPost">提交</a-button>
          </a-form-item>
        </a-spin>
      </a-form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, watchEffect, withDefaults } from "vue";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import {
  addPostUsingPost,
  editPostUsingPost,
  getPostVoByIdUsingGet,
} from "@/api/postController";
import MdEditor from "@/components/md/MdEditor.vue";
import PostPictureUpload from "@/components/picture/PostPictureUpload.vue";
import TopicSelect from "@/components/selecter/TopicSelecter.vue";

interface Props {
  id: number;
}

const props = withDefaults(defineProps<Props>(), {
  id: () => {
    return 0;
  },
});
const router = useRouter();
const form = ref<API.PostAddRequest>({
  imgList: [],
});
const loading = ref(false);
/**
 * 加载数据
 */
const loadData = async () => {
  if (!props.id || props.id == 0) {
    return;
  }
  loading.value = true;
  const response = await getPostVoByIdUsingGet({
    id: props.id,
  });
  if (response.data.code === 0 && response.data.data) {
    form.value = response.data.data;
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
  loading.value = false;
};
/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * 创建或修改应用
 */
const submitPost = async () => {
  if (!form.value.content) {
    message.error("内容不能为空");
    return;
  }
  let response: any;
  //添加
  if (!props.id || props.id == 0) {
    response = await addPostUsingPost(form.value);
    //修改
  } else {
    response = await editPostUsingPost({
      id: props.id,
      ...form.value,
    });
  }
  if (response.data.code === 0) {
    message.success("操作成功!");
    router.push(
      `/post/detail/${props.id == 0 ? response.data.data : props.id}`
    );
  } else {
    message.error("操作失败，" + response.data.message);
  }
};
</script>

<style scoped>
.editPost {
  max-width: 1600px;
  margin: 20px auto;
  padding: 10px 40px 40px 40px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 1px 1px #e8e8e8;
}

.action {
  margin-left: 12px;
  padding: 5px 4px;
  font-size: 14px;
  line-height: 1;
  border-radius: 2px;
  cursor: pointer;
}

.action:first-child {
  margin-left: 0;
}

.action:hover {
  background: rgba(0, 0, 0, 0.5);
}
</style>
