<template>
  <a-card class="appCart" :style="{ width: '300px' }" @click="clickApp">
    <template #actions>
      <a-space>
        <IconHeartFill style="margin-top: -3px" />
        <a-typography-text>
          {{ app.thumbNum }}
        </a-typography-text>
      </a-space>
      <span class="icon-hover">
        <IconShareInternal />
      </span>
    </template>
    <template #cover>
      <div
        :style="{
          height: '204px',
          overflow: 'hidden',
        }"
      >
        <img
          :style="{
            width: '100%',
            height: '100%',
            transform: 'translateY(-20px)',
          }"
          :alt="app.appName"
          :src="app.appIcon"
        />
      </div>
    </template>
    <a-card-meta
      :title="app.appName"
      :description="app.appDesc"
      style="margin-top: -20px"
    >
      <template #avatar>
        <div
          :style="{ display: 'flex', alignItems: 'center', color: '#1D2129' }"
        >
          <a-avatar
            :size="24"
            :style="{ marginRight: '8px' }"
            :image-url="app.user?.userAvatar"
          ></a-avatar>
          <a-typography-text>{{ app.user.userName }}</a-typography-text>
        </div>
      </template>
    </a-card-meta>
  </a-card>
</template>
<script setup lang="ts">
import { IconShareInternal } from "@arco-design/web-vue/es/icon";
import API from "@/api";
import { defineProps, withDefaults } from "vue";
import router from "@/router";

interface Props {
  app: API.AppVO;
}

const props = withDefaults(defineProps<Props>(), {
  app: () => {
    return {};
  },
});
/**
 * 进入应用
 */
const clickApp = () => {
  router.push({
    path: `/app/detail/${props.app.id}`,
  });
};
</script>
<style scoped>
.appCart {
  border-radius: 0 0 10px 10px;
  cursor: pointer;
}

.icon-hover {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: all 0.1s;
}

.icon-hover:hover {
  background-color: rgb(var(--gray-2));
}
</style>
