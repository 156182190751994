// @ts-ignore
/* eslint-disable */
import request from '@/request';

/** addInterviewQuestionBankQuestion POST /api/interviewQuestionBankQuestion/add */
export async function addInterviewQuestionBankQuestionUsingPost(
  body: API.InterviewQuestionBankQuestionAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseLong_>('/api/interviewQuestionBankQuestion/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** batchUpdateInterviewQuestionBank POST /api/interviewQuestionBankQuestion/batch/update/bank */
export async function batchUpdateInterviewQuestionBankUsingPost(
  body: API.BatchRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/interviewQuestionBankQuestion/batch/update/bank', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** batchUpdateInterviewQuestion POST /api/interviewQuestionBankQuestion/batch/update/question */
export async function batchUpdateInterviewQuestionUsingPost(
  body: API.BatchRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>(
    '/api/interviewQuestionBankQuestion/batch/update/question',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    },
  );
}

/** deleteInterviewQuestionBankQuestion POST /api/interviewQuestionBankQuestion/delete */
export async function deleteInterviewQuestionBankQuestionUsingPost(
  body: API.DeleteRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/interviewQuestionBankQuestion/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** getInterviewQuestionBankQuestionVOById GET /api/interviewQuestionBankQuestion/get/vo */
export async function getInterviewQuestionBankQuestionVoByIdUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getInterviewQuestionBankQuestionVOByIdUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseInterviewQuestionBankQuestionVO_>(
    '/api/interviewQuestionBankQuestion/get/vo',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** listInterviewQuestionBankQuestionByPage POST /api/interviewQuestionBankQuestion/list/page */
export async function listInterviewQuestionBankQuestionByPageUsingPost(
  body: API.InterviewQuestionBankQuestionQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageInterviewQuestionBankQuestion_>(
    '/api/interviewQuestionBankQuestion/list/page',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    },
  );
}

/** listInterviewQuestionBankQuestionVOByPage POST /api/interviewQuestionBankQuestion/list/page/vo */
export async function listInterviewQuestionBankQuestionVoByPageUsingPost(
  body: API.InterviewQuestionBankQuestionQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageInterviewQuestionBankQuestionVO_>(
    '/api/interviewQuestionBankQuestion/list/page/vo',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    },
  );
}

/** listMyInterviewQuestionBankQuestionVOByPage POST /api/interviewQuestionBankQuestion/my/list/page/vo */
export async function listMyInterviewQuestionBankQuestionVoByPageUsingPost(
  body: API.InterviewQuestionBankQuestionQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageInterviewQuestionBankQuestionVO_>(
    '/api/interviewQuestionBankQuestion/my/list/page/vo',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    },
  );
}

/** removeQuestionBankQuestion POST /api/interviewQuestionBankQuestion/remove */
export async function removeQuestionBankQuestionUsingPost(
  body: API.InterviewQuestionBankQuestionRemoveRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/interviewQuestionBankQuestion/remove', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** updateInterviewQuestionBankQuestion POST /api/interviewQuestionBankQuestion/update */
export async function updateInterviewQuestionBankQuestionUsingPost(
  body: API.InterviewQuestionBankQuestionUpdateRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/interviewQuestionBankQuestion/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
