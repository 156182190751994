<template>
  <a-form
    :model="formParams"
    layout="inline"
    style="margin: 0 0 10px auto"
    label-align="left"
    auto-label-width
    @submit="search"
  >
    <a-form-item field="id" label="ID：">
      <a-input v-model="formParams.id" placeholder="请输入ID..." allow-clear />
    </a-form-item>
    <a-form-item field="userId" label="用户ID：">
      <a-input
        v-model="formParams.userId"
        placeholder="请输入..."
        allow-clear
      />
    </a-form-item>
    <a-form-item field="title" label="标题：">
      <a-input v-model="formParams.title" placeholder="请输入..." allow-clear />
    </a-form-item>
    <a-form-item field="content" label="内容：">
      <a-input
        v-model="formParams.content"
        placeholder="请输入..."
        allow-clear
      />
    </a-form-item>
    <a-form-item field="reviewStatus" label="审核状态：">
      <a-select
        v-model="formParams.reviewStatus"
        placeholder="请输入..."
        allow-clear
        style="width: 100px"
      >
        <a-option :value="REVIEW_STATUS_ENUM.REVIEWING"
          >{{ REVIEW_STATUS_MAP["0"] }}
        </a-option>
        <a-option :value="REVIEW_STATUS_ENUM.PASS"
          >{{ REVIEW_STATUS_MAP["1"] }}
        </a-option>
        <a-option :value="REVIEW_STATUS_ENUM.REJECT"
          >{{ REVIEW_STATUS_MAP["2"] }}
        </a-option>
      </a-select>
    </a-form-item>
    <a-form-item>
      <a-button type="primary" html-type="submit" style="width: 120px"
        >搜索
      </a-button>
    </a-form-item>
  </a-form>
  <a-space size="large">
    <a-form-item>
      <a-button type="primary" @click="add"> 添加题目</a-button>
    </a-form-item>
    <a-form-item label="排序：">
      <a-radio-group v-model="formParams.sortOrder" type="button">
        <a-radio value="ascend">正序</a-radio>
        <a-radio value="descend">倒序</a-radio>
      </a-radio-group>
    </a-form-item>
    <a-form-item label="排序字段：">
      <a-radio-group v-model="formParams.sortField" type="button">
        <a-radio value="createTime">创建时间</a-radio>
        <a-radio value="updateTime">更新时间</a-radio>
      </a-radio-group>
    </a-form-item>
  </a-space>
  <!--  添加窗口 -->
  <a-modal v-model:visible="addVisible" :footer="false" draggable width="auto">
    <template #title> 添加题目</template>
    <div>
      <a-form-item label="标题">
        <a-input
          v-model="addData.title"
          placeholder="请输入标题..."
          allow-clear
        />
      </a-form-item>
      <a-form-item label="内容">
        <a-textarea
          v-model="addData.context"
          placeholder="请输入内容..."
          allow-clear
        />
      </a-form-item>
      <a-form-item label="答案">
        <MdEditor
          v-model:md-text="addData.answer"
          @on-change="(text) => (addData.answer = text)"
        />
      </a-form-item>
      <a-form-item label="标签">
        <TagSelect
          v-model="addData.tags"
          placeholder="可选5个标签，可搜索"
          :limit="5"
        />
      </a-form-item>
      <a-form-item label="是否需要vip">
        <a-radio-group v-model="addData.needVip">
          <a-radio :value="1">VIP</a-radio>
          <a-radio :value="0">免费</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="addSubmit"> 添加</a-button>
      </a-form-item>
    </div>
  </a-modal>
  <!--  修改窗口-->
  <a-modal
    v-model:visible="updateVisible"
    draggable
    :footer="false"
    width="auto"
  >
    <template #title> 修改题目</template>
    <div>
      <a-form-item label="标题">
        <a-input
          v-model="oldData.title"
          placeholder="请输入标题..."
          allow-clear
        />
      </a-form-item>
      <a-form-item label="是否需要vip">
        <a-textarea
          v-model="oldData.content"
          placeholder="请输入内容..."
          allow-clear
        />
      </a-form-item>
      <a-form-item label="答案">
        <MdEditor
          v-model="oldData.answer"
          @on-change="(text) => (oldData.answer = text)"
          biz="InterviewQuestion"
        />
      </a-form-item>
      <a-form-item label="标签">
        <TagSelect
          v-model="oldData.tags"
          placeholder="可选5个标签，可搜索"
          :limit="5"
        />
      </a-form-item>
      <a-form-item label="是否需要vip">
        <a-radio-group v-model="oldData.needVip">
          <a-radio :value="1">VIP</a-radio>
          <a-radio :value="0">免费</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="updateSubmit"> 修改</a-button>
      </a-form-item>
    </div>
  </a-modal>
  <!--  表格-->
  <a-table
    row-key="id"
    v-model:selectedKeys="idList"
    :row-selection="rowSelection"
    :columns="columns"
    :data="dataList"
    :pagination="{
      showTotal: true,
      pageSize: searchParams.pageSize,
      current: searchParams.current,
      total,
    }"
    :stripe="true"
    :bordered="{ cell: true }"
    @page-change="onPageChange"
  >
    <template #content="{ record }">
      <a-button
        @click="$modal.info({ title: '查看问题内容', content: record.content })"
        >查看内容
      </a-button>
    </template>
    <template #answer="{ record }">
      <a-button
        @click="
          answerVisible = true;
          answer = record.answer;
        "
        >查看答案
      </a-button>
    </template>
    <template #picture="{ record }">
      <a-image width="64" :src="record.picture" />
    </template>
    <template #tags="{ record }">
      <a-space>
        <a-tag v-for="tag in JSON.parse(record.tags)" :key="tag">
          {{ tag }}
        </a-tag>
      </a-space>
    </template>
    <template #reviewStatus="{ record }">
      {{ REVIEW_STATUS_MAP[record.reviewStatus] }}
    </template>
    <template #reviewTime="{ record }">
      {{ dayjs(record.reviewTime).format("YYYY-MM-DD") }}
    </template>
    <template #createTime="{ record }">
      {{ dayjs(record.createTime).format("YYYY-MM-DD") }}
    </template>
    <template #updateTime="{ record }">
      {{ dayjs(record.updateTime).format("YYYY-MM-DD") }}
    </template>
    <template #optional="{ record }">
      <a-space>
        <a-button
          type="outline"
          @click="doReview(record.id, REVIEW_STATUS_ENUM.PASS, '符合上架要求')"
          status="success"
          v-if="record.reviewStatus !== REVIEW_STATUS_ENUM.PASS"
        >
          通过
        </a-button>
        <a-button
          type="outline"
          status="warning"
          @click="
            doReview(record.id, REVIEW_STATUS_ENUM.REJECT, '不符合上架要求')
          "
          v-if="record.reviewStatus !== REVIEW_STATUS_ENUM.REJECT"
        >
          拒绝
        </a-button>
        <a-button status="success" type="outline" @click="manage(record.id)">
          管理
        </a-button>
        <a-button type="outline" @click="update(record.id)">修改</a-button>
        <a-button type="outline" status="danger" @click="doDelete(record.id)"
          >删除
        </a-button>
      </a-space>
    </template>
  </a-table>
  <a-modal v-model:visible="answerVisible" :mask="false">
    <template #title>查看答案</template>
    <md-viewer :value="answer" />
  </a-modal>
  <!--  管理窗口-->
  <a-modal v-model:visible="manageVisible" draggable :footer="false">
    <template #title> 管理题库</template>
    <div>
      <a-spin :loading="manageLoading">
        <a-transfer
          show-search
          :data="allBankList"
          v-model:model-value="exitBankList"
          one-way
          :source-input-search-props="{
            placeholder: '搜索不存在的题库',
          }"
          :target-input-search-props="{
            placeholder: '搜索已存在的题库',
          }"
          :title="['不存在的题库', '已存在的题库']"
        >
        </a-transfer>
      </a-spin>

      <a-button
        type="primary"
        @click="allSave()"
        :loading="batchLoading"
        style="margin: 20px 0 0 200px"
      >
        保存
      </a-button>
    </div>
  </a-modal>
</template>
<script setup lang="ts">
import { reactive, ref, watchEffect } from "vue";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import dayjs from "dayjs";
import { REVIEW_STATUS_ENUM, REVIEW_STATUS_MAP } from "@/constant/appEnum";
import {
  addInterviewQuestionUsingPost,
  deleteInterviewQuestionUsingPost,
  doReviewUsingPost,
  getInterviewQuestionVoByIdUsingGet,
  listInterviewQuestionByPageUsingPost,
  updateInterviewQuestionUsingPost,
} from "@/request/api/interviewQuestionController";
import MdEditor from "@/components/md/MdEditor.vue";
import MdViewer from "@/components/md/MdViewer.vue";
import {
  listAllInterviewQuestionBankUsingPost,
  listInterviewQuestionBankByQuestionIdUsingGet,
} from "@/request/api/interviewQuestionBankController";
import { batchUpdateInterviewQuestionUsingPost } from "@/request/api/interviewQuestionBankQuestionController";
import TagSelect from "@/components/selecter/TagSelecter.vue";

//初始值
const initParams = {
  current: 1,
  pageSize: 10,
  sortOrder: "ascend",
  sortField: "createTime",
};
//搜索参数
const formParams = ref<API.InterviewQuestionQueryRequest>({
  ...initParams,
});
//传递参数
const searchParams = ref<API.InterviewQuestionQueryRequest>({});
//数据
const dataList = ref<API.InterviewQuestionVO[]>([]);
//总值
const total = ref<number>(0);
// 多选值
const idList = ref();
const answer = ref<string>();
// 多选规则
const rowSelection = reactive({
  type: "checkbox",
  showCheckedAll: true,
  onlyCurrent: false,
});

/**
 * 加载数据
 */
const loadData = async () => {
  const response = await listInterviewQuestionByPageUsingPost(
    searchParams.value
  );
  if (response.data.code === 0) {
    dataList.value = response.data.data?.records || [];
    total.value = response.data.data?.total || 0;
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
};
/**
 * 搜索
 */
const search = () => {
  searchParams.value = { ...initParams, ...formParams.value };
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * 当分页变化时，改变搜索条件，触发数据加载
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

/**
 * 添加
 */
const addVisible = ref(false);
const add = async () => {
  addVisible.value = true;
  answer.value = "";
};
//添加的数据
const addData = ref<API.InterviewQuestionAddRequest>({});
/**
 * 提交
 */
const addSubmit = async () => {
  const res = await addInterviewQuestionUsingPost(addData.value);
  if (res.data.code === 0) {
    loadData();
    message.success("添加成功");
    addVisible.value = false;
    addData.value = {};
  } else {
    message.error("删除失败，" + res.data.message);
  }
};

/**
 * 修改
 */
//旧的数据
const oldData = ref<API.InterviewQuestionVO>({});
const updateVisible = ref(false);
const update = async (id: number) => {
  updateVisible.value = true;
  const response = await getInterviewQuestionVoByIdUsingGet({
    id: id,
  });
  if (response.data.code === 0) {
    oldData.value = response.data.data || {};
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
};
const updateSubmit = async () => {
  updateVisible.value = true;
  const response = await updateInterviewQuestionUsingPost({
    ...oldData.value,
  });
  if (response.data.code === 0) {
    loadData();
    message.success("修改成功");
    updateVisible.value = false;
  } else {
    message.error("修改失败，" + response.data.message);
  }
};

/**
 * 审核
 */
const doReview = async (id: number, reviewStatus: number, msg: string) => {
  const res = await doReviewUsingPost({
    id,
    reviewStatus,
    reviewMessage: msg,
  });
  if (res.data.code === 0) {
    loadData();
  } else {
    message.error("审核失败，" + res.data.message);
  }
};

/**
 * 删除
 */
const doDelete = async (id: number) => {
  const res = await deleteInterviewQuestionUsingPost({ id });
  if (res.data.code === 0) {
    loadData();
  } else {
    message.error("删除失败，" + res.data.message);
  }
};
const answerVisible = ref<boolean>(false);
/**
 * 管理题库
 */
const manageVisible = ref(false);
const batchLoading = ref(false);
const manageLoading = ref(false);
//所有题库
const allBankList = ref<any>([]);
//已存在的题库
const exitBankList = ref<any>([]);
//选中的题目
const questionId = ref(0);
/**
 * 打开管理界面
 */
const manage = async (id: number) => {
  if (id == null || id == 0) {
    message.error("题目ID不能为空");
    return;
  }
  questionId.value = id;
  manageVisible.value = true;
  await loadBankList();
};

const loadBankList = async () => {
  //搜索所有题库
  manageLoading.value = true;
  const response = await listAllInterviewQuestionBankUsingPost();
  if (response.data.code === 0) {
    allBankList.value = response.data.data?.map((item) => ({
      value: `${item.id}`,
      label: `${item.title}`,
    }));
  } else {
    message.error("加载所有题库数据失败，" + response.data.message);
  }
  // 搜索已经存在的题库
  const res = await listInterviewQuestionBankByQuestionIdUsingGet({
    interviewQuestionId: questionId.value,
  });
  if (res.data.code === 0) {
    exitBankList.value = res.data.data?.map((item) => item.id);
  } else {
    message.error("加载已经存在的题库数据失败，" + res.data.message);
  }
  manageLoading.value = false;
};

/**
 * 保存题目的题库
 */
const allSave = async () => {
  if (!confirm("确定要保存吗？")) {
    return;
  }
  batchLoading.value = true;
  const res = await batchUpdateInterviewQuestionUsingPost({
    id: questionId.value,
    idList: exitBankList.value,
  });
  if (res.data.code === 0) {
    message.success("保存成功");
    manageVisible.value = false;
  } else {
    message.error("保存失败，" + res.data.message);
  }
  batchLoading.value = false;
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "用户ID",
    dataIndex: "userId",
  },
  {
    title: "标题",
    dataIndex: "title",
  },
  {
    title: "内容",
    dataIndex: "content",
    slotName: "content",
  },
  {
    title: "答案",
    dataIndex: "answer",
    slotName: "answer",
  },
  {
    title: "标签",
    slotName: "tags",
    dataIndex: "tags",
  },
  {
    title: "审核状态",
    slotName: "reviewStatus",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    slotName: "createTime",
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    slotName: "updateTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];
</script>
