<template>
  <a-form
    layout="inline"
    style="margin: 0 0 10px auto"
    label-align="left"
    auto-label-width
    @submit="search"
  >
    <a-form-item field="id" label="ID：">
      <a-input v-model="formParams.id" placeholder="请输入ID..." allow-clear />
    </a-form-item>
    <a-form-item field="id" label="业务ID：">
      <a-input
        v-model="formParams.bizId"
        placeholder="请输入业务ID..."
        allow-clear
      />
    </a-form-item>
    <a-form-item field="id" label="业务类型：">
      <a-select allow-clear v-model="formParams.bizType">
        <a-option value="Post">帖子</a-option>
        <a-option value="InterviewQuestion">面试题</a-option>
        <a-option value="App">应用</a-option>
      </a-select>
    </a-form-item>
    <a-form-item field="userId" label="用户ID：">
      <a-input
        v-model="formParams.userId"
        placeholder="请输入..."
        allow-clear
      />
    </a-form-item>
    <a-form-item label="内容：">
      <a-input
        v-model="formParams.content"
        placeholder="请输入..."
        allow-clear
      />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" html-type="submit" style="width: 120px"
        >搜索
      </a-button>
    </a-form-item>
  </a-form>
  <a-space size="large">
    <a-form-item label="排序：">
      <a-radio-group v-model="formParams.sortOrder" type="button">
        <a-radio value="ascend">正序</a-radio>
        <a-radio value="descend">倒序</a-radio>
      </a-radio-group>
    </a-form-item>
    <a-form-item label="排序字段：">
      <a-radio-group v-model="formParams.sortField" type="button">
        <a-radio value="createTime">创建时间</a-radio>
        <a-radio value="updateTime">更新时间</a-radio>
      </a-radio-group>
    </a-form-item>
  </a-space>
  <div>
    <!--  修改窗口-->
    <a-modal
      v-model:visible="updateVisible"
      draggable
      width="600px"
      :footer="false"
    >
      <template #title>修改评论</template>
      <div>
        <a-form :model="oldData">
          <a-form-item>
            <a-space>
              <a-typography-text>内容</a-typography-text>
              <a-input
                v-model="oldData.content"
                placeholder="请输入内容..."
                allow-clear
                style="width: 300px"
              />
            </a-space>
          </a-form-item>
          <a-form-item>
            <a-popconfirm
              content="确定要修改吗"
              @ok="updateSubmit"
              position="bottom"
            >
              <a-button type="primary"> 修改</a-button>
            </a-popconfirm>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
  <!--  表格-->
  <a-table
    :columns="columns"
    :data="dataList"
    :pagination="{
      showTotal: true,
      pageSize: searchParams.pageSize,
      current: searchParams.current,
      total,
    }"
    :stripe="true"
    :bordered="{ cell: true }"
    :loading="loading"
    @page-change="onPageChange"
  >
    <template #createTime="{ record }">
      {{ dayjs(record.createTime).format("YYYY-MM-DD") }}
    </template>
    <template #updateTime="{ record }">
      {{ dayjs(record.updateTime).format("YYYY-MM-DD") }}
    </template>
    <template #optional="{ record }">
      <a-space>
        <a-button type="outline" @click="update(record)">修改</a-button>
        <a-button type="outline" status="danger" @click="doDelete(record.id)"
          >删除
        </a-button>
      </a-space>
    </template>
  </a-table>
</template>
<script setup lang="ts">
import { ref, watchEffect } from "vue";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import dayjs from "dayjs";

import {
  listCommentByPageUsingPost,
  updateCommentUsingPost,
} from "@/request/api/commentController";
import { deleteCommentUsingPost } from "@/request/api/commentController";
//初始值
const initParams = {
  current: 1,
  pageSize: 10,
  sortOrder: "ascend",
  sortField: "createTime",
};
//搜索参数
const formParams = ref<API.UserQueryRequest>({
  ...initParams,
});
//传递参数
const searchParams = ref<API.UserQueryRequest>({});
//数据
const dataList = ref<API.User[]>([]);
//总值
const total = ref<number>(0);
const loading = ref(false);
/**
 * 加载数据
 */
const loadData = async () => {
  loading.value = true;
  const response = await listCommentByPageUsingPost(searchParams.value);
  if (response.data.code === 0) {
    dataList.value = response.data.data?.records || [];
    total.value = response.data.data?.total || 0;
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
  loading.value = false;
};
/**
 * 搜索
 */
const search = () => {
  searchParams.value = { ...initParams, ...formParams.value };
};
/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * 当分页变化时，改变搜索条件，触发数据加载
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

/**
 * 修改
 */
//旧的数据
const oldData = ref<API.Comment>({});
const updateVisible = ref(false);
const update = async (item: API.Comment) => {
  updateVisible.value = true;
  oldData.value = { ...item };
};
const updateSubmit = async () => {
  updateVisible.value = true;
  const response = await updateCommentUsingPost({
    id: oldData.value.id,
    content: oldData.value.content,
  });
  if (response.data.code === 0) {
    message.success("修改成功");
    updateVisible.value = false;
    loadData();
  } else {
    message.error("修改失败，" + response.data.message);
  }
};

/**
 * 删除
 */
const doDelete = async (id: number) => {
  if (!confirm("确定要删除吗？")) {
    return;
  }
  const res = await deleteCommentUsingPost({ id });
  if (res.data.code === 0) {
    loadData();
  } else {
    message.error("删除失败，" + res.data.message);
  }
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "业务ID",
    dataIndex: "bizId",
  },
  {
    title: "业务类型",
    dataIndex: "bizType",
  },
  {
    title: "父ID",
    dataIndex: "pid",
  },
  {
    title: "内容",
    dataIndex: "content",
  },
  {
    title: "用户ID",
    dataIndex: "userId",
  },
  {
    title: "对谁评论",
    dataIndex: "toUserId",
  },
  {
    title: "点赞数",
    dataIndex: "thumbNum",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    slotName: "createTime",
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    slotName: "updateTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];
</script>
