<template>
  <div class="home">
    <div class="contextDiv">
      <a-row style="margin: 20px 0; width: 100%">
        <a-col flex="100px">
          <span style="font-size: 24px; font-weight: bold"> 面试题库 </span>
        </a-col>
        <a-col flex="auto"></a-col>
        <a-col flex="275px">
          <a-input-search
            placeholder="搜索题库"
            v-model="searchText"
            @search="search"
            @keyup.enter="search"
            search-button
          >
          </a-input-search>
        </a-col>
      </a-row>
      <a-list
        :grid-props="{ gutter: [20, 20], md: 6 }"
        :bordered="false"
        :data="dataList"
        :pagination-props="{
          pageSize: searchParams.pageSize,
          current: searchParams.current,
          total,
        }"
        :loading="loading"
        @page-change="onPageChange"
      >
        <template #item="{ item }">
          <a-card hoverable class="card" @click="click(item.id)">
            <a-space size="medium">
              <a-image :src="item.picture" width="40" />
              <a-space direction="vertical">
                <a-typography-text>
                  {{ item.title }}
                </a-typography-text>
                <div class="des">
                  {{ item.description }}
                </div>
              </a-space>
            </a-space>
          </a-card>
        </template>
      </a-list>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, watchEffect, withDefaults } from "vue";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import { listInterviewQuestionBankVoByPageUsingPost } from "@/request/api/interviewQuestionBankController";
import router from "@/router";

interface Props {
  pageSize: number;
}

const props = withDefaults(defineProps<Props>(), {
  pageSize: () => {
    return 20;
  },
});
// 题库搜索条件
const searchParams = ref<API.InterviewQuestionBankQueryRequest>({
  current: 1,
  pageSize: props.pageSize,
  sortField: "createTime",
  sortOrder: "ascend",
});
// 题目搜索关键词
const searchText = ref("");
//数据
const dataList = ref<API.InterviewQuestionBankVO[]>([]);
//总值
const total = ref<number>(0);
const loading = ref(false);

/**
 * 搜索
 */
const search = async () => {
  if (searchText.value == "") {
    await loadData();
    return;
  }
  const response = await listInterviewQuestionBankVoByPageUsingPost({
    searchText: searchText.value,
    ...searchParams.value,
  });
  if (response.data.code === 0) {
    dataList.value = response.data.data?.records || [];
    total.value = response.data.data?.total || 0;
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
};

/**
 * 加载数据
 */
const loadData = async () => {
  loading.value = true;
  const response = await listInterviewQuestionBankVoByPageUsingPost(
    searchParams.value
  );
  if (response.data.code === 0) {
    dataList.value = response.data.data?.records || [];
    total.value = response.data.data?.total || 0;
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
  loading.value = false;
};
/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * 当分页变化时，改变搜索条件，触发数据加载
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

/**
 * 点击题库
 */
const click = (id: number) => {
  router.push({
    path: `/bank/detail/${id}`,
  });
};
</script>

<style scoped>
.home {
  margin: 24px auto 0;
}

.card {
  width: 250px;
  margin-top: 10px;
  padding: 0 10px;
  border-radius: 20px;
}

.contextDiv {
  max-width: 1200px;
  margin: 20px auto;
}

.des {
  max-width: 170px;
  overflow: hidden;
  color: #999999;
  font-weight: 100;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card:hover {
  transform: translateY(-4px);
}
</style>
