<template>
  <div id="noAuthPage">
    <h1>无权限访问</h1>
    <a-link href="/"><h2>返回主页</h2></a-link>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped>
#noAuthPage {
  padding-top: 200px;
  text-align: center;
}
</style>
