<template>
  <a-link @click="checkIn"> 打卡学习</a-link>
</template>
<script setup lang="ts">
import { addSignInUsingPost } from "@/request/api/userController";
import message from "@arco-design/web-vue/es/message";
import router from "@/router";

const checkIn = async () => {
  const res = await addSignInUsingPost();
  if (res.data.code === 0) {
    if (res.data.data == true) {
      message.success("签到成功");
      //刷新页面
      router.go(0);
    } else {
      message.success("今天已经签到过了哦");
    }
  } else {
    message.error("签到失败" + res.data.message);
  }
};
</script>
<style scoped></style>
