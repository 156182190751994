<template>
  <div class="AppStatisticPage" style="width: 1000px">
    <h2>热门应用统计</h2>
    <a-form-item>
      <a-input-number
        :style="{ width: '240px' }"
        placeholder="请输入限制数量"
        v-model="limit"
        allow-clear
      ></a-input-number>
      <a-button type="primary" @click="getTopApp">查看</a-button>
    </a-form-item>
    <v-chart :option="appAnswerCountOptions" style="height: 300px" />

    <h2>应用结果统计</h2>
    <a-form-item>
      <a-input-number
        :style="{ width: '240px' }"
        placeholder="请输入appId"
        v-model="appId"
        allow-clear
      ></a-input-number>
      <a-button type="primary" @click="getResult">查看</a-button>
    </a-form-item>
    <v-chart :option="appAnswerResultCountOptions" style="height: 300px" />
  </div>
</template>

<script setup lang="ts">
import "echarts";
import VChart from "vue-echarts";
import { computed, ref, watchEffect } from "vue";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import {
  getScoringResultCountByAppIdUsingGet,
  getTopAppUsingGet,
} from "@/request/api/appStatisticController";
//appId
const appId = ref<any>(null);
//limit
const limit = ref<number>(10);
//热门应用数据
const AppAnswerCountDTOList = ref<API.AppAnswerCountDTO[]>([]);
//应用结果统计数据
const AppResultCountDTOList = ref<API.AppResultCountDTO[]>([]);

/**
 * 查看应用结果分布
 */
const getTopApp = async () => {
  const response = await getTopAppUsingGet({ limit: limit.value });
  if (response.data.code === 0) {
    AppAnswerCountDTOList.value = response.data.data || [];
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
};

/**
 * 查看应用结果分布
 */
const getResult = async () => {
  if (!appId.value || appId.value <= 0) {
    return;
  }
  const response = await getScoringResultCountByAppIdUsingGet({
    appId: appId.value,
  });
  if (response.data.code === 0) {
    AppResultCountDTOList.value = response.data.data || [];
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
};
/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  getTopApp();
});

const appAnswerCountOptions = computed(() => {
  return {
    xAxis: {
      type: "category",
      data: AppAnswerCountDTOList.value.map(
        (item) => item.app?.appName + "\n" + item.appId
      ),
      name: "应用名",
    },
    yAxis: {
      type: "value",
      name: "做题用户数",
    },
    series: [
      {
        data: AppAnswerCountDTOList.value.map((item) => item.answerCount),
        type: "bar",
      },
    ],
    tooltip: {
      axisPointer: {
        type: "shadow",
      },
    },
    visualMap: {
      orient: "horizontal",
      left: "center",
      min: 10,
      max: 100,
      text: ["更多人", "更少人"],
      // Map the score column to color
      inRange: {
        color: ["#65B581", "#FFCE34", "#FD665F"],
      },
    },
  };
});

const appAnswerResultCountOptions = computed(() => {
  return {
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
    },
    series: [
      {
        type: "pie",
        radius: "50%",
        data: AppResultCountDTOList.value.map((item) => {
          return {
            value: item.resultNameCount,
            name: item.resultName,
          };
        }),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
});
</script>

<style scoped>
.AppStatisticPage {
  width: 100%;
  max-width: 1400px;
  margin: 24px auto 0;
}

h2 {
  margin-top: 50px;
}
</style>
