<template>
  <div class="appDetail">
    <a-card class="appDetailCart">
      <a-row>
        <a-col flex="auto" class="content">
          <h2>{{ data.appName }}</h2>
          <p>{{ data.appDesc }}</p>
          <p>应用类型：{{ APP_TYPE_MAP[data.appType] }}</p>
          <p>评分策略：{{ APP_SCORING_STRATEGY_MAP[data.scoringStrategy] }}</p>
          <a-space>
            <p>作者：</p>
            <a-avatar :image-url="data.user?.userAvatar"></a-avatar>
            <a-typography-text>{{ data.user?.userName }}</a-typography-text>
          </a-space>
          <p>
            创建时间：{{ dayjs(data.createTime).format("YYYY-MM-DD HH:mm:ss") }}
          </p>
          <a-space size="large" style="width: 200px; font-size: 15px">
            <a-space>
              <IconEye />
              <a-typography-text>
                {{ data.viewNum }}
              </a-typography-text>
            </a-space>
            <a-space>
              <div @click="onLikeChange">
                <span v-if="like">
                  <IconHeartFill :style="{ color: '#f53f3f' }" />
                </span>
                <span v-else>
                  <IconHeart />
                </span>
              </div>
              <a-typography-text>
                {{ data.thumbNum }}
              </a-typography-text>
            </a-space>
            <a-space>
              <div @click="onStarChange">
                <span v-if="star">
                  <IconStarFill style="color: #fff35d" />
                </span>
                <span v-else>
                  <IconStar />
                </span>
              </div>
              <a-typography-text>
                {{ data.favourNum }}
              </a-typography-text>
            </a-space>
          </a-space>
          <br />
          <a-space size="medium">
            <a-button type="primary" @click="answer">开始答题</a-button>
            <a-button>
              <share-trigger :app-id="props.id" />
            </a-button>
            <a-button
              type="outline"
              @click="click(`/add/app/${id}`)"
              v-if="isMy"
            >
              修改应用
            </a-button>
            <a-button
              type="outline"
              @click="click(`/add/question/${id}`)"
              v-if="isMy"
            >
              设置题目
            </a-button>
            <a-button
              type="outline"
              @click="click(`/add/scoringResult/${id}`)"
              v-if="isMy"
              >设置评分
            </a-button>
          </a-space>
          <a-steps :current="data.reviewStatus + 3" v-if="isMy">
            <a-step description="没审核也可以分享给小伙伴，但不能上架首页哦"
              >创建成功
            </a-step>
            <a-step description="等待管理员审核">待审核</a-step>
            <a-step
              v-if="data.reviewStatus != 2"
              :description="data.reviewMessage"
              >审核成功
            </a-step>
            <a-step
              v-if="data.reviewStatus == 2"
              :description="data.reviewMessage"
              status="error"
              >审核失败
            </a-step>
          </a-steps>
        </a-col>

        <a-col flex="320px">
          <a-image
            width="100%"
            :src="data.appIcon"
            style="margin: 50px 0"
          ></a-image>
        </a-col>
      </a-row>
    </a-card>
    <comment-cart
      :biz-id="props.id"
      biz-type="App"
      style="border: 1px solid rgba(128, 128, 128, 0.2)"
    ></comment-cart>
  </div>
</template>

<script setup lang="ts">
import { getAppVoByIdUsingGet } from "@/api/appController";

import API from "@/api";
import { computed, defineProps, ref, watchEffect, withDefaults } from "vue";
import message from "@arco-design/web-vue/es/message";
import { APP_SCORING_STRATEGY_MAP, APP_TYPE_MAP } from "../../constant/appEnum";
import dayjs from "dayjs";
import { useLoginUserStore } from "@/store/userStore";
import ShareTrigger from "@/components/trigger/ShareTrigger.vue";
import CommentCart from "@/components/list/CommentCart.vue";
import {
  doAppFavourUsingPost,
  doAppThumbUsingPost,
} from "@/request/api/appController";
import router from "@/router";

interface Props {
  id: number;
}

const props = withDefaults(defineProps<Props>(), {
  id: () => {
    return 0;
  },
});

const data = ref<API.AppVO>({});
//查询登录用户
const loginUserStore = useLoginUserStore();
const loginUserId = loginUserStore.loginUser?.id;
const isMy = computed(() => {
  return loginUserId && loginUserId === data.value.userId;
});

const like = ref<boolean>(false);
const star = ref<boolean>(false);
/**
 * 加载数据
 */
const loadData = async () => {
  if (!props.id) {
    return;
  }
  const response = await getAppVoByIdUsingGet({
    id: props.id,
  });
  if (response.data.code === 0 && response.data.data) {
    data.value = response.data.data;
    like.value = response.data.data?.isThumb;
    star.value = response.data.data?.isFavour;
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});
/**
 * 答题
 */
const answer = () => router.push(`/answer/do/${props.id}`);

/**
 * 点赞
 */
const onLikeChange = () => {
  if (like.value) {
    data.value.thumbNum--;
  } else {
    data.value.thumbNum++;
    message.success("点赞成功");
  }
  like.value = !like.value;
  doAppThumbUsingPost({ bizId: props.id, bizType: "App" });
};

/**
 * 收藏
 */
const onStarChange = () => {
  if (star.value) {
    data.value.favourNum--;
  } else {
    data.value.favourNum++;
    message.success("收藏成功");
  }
  star.value = !star.value;
  doAppFavourUsingPost({ bizId: props.id, bizType: "App" });
};
const click = (url: string) => {
  router.push({ path: url });
};
</script>

<style scoped>
.appDetail {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.appDetailCart {
  margin: auto 0;
  padding: 0 50px;
  border-radius: 20px;
}

.appDetail .content > * {
  margin-bottom: 24px;
}
</style>
