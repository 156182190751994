<template>
  <a-form
    :model="searchParams"
    layout="inline"
    style="margin: 0 0 10px auto"
    label-align="left"
    auto-label-width
    @submit="search"
  >
    <a-form-item label="搜索内容">
      <a-input
        v-model="searchParams.searchText"
        placeholder="请输入..."
        allow-clear
      />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" html-type="submit" style="width: 120px"
        >搜索
      </a-button>
    </a-form-item>
  </a-form>
  <a-list
    :bordered="false"
    :data="dataList"
    :pagination-props="{
      pageSize: searchParams.pageSize,
      current: searchParams.current,
      total,
    }"
    :stripe="true"
    @page-change="onPageChange"
    :loading="loading"
  >
    <template #item="{ item }">
      <a-list-item class="list-item" action-layout="vertical">
        <template #actions>
          <span class="action" @click="onLikeChange(item)">
            <span v-if="item.isThumb">
              <IconHeartFill :style="{ color: '#f53f3f' }" />
            </span>
            <span v-else>
              <IconHeart />
            </span>
            {{ item.thumbNum }}
          </span>
          <span class="action" @click="onStarChange(item)">
            <span v-if="item.isFavour">
              <IconStarFill style="color: #fff35d" />
            </span>
            <span v-else>
              <IconStar />
            </span>
            {{ item.favourNum }}
          </span>
          <span
            class="action"
            @click="onClickMenuItem(`/post/detail/${item.id}`)"
          >
            <icon-message />回复
          </span>
          <a-space size="medium">
            <span v-if="isMy" class="action" @click="edit(item.id)">
              <icon-edit />
            </span>
            <a-popconfirm
              content="你确定要删除吗?"
              @ok="remove(item.id)"
              position="bottom"
            >
              <span v-if="isMy" class="action">
                <icon-delete />
              </span>
            </a-popconfirm>
          </a-space>
        </template>
        <a-list-item-meta style="padding: 10px 0">
          <template #title>
            <a-space>
              <a-avatar>
                <img alt="avatar" :src="item.user.userAvatar" />
              </a-avatar>
              <div>
                <a-typography-text style="font-size: 16px; font-weight: bold">
                  {{ item.user.userName }}
                </a-typography-text>
                <br />
                <a-typography-text style="color: #9f9f9f">
                  {{ dayjs(item.createTime).format("YYYY-MM-DD HH:mm:ss") }}
                </a-typography-text>
              </div>
            </a-space>
          </template>
          <template #description>
            <div
              @click="onClickMenuItem(`/post/detail/${item.id}`)"
              class="postContent"
            >
              <div v-if="item.title">
                <a-typography-text style="font-size: 20px">
                  {{ item.title }}
                </a-typography-text>
              </div>
              <md-viewer :value="item.content" />
              <br />
            </div>
            <a-image-preview-group v-if="item.imgList.length > 0">
              <a-space size="large" style="margin: 10px 20px 0">
                <a-image
                  v-for="img in item.imgList"
                  :key="img"
                  :src="img"
                  width="200"
                />
              </a-space>
            </a-image-preview-group>
            <div v-if="item.tagList.length > 0">
              <a-space style="margin: 10px 20px 0">
                <a-tag v-for="tag in item.tagList" :key="tag">
                  {{ tag }}
                </a-tag>
              </a-space>
            </div>
          </template>
        </a-list-item-meta>
      </a-list-item>
    </template>
  </a-list>
</template>
<script setup lang="ts">
import dayjs from "dayjs";
import MdViewer from "@/components/md/MdViewer.vue";
import router from "@/router";
import { defineProps, ref, watchEffect, withDefaults } from "vue";
import API from "@/api";
import {
  deletePostUsingPost,
  listPostVoByPageUsingPost,
} from "@/api/postController";
import message from "@arco-design/web-vue/es/message";
import {
  doPostFavourUsingPost,
  doPostThumbUsingPost,
} from "@/request/api/postController";
import ShareTrigger from "@/components/trigger/ShareTrigger.vue";
import { useLoginUserStore } from "@/store/userStore";
import {
  APP_SCORING_STRATEGY_ENUM,
  APP_SCORING_STRATEGY_MAP,
  APP_TYPE_ENUM,
  APP_TYPE_MAP,
} from "@/constant/appEnum";

interface Props {
  userId: number;
}

const props = withDefaults(defineProps<Props>(), {
  userId: () => {
    return 0;
  },
});
//查询参数
const searchParams = ref<API.PostQueryRequest>({
  current: 1,
  pageSize: 10,
  userId: props.userId,
  sortField: "createTime",
  sortOrder: "descend",
});
//总值
const total = ref<number>(0);
//是否显示加载
const loading = ref(true);
// 帖子列表
const dataList = ref<API.PostVO[]>([]);
//搜索关键词
const searchText = ref<string>("");

//总值
const loginUser = ref<API.UserVO>(useLoginUserStore().loginUser);
const isMy = ref(props.userId == loginUser.value.id);
/**
 * 搜索
 */
const search = async () => {
  if (searchText.value == "") {
    return;
  }
};
/**
 * 加载数据
 */
const loadData = async () => {
  loading.value = true;
  const response = await listPostVoByPageUsingPost(searchParams.value);
  if (response.data.code === 0 && response.data.data) {
    dataList.value = response.data.data?.records || [];
    total.value = response.data.data?.total || 0;
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
  loading.value = false;
};

/**
 * 当分页变化时，改变搜索条件，触发数据加载
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

/**
 * 点击菜单项
 * @param id
 */
const onClickMenuItem = async (url: string) => {
  router.push(url);
};

/**
 * 点赞
 */
const onLikeChange = (item: any) => {
  if (item.isThumb == true) {
    item.thumbNum--;
  } else {
    item.thumbNum++;
  }
  item.isThumb = !item.isThumb;
  doPostThumbUsingPost({ bizId: item.id, bizType: "Post" });
};

/**
 * 收藏
 */
const onStarChange = (item: any) => {
  if (item.isFavour == true) {
    item.favourNum--;
  } else {
    item.favourNum++;
  }
  item.isFavour = !item.isFavour;
  doPostFavourUsingPost({ bizId: item.id, bizType: "Post" });
};

/**
 * 删除帖子
 */
const remove = async (id: number) => {
  const response = await deletePostUsingPost({ id: id });
  if (response.data.code === 0) {
    message.success("删除成功");
    loadData();
  } else {
    message.error("删除失败，" + response.data.message);
  }
};
/**
 * 编辑帖子
 */
const edit = async (id: number) => {
  router.push("/post/edit/" + id);
};
/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  if (searchParams.value) {
    loadData();
  }
});
</script>
<style scoped></style>
