<template>
  <div id="answerResultPage">
    <a-card :bordered="false">
      <a-row>
        <a-col flex="800px" class="contentWrapper" style="margin-left: 50px">
          <h2>{{ data.resultName }}</h2>
          <p>结果描述：{{ data.resultDesc }}</p>
          <p v-if="app.appType == APP_TYPE_ENUM.SCORE">
            结果得分：{{ data.resultScore }}
          </p>
          <p>我的选择：{{ data.choices }}</p>
          <p>应用类型：{{ APP_TYPE_MAP[data.appType] }}</p>
          <p>评分策略：{{ APP_SCORING_STRATEGY_MAP[data.scoringStrategy] }}</p>
          <div :style="{ display: 'flex', alignItems: 'center' }">
            <span>答题人：</span>
            <a-avatar
              :size="24"
              :style="{ marginRight: '8px' }"
              :image-url="data.user?.userAvatar"
            />
            <a-typography-text>{{ data.user?.userName }}</a-typography-text>
          </div>
          <p>
            答题时间：{{ dayjs(data.createTime).format("YYYY-MM-DD HH:mm:ss") }}
          </p>
          <a-space size="medium">
            <a-button type="primary" @click="click(`/answer/do/${data.appId}`)">
              再次答题
            </a-button>
            <a-button @click="click(`/app/detail/${data.appId}`)">
              回到首页
            </a-button>
            <a-button @click="click(`/app/detail/${data.appId}`)">
              查看应用
            </a-button>
          </a-space>
        </a-col>
        <a-col flex="400px">
          <a-image
            width="320px"
            style="margin: 50px"
            :src="data?.resultPicture"
          />
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, watchEffect, withDefaults } from "vue";
import API from "@/api";
import { getUserAnswerVoByIdUsingGet } from "@/api/userAnswerController";
import message from "@arco-design/web-vue/es/message";
import {
  APP_SCORING_STRATEGY_MAP,
  APP_TYPE_ENUM,
  APP_TYPE_MAP,
} from "@/constant/appEnum";
import { dayjs } from "@arco-design/web-vue/es/_utils/date";
import { getAppVoByIdUsingGet } from "@/api/appController";
import router from "@/router";

interface Props {
  id: number;
}

const props = withDefaults(defineProps<Props>(), {
  id: () => 0,
});
//数据
const data = ref<API.UserAnswerVO>({});
//appId
const appId = ref<any>("");
//应用数据
const app = ref<API.AppVO>({});
/**
 * 加载数据
 */
const loadData = async () => {
  if (!props.id) {
    return;
  }
  //获取结果信息
  const res = await getUserAnswerVoByIdUsingGet({
    id: props.id as any,
  });
  if (res.data.code === 0 && res.data.data) {
    data.value = res.data.data;
    appId.value = res.data.data.appId;
  } else {
    message.error("获取数据失败，" + res.data.message);
  }
  const appRes = await getAppVoByIdUsingGet({
    id: appId.value,
  });
  //获取应用信息
  if (appRes.data.code === 0 && appRes.data.data) {
    app.value = appRes.data.data;
  } else {
    message.error("获取应用信息失败，" + appRes.data.message);
  }
};
const click = (url: string) => {
  router.push({ path: url });
};
/**
 * 监听变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});
</script>

<style scoped>
#answerResultPage {
  margin: 30px 200px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 1px 1px #e8e8e8;
}

#answerResultPage .contentWrapper > * {
  margin-bottom: 24px;
}
</style>
