<template>
  <a-form
    :model="formParams"
    layout="inline"
    style="margin: 0 0 10px auto"
    label-align="left"
    auto-label-width
    @submit="search"
  >
    <a-form-item field="id" label="ID：">
      <a-input v-model="formParams.id" placeholder="请输入ID..." allow-clear />
    </a-form-item>
    <a-form-item field="appType" label="应用类型：">
      <a-select
        v-model="formParams.appType"
        placeholder="请输入..."
        allow-clear
        style="width: 100px"
      >
        <a-option :value="APP_TYPE_ENUM.SCORE"
          >{{ APP_TYPE_MAP["0"] }}
        </a-option>
        <a-option :value="APP_TYPE_ENUM.TEST">{{ APP_TYPE_MAP["1"] }}</a-option>
      </a-select>
    </a-form-item>
    <a-form-item field="scoringStrategy" label="评分策略：">
      <a-select
        v-model="formParams.scoringStrategy"
        placeholder="请输入..."
        allow-clear
        style="width: 100px"
      >
        <a-option :value="APP_SCORING_STRATEGY_ENUM.CUSTOM"
          >{{ APP_SCORING_STRATEGY_MAP["0"] }}
        </a-option>
        <a-option :value="APP_SCORING_STRATEGY_ENUM.AI"
          >{{ APP_SCORING_STRATEGY_MAP["1"] }}
        </a-option>
      </a-select>
    </a-form-item>
    <a-form-item field="resultName" label="结果名称：">
      <a-input
        v-model="formParams.resultName"
        placeholder="请输入..."
        allow-clear
      />
    </a-form-item>
    <a-form-item field="resultDesc" label="结果描述：">
      <a-input v-model="formParams.resultDesc" placeholder="请输入..." />
    </a-form-item>

    <a-form-item>
      <a-button type="primary" html-type="submit" style="width: 120px"
        >搜索
      </a-button>
    </a-form-item>
  </a-form>
  <a-table
    :columns="columns"
    :data="dataList"
    :pagination="{
      showTotal: true,
      pageSize: searchParams.pageSize,
      current: searchParams.current,
      total,
    }"
    :stripe="true"
    :bordered="{ cell: true }"
    @page-change="onPageChange"
  >
    <template #appType="{ record }">
      {{ APP_TYPE_MAP[record.appType] }}
    </template>
    <template #scoringStrategy="{ record }">
      {{ APP_SCORING_STRATEGY_MAP[record.scoringStrategy] }}
    </template>
    <template #resultPicture="{ record }">
      <a-image width="64" :src="record.resultPicture" />
    </template>
    <template #choices="{ record }">
      <a-button
        @click="$modal.info({ title: '查看选项', content: record.choices })"
        >查看选项
      </a-button>
    </template>
    <template #resultDesc="{ record }">
      <a-button
        @click="$modal.info({ title: '查看描述', content: record.resultDesc })"
        >查看描述
      </a-button>
    </template>
    <template #createTime="{ record }">
      {{ dayjs(record.createTime).format("YYYY-MM-DD") }}
    </template>
    <template #updateTime="{ record }">
      {{ dayjs(record.updateTime).format("YYYY-MM-DD") }}
    </template>
    <template #optional="{ record }">
      <a-space>
        <a-button type="outline" @click="click(`/answer/result/${record.id}`)"
          >查看结果
        </a-button>
        <a-button type="outline" status="danger" @click="doDelete(record.id)"
          >删除
        </a-button>
      </a-space>
    </template>
    <template #tags="{ record }">
      <a-space wrap>
        <a-tag v-for="(tag, index) of record.tags" :key="index" color="green"
          >{{ tag }}
        </a-tag>
      </a-space>
    </template>
  </a-table>
</template>
<script setup lang="ts">
import { ref, watchEffect } from "vue";
import API from "@/api";
import {
  deleteUserAnswerUsingPost,
  listUserAnswerByPageUsingPost,
} from "@/api/userAnswerController";
import message from "@arco-design/web-vue/es/message";
import dayjs from "dayjs";
import {
  APP_SCORING_STRATEGY_ENUM,
  APP_SCORING_STRATEGY_MAP,
  APP_TYPE_ENUM,
  APP_TYPE_MAP,
} from "@/constant/appEnum";
import router from "@/router";
//初始值
const initParams = {
  current: 1,
  pageSize: 10,
  sortOrder: "ascend",
  sortField: "createTime",
};
//搜索参数
const formParams = ref<API.UserAnswerQueryRequest>({
  ...initParams,
});
//传递参数
const searchParams = ref<API.UserAnswerQueryRequest>({});
//数据
const dataList = ref<API.UserAnswer[]>([]);
//总值
const total = ref<number>(0);
/**
 * 加载数据
 */
const loadData = async () => {
  const response = await listUserAnswerByPageUsingPost(searchParams.value);
  if (response.data.code === 0) {
    dataList.value = response.data.data?.records || [];
    total.value = response.data.data?.total || 0;
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
};
/**
 * 搜索
 */
const search = () => {
  searchParams.value = { ...initParams, ...formParams.value };
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * 当分页变化时，改变搜索条件，触发数据加载
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

/**
 * 更新
 */
const doUpdate = (id: number) => {
  console.log("更新", id);
};
const click = (url: string) => {
  router.push({ path: url });
};
/**
 * 删除
 */
const doDelete = async (id: number) => {
  const res = await deleteUserAnswerUsingPost({ id });
  if (res.data.code === 0) {
    loadData();
  } else {
    message.error("删除失败，" + res.data.message);
  }
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "应用ID",
    dataIndex: "appId",
  },
  {
    title: "应用类型",
    dataIndex: "appType",
    slotName: "appType",
  },
  {
    title: "评分策略",
    dataIndex: "scoringStrategy",
    slotName: "scoringStrategy",
  },
  {
    title: "选项",
    dataIndex: "choices",
    slotName: "choices",
  },
  {
    title: "结果ID",
    dataIndex: "resultId",
  },
  {
    title: "结果名称",
    dataIndex: "resultName",
  },
  {
    title: "结果描述",
    dataIndex: "resultDesc",
    slotName: "resultDesc",
  },
  {
    title: "结果图片",
    dataIndex: "resultPicture",
    slotName: "resultPicture",
  },
  {
    title: "结果分数",
    dataIndex: "resultScore",
  },
  {
    title: "用户ID",
    dataIndex: "userId",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    slotName: "createTime",
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    slotName: "updateTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
  {
    title: "标签",
    slotName: "tags",
  },
];
</script>
