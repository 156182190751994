<template>
  <a-form
    :model="formParams"
    layout="inline"
    style="margin: 0 0 10px auto"
    label-align="left"
    auto-label-width
    @submit="search"
  >
    <a-form-item field="id" label="ID：">
      <a-input v-model="formParams.id" placeholder="请输入ID..." allow-clear />
    </a-form-item>
    <a-form-item field="userId" label="用户ID：">
      <a-input
        v-model="formParams.userId"
        placeholder="请输入..."
        allow-clear
      />
    </a-form-item>
    <a-form-item field="appName" label="应用名：">
      <a-input
        v-model="formParams.appName"
        placeholder="请输入..."
        allow-clear
      />
    </a-form-item>
    <a-form-item field="appDesc" label="应用描述：">
      <a-input
        v-model="formParams.appDesc"
        placeholder="请输入..."
        allow-clear
      />
    </a-form-item>
    <a-form-item field="appType" label="应用类型：">
      <a-select
        v-model="formParams.appType"
        placeholder="请输入..."
        allow-clear
        style="width: 100px"
      >
        <a-option :value="APP_TYPE_ENUM.SCORE"
          >{{ APP_TYPE_MAP["0"] }}
        </a-option>
        <a-option :value="APP_TYPE_ENUM.TEST">{{ APP_TYPE_MAP["1"] }}</a-option>
      </a-select>
    </a-form-item>
    <a-form-item field="scoringStrategy" label="评分策略：">
      <a-select
        v-model="formParams.scoringStrategy"
        placeholder="请输入..."
        allow-clear
        style="width: 100px"
      >
        <a-option :value="APP_SCORING_STRATEGY_ENUM.CUSTOM"
          >{{ APP_SCORING_STRATEGY_MAP["0"] }}
        </a-option>
        <a-option :value="APP_SCORING_STRATEGY_ENUM.AI"
          >{{ APP_SCORING_STRATEGY_MAP["1"] }}
        </a-option>
      </a-select>
    </a-form-item>
    <a-form-item field="reviewStatus" label="审核状态：">
      <a-select
        v-model="formParams.reviewStatus"
        placeholder="请输入..."
        allow-clear
        style="width: 100px"
      >
        <a-option :value="REVIEW_STATUS_ENUM.REVIEWING"
          >{{ REVIEW_STATUS_MAP["0"] }}
        </a-option>
        <a-option :value="REVIEW_STATUS_ENUM.PASS"
          >{{ REVIEW_STATUS_MAP["1"] }}
        </a-option>
        <a-option :value="REVIEW_STATUS_ENUM.REJECT"
          >{{ REVIEW_STATUS_MAP["2"] }}
        </a-option>
      </a-select>
    </a-form-item>
    <a-form-item field="reviewerId" label="审核ID：">
      <a-input v-model="formParams.reviewerId" placeholder="请输入..." />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" html-type="submit" style="width: 120px"
        >搜索
      </a-button>
    </a-form-item>
  </a-form>
  <a-space size="large">
    <a-form-item>
      <a-button @click="router.push('/add/app')" type="primary"
        >添加应用
      </a-button>
    </a-form-item>
    <a-form-item label="排序：">
      <a-radio-group v-model="formParams.sortOrder" type="button">
        <a-radio value="ascend">正序</a-radio>
        <a-radio value="descend">倒序</a-radio>
      </a-radio-group>
    </a-form-item>
    <a-form-item label="排序字段：">
      <a-radio-group v-model="formParams.sortField" type="button">
        <a-radio value="createTime">创建时间</a-radio>
        <a-radio value="updateTime">更新时间</a-radio>
      </a-radio-group>
    </a-form-item>
  </a-space>
  <!--  修改窗口-->
  <a-modal v-model:visible="updateVisible" :footer="false">
    <template #title> 修改题目</template>
    <a-form :model="updateDate">
      <a-form-item field="appName" label="应用名称" required>
        <a-input v-model="updateDate.appName" placeholder="请输入应用名称..." />
      </a-form-item>
      <a-form-item field="appDesc" label="应用描述">
        <a-input v-model="updateDate.appDesc" placeholder="请输入应用描述..." />
      </a-form-item>
      <a-form-item field="appIcon" label="应用图标" tooltip="输入图标链接">
        <a-space size="large">
          <a-image
            v-show="updateDate.appIcon"
            :src="updateDate.appIcon"
            width="80px"
          ></a-image>
          <picture-upload
            biz="app_icon"
            :value="updateDate.appIcon"
            :onChange="(url) => (updateDate.appIcon = url)"
          />
        </a-space>
      </a-form-item>
      <a-form-item field="appType" label="应用类型" required>
        <a-radio-group v-model="updateDate.appType">
          <a-radio :value="Number(1)">
            {{ APP_TYPE_MAP[1] }}
          </a-radio>
          <a-radio :value="Number(0)" @click="updateDate.scoringStrategy = 0">
            {{ APP_TYPE_MAP[0] }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item field="scoringStrategy" label="评分策略" required>
        <a-radio-group v-model="updateDate.scoringStrategy">
          <a-radio :value="Number(0)"
            >{{ APP_SCORING_STRATEGY_MAP[0] }}
          </a-radio>
          <a-radio :value="Number(1)" v-show="updateDate.appType == 1"
            >{{ APP_SCORING_STRATEGY_MAP[1] }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item>
        <a-popconfirm content="确定要删除吗" @ok="updateSubmit">
          <a-button type="primary"> 修改</a-button>
        </a-popconfirm>
      </a-form-item>
    </a-form>
  </a-modal>
  <a-table
    :columns="columns"
    :data="dataList"
    :pagination="{
      showTotal: true,
      pageSize: searchParams.pageSize,
      current: searchParams.current,
      total,
    }"
    :stripe="true"
    :bordered="{ cell: true }"
    :loading="loading"
    @page-change="onPageChange"
  >
    <template #appIcon="{ record }">
      <a-image width="64" :src="record.appIcon" />
    </template>
    <template #appType="{ record }">
      {{ APP_TYPE_MAP[record.appType] }}
    </template>
    <template #scoringStrategy="{ record }">
      {{ APP_SCORING_STRATEGY_MAP[record.scoringStrategy] }}
    </template>
    <template #reviewStatus="{ record }">
      {{ REVIEW_STATUS_MAP[record.reviewStatus] }}
    </template>
    <template #reviewTime="{ record }">
      {{ dayjs(record.reviewTime).format("YYYY-MM-DD") }}
    </template>
    <template #createTime="{ record }">
      {{ dayjs(record.createTime).format("YYYY-MM-DD") }}
    </template>
    <template #updateTime="{ record }">
      {{ dayjs(record.updateTime).format("YYYY-MM-DD") }}
    </template>
    <template #optional="{ record }">
      <a-space>
        <a-button
          type="outline"
          @click="doReview(record.id, REVIEW_STATUS_ENUM.PASS, '符合上架要求')"
          status="success"
          v-if="record.reviewStatus !== REVIEW_STATUS_ENUM.PASS"
        >
          通过
        </a-button>
        <a-button
          type="outline"
          status="warning"
          @click="
            doReview(record.id, REVIEW_STATUS_ENUM.REJECT, '不符合上架要求')
          "
          v-if="record.reviewStatus !== REVIEW_STATUS_ENUM.REJECT"
        >
          拒绝
        </a-button>
        <a-button type="primary" @click="doUpdate(record)">修改</a-button>
        <a-popconfirm content="确定要删除吗" @ok="doDelete(record.id)">
          <a-button status="danger">删除</a-button>
        </a-popconfirm>
      </a-space>
    </template>
    <template #tags="{ record }">
      <a-space wrap>
        <a-tag v-for="(tag, index) of record.tags" :key="index" color="green"
          >{{ tag }}
        </a-tag>
      </a-space>
    </template>
  </a-table>
</template>
<script setup lang="ts">
import { ref, watchEffect } from "vue";
import API from "@/api";
import {
  deleteAppUsingPost,
  doAppReviewUsingPost,
  listAppByPageUsingPost,
  updateAppUsingPost,
} from "@/api/appController";
import message from "@arco-design/web-vue/es/message";
import dayjs from "dayjs";
import {
  APP_SCORING_STRATEGY_ENUM,
  APP_SCORING_STRATEGY_MAP,
  APP_TYPE_ENUM,
  APP_TYPE_MAP,
  REVIEW_STATUS_ENUM,
  REVIEW_STATUS_MAP,
} from "@/constant/appEnum";
import PictureUpload from "@/components/picture/PictureUpload.vue";
import router from "@/router";
//初始值
const initParams = {
  current: 1,
  pageSize: 10,
  sortField: "createTime",
  sortOrder: "descend",
};
//搜索参数
const formParams = ref<API.AppQueryRequest>({
  ...initParams,
});
//传递参数
const searchParams = ref<API.AppQueryRequest>({});
//数据
const dataList = ref<API.App[]>([]);
//总值
const total = ref<number>(0);
const loading = ref(false);
/**
 * 加载数据
 */
const loadData = async () => {
  loading.value = true;
  const response = await listAppByPageUsingPost(searchParams.value);
  if (response.data.code === 0) {
    dataList.value = response.data.data?.records || [];
    total.value = response.data.data?.total || 0;
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
  loading.value = false;
};
/**
 * 搜索
 */
const search = () => {
  searchParams.value = { ...initParams, ...formParams.value };
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * 当分页变化时，改变搜索条件，触发数据加载
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

/**
 * 审核
 */
const doReview = async (id: number, reviewStatus: number, msg: string) => {
  const res = await doAppReviewUsingPost({
    id,
    reviewStatus,
    reviewMessage: msg,
  });
  if (res.data.code === 0) {
    await loadData();
  } else {
    message.error("审核失败，" + res.data.message);
  }
};
/**
 * 更新
 */
const updateVisible = ref(false);
const updateDate = ref<API.App>({});
const doUpdate = (record: API.App) => {
  updateVisible.value = true;
  updateDate.value = { ...record };
};
const updateSubmit = async () => {
  const res = await updateAppUsingPost({
    ...updateDate.value,
  } as API.AppUpdateRequest);
  if (res.data.code === 0) {
    message.success("修改成功");
    await loadData();
    updateVisible.value = false;
  } else {
    message.error("修改失败，" + res.data.message);
  }
  updateDate.value = {};
};

/**
 * 删除
 */
const doDelete = async (id: number) => {
  const res = await deleteAppUsingPost({ id });
  if (res.data.code === 0) {
    loadData();
  } else {
    message.error("删除失败，" + res.data.message);
  }
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "用户ID",
    dataIndex: "userId",
  },
  {
    title: "应用名称",
    dataIndex: "appName",
  },
  {
    title: "应用描述",
    dataIndex: "appDesc",
  },
  {
    title: "应用图标",
    dataIndex: "appIcon",
    slotName: "appIcon",
  },
  {
    title: "应用类型",
    dataIndex: "appType",
    slotName: "appType",
  },
  {
    title: "评分策略",
    dataIndex: "scoringStrategy",
    slotName: "scoringStrategy",
  },
  {
    title: "审核状态",
    dataIndex: "reviewStatus",
    slotName: "reviewStatus",
  },
  {
    title: "审核信息",
    dataIndex: "reviewMessage",
  },
  {
    title: "审核人ID",
    dataIndex: "reviewerId",
  },
  {
    title: "审核时间",
    dataIndex: "reviewTime",
    slotName: "reviewTime",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    slotName: "createTime",
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    slotName: "updateTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
  {
    title: "标签",
    slotName: "tags",
  },
];
</script>
