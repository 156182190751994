<template>
  <a-space direction="vertical" :style="{ width: '100%' }">
    <a-upload
      list-type="picture-card"
      :fileList="file ? [file] : []"
      :show-file-list="false"
      :custom-request="customRequest"
    />
  </a-space>
</template>

<script setup lang="ts">
import { defineProps, ref, withDefaults } from "vue";
import { uploadFileUsingPost } from "@/api/fileController";
import { Message } from "@arco-design/web-vue";

/**
 * 定义组件属性类型
 */
interface Props {
  biz: string;
  onChange?: (url: string) => void;
  value?: string;
}

/**
 * 给组件指定初始值
 */
const props = withDefaults(defineProps<Props>(), {
  value: () => "",
});

const file = ref();
if (props.value) {
  file.value = {
    url: props.value,
    percent: 100,
    status: "done",
  };
}

// 自定义请求
const customRequest = async (option: any) => {
  const { onError, onSuccess, fileItem } = option;

  const res = (await uploadFileUsingPost(
    { biz: props.biz },
    {},
    fileItem.file
  )) as any;
  if (res.data.code === 0 && res.data.data) {
    const url = res.data.data;
    props.onChange?.(url);
    onSuccess();
  } else {
    Message.error("上传失败，" + res.data.message || "");
    onError(new Error(res.data.message));
  }
};
</script>
