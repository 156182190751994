<template>
  <MdEditor
    v-model="text"
    @change="handleChange"
    @on-upload-img="onUploadImg"
  />
</template>

<script setup lang="ts">
import "md-editor-v3/lib/style.css";
import { MdEditor } from "md-editor-v3";
import { defineProps, ref, withDefaults } from "vue";
import { uploadFileUsingPost } from "@/request/api/fileController";
import { Message } from "@arco-design/web-vue";

/**
 * 定义组件属性类型
 */
interface Props {
  mdText: string;
  onChange?: (text: string) => void;
}

/**
 * 给组件指定初始值
 */
const props = withDefaults(defineProps<Props>(), {
  mdText: () => "",
});
const text = ref(props.mdText);

const handleChange = (value: string) => {
  text.value = value;
};
// 图片上传事件
const onUploadImg = async (
  files: (string | Blob)[],
  callback: (arg0: any[]) => void
) => {
  const res = await Promise.all(
    files.map((file: string | Blob) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (rev, rej) => {
        // 传给后端一个FormData数据，添加键值对，这里我添加了这个文章的ID和file
        const res = (await uploadFileUsingPost(
          { biz: "md" },
          {},
          file as File
        )) as any;
        if (res.data.code == 0 && res.data.data) {
          rev(res.data.data);
        } else {
          rej(res.data.message);
        }
      });
    })
  );
  callback(res);
};
</script>

<style scoped></style>
