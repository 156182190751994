<template>
  <div ref="myChartCart" id="chart" style="width: 700px; height: 200px"></div>
</template>
<script setup lang="ts">
import message from "@arco-design/web-vue/es/message";
import { getSignInUsingGet } from "@/request/api/userController";
import { dayjs } from "@arco-design/web-vue/es/_utils/date";
import { ref, watchEffect } from "vue";
import * as echarts from "echarts";
import useState from "@arco-design/web-vue/es/_hooks/use-state";

// 签到日期列表（[1, 200]，表示第 1 和第 200 天有签到记录）
const dataList = ref<number[]>([]);

// 计算图表需要的数据
const year = new Date().getFullYear();
const optionsData = ref<any>([]);
/**
 * 获取签到信息
 */
const getSignIn = async () => {
  const response = await getSignInUsingGet({ year: year });
  if (response.data.code === 0) {
    dataList.value = response.data.data || [];
    optionsData.value = dataList.value.map((dayOfYear: number, index: any) => {
      // 计算日期字符串
      const dateStr = dayjs(`${year}-01-01`)
        .add(dayOfYear - 1, "day")
        .format("YYYY-MM-DD");
      return [dateStr, 1];
    });
    echartsInit();
  } else {
    message.error(response.data.message as string);
  }
};
/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  getSignIn();
});

const echartsInit = () => {
  var myChart = echarts.init(document.getElementById("chart"));
  //图表配置;
  var options = {
    visualMap: {
      show: false,
      min: 0,
      max: 1,
      inRange: {
        // 颜色从灰色到浅绿色
        color: ["#efefef", "lightgreen"],
      },
    },
    calendar: {
      range: year,
      left: 20,
      // 单元格自动宽度，高度为 16 像素
      cellSize: ["auto", 16],
      yearLabel: {
        position: "top",
        formatter: `${year} 年刷题记录`,
      },
    },
    series: {
      type: "heatmap",
      coordinateSystem: "calendar",
      data: optionsData.value,
    },
  };
  myChart.setOption(options);
};
</script>
<style scoped></style>
