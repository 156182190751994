<template>
  <div id="addPage">
    <a-space>
      <h2>设置题目</h2>
      <div>
        <a-button @click="visible = true">
          <h2>查看案例</h2>
        </a-button>
        <a-modal v-model:visible="visible" width="700px" :footer="false">
          <template #title>
            <h2>案例</h2>
          </template>
          <div v-if="app.appType === APP_TYPE_ENUM.TEST">
            <img src="../../../assets/questionCase1.png" style="width: 600px" />
            <img src="../../../assets/questionCase2.png" style="width: 600px" />
          </div>
          <div v-else>
            <img src="../../../assets/questionCase3.png" style="width: 600px" />
            <img src="../../../assets/questionCase4.png" style="width: 600px" />
          </div>
        </a-modal>
      </div>
    </a-space>
    <br />
    <a-form
      :model="form"
      :style="{ width: '400px' }"
      label-align="left"
      auto-label-width
      @submit="submitQuestion"
    >
      <a-form-item field="appName" label="应用名称">
        <a-typography-text>{{ app.appName }}</a-typography-text>
      </a-form-item>
      <a-form-item field="appDesc" label="应用描述">
        <a-typography-text>{{ app.appDesc }}</a-typography-text>
      </a-form-item>
      <a-form-item label="题目列表">
        <a-space size="medium">
          <a-button @click="addQuestion(questionContent.length)"
            >添加题目
          </a-button>
          <add-ai-question-drawer
            :app="app"
            :app-id="appId"
            :onSuccess="onAiGenerateSuccess"
            :onSSESuccess="onAiGenerateSSESuccess"
            :onSSEStart="onSSEStart"
            :onSSEClose="onSSEClose"
          ></add-ai-question-drawer>
        </a-space>
      </a-form-item>

      <a-form-item
        v-for="(question, index) of questionContent"
        :key="index"
        auto-label-width
      >
        <!--所有选项表单-->
        <a-space direction="vertical">
          <a-space fill size="large">
            <h2>题目{{ index + 1 }}</h2>
            <a-button @click="addQuestion(index + 1)">添加题目</a-button>
            <a-button @click="delQuestion(index)" status="danger"
              >删除题目
            </a-button>
          </a-space>

          <a-form-item label="题目标题" required>
            <a-input
              v-model="question.title"
              placeholder="请输入应用标题..."
              style="width: 500px"
            />
          </a-form-item>

          <a-space class="options" direction="vertical">
            <a-space size="large">
              <h3 style="margin-left: -30px">题目{{ index + 1 }}选项列表</h3>
              <a-button @click="addOption(question, question.options.length)"
                >添加选项
              </a-button>
            </a-space>
            <!--选项表单-->
            <a-space fill size="large">
              <a-form
                :model="questionContent[index].options"
                :style="{ width: '500px' }"
              >
                <a-form-item
                  v-for="(option, y) of questionContent[index].options"
                  :key="y"
                >
                  <a-form
                    :model="questionContent[index].options[y]"
                    :style="{ width: '400px' }"
                  >
                    <h3 style="margin-left: -80px; margin-bottom: -40px">
                      选项 {{ optionKey[y] }}
                    </h3>

                    <a-form-item
                      label="选项名"
                      style="margin-top: 15px"
                      required
                    >
                      <a-input
                        v-model="option.key"
                        :default-value="optionKey[y]"
                        placeholder="请输入选项名（类似A）"
                      />
                    </a-form-item>
                    <a-form-item label="选项描述" required>
                      <a-input
                        v-model="option.value"
                        placeholder="选项描述..."
                      />
                    </a-form-item>
                    <a-form-item
                      label="选项结果"
                      v-if="app.appType === APP_TYPE_ENUM.TEST"
                      required
                    >
                      <a-input
                        v-model="option.result"
                        placeholder="请输入选项结果..."
                      />
                    </a-form-item>
                    <a-form-item
                      label="选项得分"
                      v-if="app.appType === APP_TYPE_ENUM.SCORE"
                      required
                    >
                      <a-input-number
                        v-model="option.score"
                        placeholder="请输入选项得分..."
                      />
                    </a-form-item>
                    <a-form-item>
                      <a-space size="large">
                        <a-button @click="addOption(question, y + 1)"
                          >添加选项
                        </a-button>
                        <a-button
                          @click="delOption(question, y as number)"
                          status="danger"
                          >删除选项
                        </a-button>
                      </a-space>
                    </a-form-item>
                    <a-divider />
                  </a-form>
                </a-form-item>
              </a-form>
            </a-space>
            <!--选项表单-->
          </a-space>
        </a-space>
        <!--所有选项表单-->
      </a-form-item>
      <!--所有题目-->
      <a-button
        type="primary"
        html-type="submit"
        style="width: 100px; margin: 0 auto 10px"
        >提交题目
      </a-button>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, watchEffect, withDefaults } from "vue";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import {
  addQuestionUsingPost,
  editQuestionUsingPost,
  listQuestionVoByPageUsingPost,
} from "@/api/questionController";
import { APP_TYPE_ENUM } from "@/constant/appEnum";
import { getAppVoByIdUsingGet } from "@/api/appController";
import AddAiQuestionDrawer from "@/components/sider/AddAiQuestionDrawer.vue";

const router = useRouter();
const form = ref({
  appId: "",
  questionContent: "",
} as API.QuestionAddRequest | API.QuestionEditRequest);

const questionContent = ref<API.QuestionContentDTO[]>([]);
//应用数据
const app = ref<API.AppVO>({});
//选项
const optionKey = ["A", "B", "C", "D", "E", "F"];

interface Props {
  appId: number;
}

const props = withDefaults(defineProps<Props>(), {
  appId: () => {
    return 0;
  },
});
const oldQuestion = ref<API.QuestionVO>({});
const visible = ref(false);

/**
 * 加载数据
 */
const loadData = async () => {
  if (!props.appId) {
    return;
  }
  //获取应用信息
  const appRes = await getAppVoByIdUsingGet({
    id: props.appId,
  });
  if (appRes.data.code === 0 && appRes.data.data) {
    app.value = appRes.data.data;
  } else {
    message.error("获取应用信息失败，" + appRes.data.message);
  }
  //获取题目信息
  const response = await listQuestionVoByPageUsingPost({
    appId: props.appId,
    current: 1,
    pageSize: 1,
    sortField: "createTime",
    sortOrder: "descend",
  });
  if (response.data.code === 0 && response.data.data?.records) {
    oldQuestion.value = response.data.data?.records[0];
    if (oldQuestion.value.questionContentDTO) {
      questionContent.value = oldQuestion.value.questionContentDTO ?? [];
    }
  }
  return;
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * AI生成题目成功后执行的方法
 */
const onAiGenerateSuccess = (result: API.QuestionContentDTO[]) => {
  questionContent.value = [...questionContent.value, ...result];
  message.success(`AI 生成题目成功，已新增 ${result.length} 道题目`);
};

/**
 * AI生成题目成功后执行的方法(SSE流式返回)
 */
const onAiGenerateSSESuccess = (result: any) => {
  questionContent.value = [...questionContent.value, result];
};
/**
 * 开始的方法(SSE流式返回)
 */
const onSSEStart = (event: any) => {
  message.success(`开始生成`);
};
/**
 * 结束的方法(SSE流式返回)
 */
const onSSEClose = (event: any) => {
  message.success(`生成完毕`);
};

/**
 * 提交题目
 */
const submitQuestion = async () => {
  if (questionContent.value == []) {
    message.warning("请先添加题目");
    return;
  }
  let response: any;
  //有就是修改
  if (oldQuestion.value?.id) {
    form.value.questionContentDTO = questionContent.value;
    response = await editQuestionUsingPost({
      id: oldQuestion.value?.id,
      questionContentDTO: questionContent.value,
    });
    //没有就是添加
  } else {
    response = await addQuestionUsingPost({
      appId: props.appId,
      questionContentDTO: questionContent.value,
    });
  }
  if (response.data.code === 0) {
    message.success("操作成功!跳转到应用详情页面");
    router.push(
      `/app/detail/${props.appId == 0 ? response.data.data : props.appId}`
    );
  } else {
    message.error("操作失败，" + response.data.message);
  }
};
/**
 * 添加题目
 */
const addQuestion = (index: number) => {
  if (questionContent.value.length >= 50) {
    message.error("题目数量不能超过50个");
    return;
  }
  questionContent.value.splice(index, 0, {
    title: "",
    options: [],
  });
};
/**
 * 删除题目
 * @param index
 */
const delQuestion = (index: number) => {
  questionContent.value.splice(index, 1);
};
/**
 * 添加选项
 * @param question
 * @param y
 */
const addOption = (question: API.QuestionContentDTO, y: number) => {
  if (!question.options) {
    question.options = [];
  }
  if (question.options?.length >= 6) {
    message.error("选项数量不能超过6个");
    return;
  }
  question.options.splice(y, 0, {
    key: optionKey[y],
  });
};
/**
 * 删除选项
 * @param question
 * @param y
 */
const delOption = (question: API.QuestionContentDTO, y: number) => {
  if (!question.options) {
    question.options = [];
  }
  question.options.splice(y, 1);
};
</script>

<style scoped>
#addPage {
  margin: 30px 200px;
  padding: 20px 50px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 1px 1px #e8e8e8;
}

.options {
  margin: 20px 50px;
}
</style>
