<template>
  <a-form
    layout="inline"
    style="margin: 0 0 10px auto"
    label-align="left"
    auto-label-width
    @submit="search"
  >
    <a-form-item field="id" label="用户ID：">
      <a-input v-model="formParams.id" placeholder="请输入ID..." allow-clear />
    </a-form-item>
    <a-form-item field="userAccount" label="账号：">
      <a-input
        v-model="formParams.userAccount"
        placeholder="请输入账号..."
        allow-clear
      />
    </a-form-item>
    <a-form-item field="userName" label="用户名：">
      <a-input
        v-model="formParams.userName"
        placeholder="请输入用户名..."
        allow-clear
      />
    </a-form-item>
    <a-form-item field="userRole" label="用户角色：">
      <a-input
        v-model="formParams.userRole"
        placeholder="请输入用户角色..."
        allow-clear
      />
    </a-form-item>
    <a-form-item field="userRole" label="用户简介：">
      <a-input
        v-model="formParams.userProfile"
        placeholder="请输入用户简介..."
      />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" html-type="submit" style="width: 120px"
        >搜索
      </a-button>
    </a-form-item>
  </a-form>
  <a-space size="large">
    <a-button
      @click="add"
      type="primary"
      style="width: 100px; margin-bottom: 20px"
      >添加用户
    </a-button>
    <a-form-item label="排序：">
      <a-radio-group v-model="formParams.sortOrder" type="button">
        <a-radio value="ascend">正序</a-radio>
        <a-radio value="descend">倒序</a-radio>
      </a-radio-group>
    </a-form-item>
    <a-form-item label="排序字段：">
      <a-radio-group v-model="formParams.sortField" type="button">
        <a-radio value="createTime">创建时间</a-radio>
        <a-radio value="updateTime">更新时间</a-radio>
      </a-radio-group>
    </a-form-item>
  </a-space>
  <a-modal
    v-model:visible="addVisible"
    title="修改用户信息"
    :footer="false"
    draggable
  >
    <a-form :model="addUser" label-align="left" auto-label-width>
      <a-form-item label="用户名称">
        <a-input v-model="addUser.userName" placeholder="请输入用户名称..." />
      </a-form-item>
      <a-form-item label="手机号">
        <a-input v-model="addUser.userPhone" placeholder="请输入手机号..." />
      </a-form-item>
      <a-form-item label="账号">
        <a-input v-model="addUser.userAccount" placeholder="请输入账号..." />
      </a-form-item>
      <a-form-item label="用户头像">
        <a-space size="large">
          <a-image
            v-if="addUser.userAvatar"
            :src="addUser.userAvatar"
            width="80px"
          ></a-image>
          <picture-upload
            biz="user_avatar"
            v-model="addUser.userAvatar"
            :onChange="(url) => (addUser.userAvatar = url)"
          />
        </a-space>
      </a-form-item>

      <a-form-item label="用户简介">
        <a-input
          v-model="addUser.userProfile"
          placeholder="请输入用户简介..."
        />
      </a-form-item>
      <a-form-item label="用户角色">
        <a-select v-model="addUser.userRole" placeholder="请输入用户角色...">
          <a-option value="admin">管理员</a-option>
          <a-option value="user">用户</a-option>
          <a-option value="vip">会员</a-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-space>
          <a-button @click="addUser = {}">重置</a-button>
          <a-popconfirm
            content="你确定要添加吗?"
            @ok="insertUser"
            position="bottom"
          >
            <a-button type="primary">添加信息</a-button>
          </a-popconfirm>
        </a-space>
      </a-form-item>
    </a-form>
  </a-modal>
  <a-table
    :columns="columns"
    :data="dataList"
    :pagination="{
      showTotal: true,
      pageSize: searchParams.pageSize,
      current: searchParams.current,
      total,
    }"
    :bordered="{ cell: true }"
    :stripe="true"
    :loading="loading"
    @page-change="onPageChange"
  >
    <template #userAvatar="{ record }">
      <a-image width="64" :src="record.userAvatar" />
    </template>
    <template #createTime="{ record }">
      {{ dayjs(record.createTime).format("YYYY-MM-DD") }}
    </template>
    <template #updateTime="{ record }">
      {{ dayjs(record.updateTime).format("YYYY-MM-DD") }}
    </template>
    <template #optional="{ record }">
      <a-space>
        <a-button type="primary" @click="update(record)">修改</a-button>
        <a-popconfirm content="确定要删除吗" @ok="doDelete(record.id)">
          <a-button status="danger">删除</a-button>
        </a-popconfirm>
      </a-space>
    </template>
    <template #tags="{ record }">
      <a-space wrap>
        <a-tag v-for="(tag, index) of record.tags" :key="index" color="green"
          >{{ tag }}
        </a-tag>
      </a-space>
    </template>
  </a-table>
  <a-modal
    v-model:visible="updateVisible"
    title="修改用户信息"
    :footer="false"
    draggable
  >
    <a-form :model="updateUser" label-align="left" auto-label-width>
      <a-form-item label="用户名称">
        <a-input
          v-model="updateUser.userName"
          placeholder="请输入用户名称..."
        />
      </a-form-item>
      <a-form-item label="用户头像">
        <a-space size="large">
          <a-image
            v-if="updateUser.userAvatar"
            :src="updateUser.userAvatar"
            width="80px"
          ></a-image>
          <picture-upload
            biz="user_avatar"
            v-model="updateUser.userAvatar"
            :onChange="(url) => (updateUser.userAvatar = url)"
          />
        </a-space>
      </a-form-item>
      <a-form-item label="手机号">
        <a-input v-model="updateUser.userPhone" placeholder="请输入手机号..." />
      </a-form-item>
      <a-form-item label="用户密码">
        <a-input
          v-model="updateUser.userPassword"
          placeholder="请输入密码..."
        />
      </a-form-item>
      <a-form-item label="用户简介">
        <a-input
          v-model="updateUser.userProfile"
          placeholder="请输入用户简介..."
        />
      </a-form-item>
      <a-form-item label="用户角色">
        <a-select v-model="updateUser.userRole" placeholder="请输入用户简介...">
          <a-option value="admin">管理员</a-option>
          <a-option value="user">用户</a-option>
          <a-option value="vip">会员</a-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-space>
          <a-button @click="reset">重置</a-button>
          <a-popconfirm
            content="你确定要修改吗?"
            @ok="submitUser"
            position="bottom"
          >
            <a-button type="primary">修改信息</a-button>
          </a-popconfirm>
        </a-space>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script setup lang="ts">
import { ref, watchEffect } from "vue";
import API from "@/api";
import {
  addUserUsingPost,
  deleteUserUsingPost,
  listUserByPageUsingPost,
  updateUserUsingPost,
} from "@/api/userController";
import message from "@arco-design/web-vue/es/message";
import dayjs from "dayjs";
import PictureUpload from "@/components/picture/PictureUpload.vue";
//初始值
const initParams = {
  current: 1,
  pageSize: 10,
  sortOrder: "ascend",
  sortField: "createTime",
};
//搜索参数
const formParams = ref<API.UserQueryRequest>({
  ...initParams,
});
//传递参数
const searchParams = ref<API.UserQueryRequest>({});
//数据
const dataList = ref<API.User[]>([]);
//总值
const total = ref<number>(0);
const loading = ref(false);
/**
 * 加载数据
 */
const loadData = async () => {
  loading.value = true;
  const response = await listUserByPageUsingPost(searchParams.value);
  if (response.data.code === 0) {
    dataList.value = response.data.data?.records || [];
    total.value = response.data.data?.total || 0;
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
  loading.value = false;
};
/**
 * 搜索
 */
const search = () => {
  searchParams.value = { ...initParams, ...formParams.value };
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * 当分页变化时，改变搜索条件，触发数据加载
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};
/**
 * 添加
 */
const addUser = ref<API.UserUpdateMyRequest>({});
const addVisible = ref(false);
const add = () => {
  addVisible.value = true;
};

/**
 * 添加用户信息
 */
const insertUser = async () => {
  if (addUser.value.userName == "") {
    message.error("请输入用户名");
    return;
  }
  if (addUser.value.userAvatar == "") {
    message.error("请输入头像");
    return;
  }
  const response = await addUserUsingPost(addUser.value);
  if (response.data.code === 0) {
    message.success("修改成功");
    addVisible.value = false;
    loadData();
    addUser.value = {};
  } else {
    message.error("修改失败，" + response.data.message);
  }
};

/**
 * 修改
 */
const oldUser = ref<API.UserUpdateRequest>({});
const updateUser = ref<API.UserUpdateRequest>({});
const updateVisible = ref(false);
const update = (user: API.User) => {
  oldUser.value = user;
  updateUser.value = { ...user };
  updateUser.value.userPassword = "";
  updateVisible.value = true;
};
const reset = () => {
  updateUser.value = {};
  updateUser.value = {
    ...oldUser.value,
  };
};
/**
 * 提交用户信息
 */
const submitUser = async () => {
  if (updateUser.value.userName == "") {
    message.error("请输入用户名");
    return;
  }
  if (updateUser.value.userAvatar == "") {
    message.error("请输入头像");
    return;
  }
  const response = await updateUserUsingPost(updateUser.value);
  if (response.data.code === 0) {
    loadData();
    message.success("修改成功");
    updateVisible.value = false;
  } else {
    message.error("修改失败，" + response.data.message);
  }
};

/**
 * 删除
 */
const doDelete = async (id: number) => {
  const res = await deleteUserUsingPost({ id });
  if (res.data.code === 0) {
    loadData();
  } else {
    message.error("删除失败，" + res.data.message);
  }
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "用户账户",
    dataIndex: "userAccount",
  },
  {
    title: "用户名",
    dataIndex: "userName",
  },
  {
    title: "手机号",
    dataIndex: "userPhone",
  },
  {
    title: "用户头像",
    dataIndex: "userAvatar",
    slotName: "userAvatar",
  },
  {
    title: "用户简介",
    dataIndex: "userProfile",
  },
  {
    title: "用户角色",
    dataIndex: "userRole",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    slotName: "createTime",
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    slotName: "updateTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
  {
    title: "标签",
    slotName: "tags",
  },
];
</script>
