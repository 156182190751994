<template>
  <a-button type="outline" @click="handleClick">AI 生成题目</a-button>
  <a-modal
    :width="500"
    :visible="visible"
    @ok="handleOk"
    @cancel="handleCancel"
    :footer="null"
  >
    <template #title> AI 生成题目</template>
    <div>
      <a-form :model="form" auto-label-width @submit="aiGenerateQuestion">
        <a-form-item label="应用名称">
          <a-typography-text>{{ app.appName }}</a-typography-text>
        </a-form-item>
        <a-form-item label="应用描述">
          <a-typography-text>{{ app.appDesc }}</a-typography-text>
        </a-form-item>
        <a-form-item label="提示词">
          <a-textarea
            v-model="form.userDescription"
            placeholder="可以提示一下AI要生成什么内容"
          ></a-textarea>
        </a-form-item>
        <a-form-item label="题目数量">
          <a-input-number
            v-model="form.questionNumber"
            style="width: 80px"
            max="20"
            min="0"
          ></a-input-number>
        </a-form-item>
        <a-form-item label="选项数量">
          <a-input-number
            v-model="form.optionNumber"
            style="width: 80px"
            max="6"
            min="0"
          ></a-input-number>
        </a-form-item>
        <a-form-item label="提示">
          <a-typography-text style="font-size: 12px">
            应用名称和应用描述越准确 AI 生成的题目越好哦
          </a-typography-text>
        </a-form-item>
        <a-form-item>
          <a-space size="medium">
            <a-button :loading="isLoad" @click="aiGenerateQuestion">
              {{ isLoad != true ? "生成题目" : "生成中" }}
            </a-button>
            <a-button
              type="primary"
              :loading="isSSELoad"
              @click="aiGenerateQuestionSSE"
            >
              {{ isSSELoad != true ? "实时生成" : "生成中" }}
            </a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script setup lang="ts">
import { defineProps, ref, withDefaults } from "vue";
import { aiGenerateQuestionContentUsingPost } from "@/request/api/questionController";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import { useLoginUserStore } from "@/store/userStore";
import { url } from "@/request";

interface Props {
  appId: number;
  app: API.AppVO;
  onSuccess?: (result: API.QuestionContentDTO[]) => void;
  onSSESuccess?: (result: API.QuestionContentDTO[]) => void;
  onSSEStart?: (event: any) => void;
  onSSEClose?: (event: any) => void;
}

const props = withDefaults(defineProps<Props>(), {
  appId: () => {
    return 0;
  },
  app: () => {
    return {};
  },
});

const form = ref({
  userDescription: "",
  questionNumber: 10,
  optionNumber: 2,
} as API.AiGenerateQuestionRequest);

const userId = useLoginUserStore().loginUser.id;

const visible = ref(false);

const isLoad = ref(false);

let isSSELoad = false;
/**
 * AI 生成题目
 */
const aiGenerateQuestion = async () => {
  if (!props.appId) {
    return;
  }
  isLoad.value = true;
  message.success(`正在生成,请稍等`);
  const res = await aiGenerateQuestionContentUsingPost({
    appId: props.appId as number,
    questionNumber: form.value.questionNumber,
    optionNumber: form.value.optionNumber,
  });
  if (res.data.code === 0 && res.data.data.length > 0) {
    if (props.onSuccess) {
      props.onSuccess(res.data.data);
    } else {
      message.success("生成成功");
    }
    handleCancel();
  } else {
    message.error("操作失败，" + res.data.message);
  }
  isLoad.value = false;
};
/**
 * AI 题目实时生成
 */
const aiGenerateQuestionSSE = async () => {
  if (!props.appId) {
    return;
  }
  isSSELoad = true;
  message.success("准备生成题目");
  handleCancel();
  //进行sse请求
  const eventSource = new EventSource(
    `${url}/api/question/aiGenerate/sse` +
      `?appId=${props.appId}&userId=${userId}&questionNumber=${form.value.questionNumber}&optionNumber=${form.value.optionNumber}`
  );
  //连接建立执行的函数
  eventSource.onopen = (event) => {
    props.onSSEStart?.(event);
  };
  //每有数据返回执行的函数
  eventSource.onmessage = (event) => {
    props.onSSESuccess?.(JSON.parse(event.data));
  };
  //连接关闭执行的函数
  eventSource.onerror = (event) => {
    if (event.eventPhase === EventSource.CLOSED) {
      props.onSSEClose?.(event);
    }
    eventSource.close();
  };
  isSSELoad = false;
};
const handleClick = () => {
  visible.value = true;
};
const handleOk = () => {
  visible.value = false;
};
const handleCancel = () => {
  visible.value = false;
};
</script>
