<template>
  <a-typography-title :heading="5" style="text-align: center">
    签到表
    <a-tooltip content="画圈表示已签到">
      <icon-exclamation-circle-fill size="14" />
    </a-tooltip>
  </a-typography-title>

  <a-date-picker hide-trigger style="border: none; border-radius: 20px">
    <template #cell="{ date }">
      <div class="arco-picker-date">
        <div class="arco-picker-date-value" :style="getCellStyle(date)">
          {{ date.getDate() }}
        </div>
      </div>
    </template>
  </a-date-picker>
</template>
<script setup lang="ts">
// 签到日期列表（[1, 200]，表示第 1 和第 200 天有签到记录）
import message from "@arco-design/web-vue/es/message";
import { getSignInUsingGet } from "@/request/api/userController";
import useState from "@arco-design/web-vue/es/_hooks/use-state";
import { ref, watchEffect } from "vue";
import { dayjs } from "@arco-design/web-vue/es/_utils/date";

const [dataList, setDataList] = useState<number[]>([]);

// 计算图表需要的数据
const year = new Date().getFullYear();
/**
 * 获取签到信息
 */
const getSignIn = async () => {
  const response = await getSignInUsingGet({ year: year });
  if (response.data.code === 0) {
    setDataList(response.data.data || []);
    optionsData.value = dataList.value.map((dayOfYear: number) => {
      // 计算日期字符串
      const dateStr = dayjs(`${year}-01-01`)
        .add(dayOfYear - 1, "day")
        .format("YYYY-MM-DD");
      return dateStr;
    });
  } else {
    message.error(response.data.message as string);
  }
};
// 签到日期时间
const optionsData = ref<string[]>([]);

const highlightStyle = {
  border: "1px solid rgb(var(--arcoblue-6))",
};
const now = new Date();

const getCellStyle = (date: any) => {
  const dateStr = dayjs(date).format("YYYY-MM-DD") as string;
  return optionsData.value.includes(dateStr) ? highlightStyle : {};
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  getSignIn();
});
</script>
