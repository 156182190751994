<template>
  <!--  题库信息 -->
  <div class="bankDetailPage">
    <div class="bankCart">
      <a-spin :loading="loading">
        <a-space>
          <a-image :src="data.picture" width="100" style="padding: 50px" />
          <div style="width: 90%; min-width: 800px">
            <a-form-item>
              <a-typography-title style="font-weight: bolder">
                {{ data.title }}
              </a-typography-title>
            </a-form-item>
            <a-form-item style="margin-top: -30px">
              <a-typography-text type="secondary">
                {{ data.description }}
              </a-typography-text>
            </a-form-item>
            <a-form-item>
              <a-space>
                <a-button type="primary" shape="round" @click="answer">
                  开始刷题
                </a-button>
                <a-button shape="round">
                  <share-trigger></share-trigger>
                </a-button>
              </a-space>
            </a-form-item>
          </div>
        </a-space>
      </a-spin>
    </div>

    <!--  题目列表 -->
    <interview-question-table :bank-id="props.id" />
  </div>
</template>
<script setup lang="ts">
import API from "@/api";
import { defineProps, ref, watchEffect, withDefaults } from "vue";
import message from "@arco-design/web-vue/es/message";
import ShareTrigger from "@/components/trigger/ShareTrigger.vue";
import router from "@/router";
import { getInterviewQuestionBankVoByIdUsingGet } from "@/request/api/interviewQuestionBankController";
import InterviewQuestionTable from "@/components/list/InterviewQuestionTable.vue";

interface Props {
  id: number;
}

const props = withDefaults(defineProps<Props>(), {
  id: () => {
    return 0;
  },
});

const data = ref<API.InterviewQuestionBankVO>({});
const loading = ref(false);
/**
 * 加载数据
 */
const loadData = async () => {
  loading.value = true;
  if (!props.id || props.id === 0) {
    return;
  }
  const response = await getInterviewQuestionBankVoByIdUsingGet({
    id: props.id,
  });
  if (response.data.code === 0 && response.data.data) {
    data.value = response.data.data;
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
  loading.value = false;
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});
/**
 * 答题
 */
const answer = () => {
  router.push({
    path: `/interviewQuestion/detail/${props.id}/${-1}`,
  });
};
</script>
<style scoped>
.bankDetailPage {
  width: 1200px;
  margin: 20px auto;
}

.bankCart {
  width: 1200px;
  margin: 20px auto;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 1px 1px #e8e8e8;
}
</style>
