<template>
  <div id="DoAnswerPage">
    <div class="doAnswerCart">
      <a-form :model="form" label-align="left" auto-label-width>
        <a-form-item>
          <a-typography-title>
            {{ app.appName }}
          </a-typography-title>
        </a-form-item>
        <a-form-item style="margin: -20px 0 -10px">
          <a-typography-paragraph>
            {{ app.appDesc }}
          </a-typography-paragraph>
        </a-form-item>
        <a-divider />
        <div v-for="(item, index) in questionContent" :key="index">
          <a-form-item>
            <a-typography-title :heading="5">
              {{ index + 1 }}、{{ item.title }}
            </a-typography-title>
          </a-form-item>
          <a-form-item style="margin-top: -20px">
            <a-radio-group v-model="choices[index]" direction="vertical">
              <a-radio
                :value="option.key"
                v-for="option in item.options"
                :key="option.key"
              >
                <a-typography-text style="font-size: 16px">
                  {{ option.key }}.{{ option.value }}
                </a-typography-text>
              </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-divider />
        </div>
        <a-form-item>
          <a-space size="large">
            <a-button
              type="primary"
              style="width: 100px"
              :loading="isLoad"
              @click="submitQuestion"
            >
              {{ isLoad != true ? "提交答案" : "正在分析中" }}
            </a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, watchEffect, withDefaults } from "vue";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import { listQuestionVoByPageUsingPost } from "@/api/questionController";
import { getAppVoByIdUsingGet } from "@/api/appController";
import { addUserAnswerUsingPost } from "@/api/userAnswerController";
import router from "@/router";
import { generateUserAnswerIdUsingGet } from "@/request/api/userAnswerController";
//答题唯一标识
const id = ref<number>(0);
//表单
const form = {};
//应用
const app = ref<API.AppVO>({});
//所有题目
const questionContent = ref<API.QuestionContentDTO>({});
//答案
const choices = ref<string[]>([]);
//未选答案
const noChoice = ref<number[]>([]);
//是否在加载中
const isLoad = ref(false);

interface Props {
  appId: number;
}

const props = withDefaults(defineProps<Props>(), {
  appId: () => {
    return 0;
  },
});
/**
 * 获取答题唯一标识
 */
const getUserAnswerId = async () => {
  let res: any = await generateUserAnswerIdUsingGet();
  if (res.data.code === 0) {
    id.value = res.data.data;
  } else {
    message.error("获取答题唯一标识失败，" + res.data.message);
  }
};

/**
 * 加载数据
 */
const loadData = async () => {
  if (!props.appId) {
    return;
  }
  //获取应用信息
  let appRes = await getAppVoByIdUsingGet({
    id: props.appId,
  });
  if (appRes.data.code === 0 && appRes.data.data) {
    app.value = appRes.data.data as any;
  } else {
    message.error("获取应用信息失败，" + appRes.data.message);
  }
  //获取题目信息
  const questionRes = await listQuestionVoByPageUsingPost({
    appId: props.appId,
    current: 1,
    pageSize: 1,
    sortField: "createTime",
    sortOrder: "descend",
  });
  if (questionRes.data.code === 0 && questionRes.data.data?.records) {
    questionContent.value =
      questionRes.data.data?.records[0].questionContentDTO;
  }
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
  getUserAnswerId();
});

/**
 * 提交题目
 */
const submitQuestion = async () => {
  if (choices.value.length === 0) {
    message.warning("请先选择答案");
    return;
  }
  //判断是否有未选答案
  noChoice.value = [];
  for (let i = 0; i < choices.value.length; i++) {
    if (!choices.value[i]) {
      noChoice.value.push(i + 1);
    }
  }
  if (noChoice.value.length > 0) {
    message.warning("第" + noChoice.value.join(",") + "题未选择答案");
    return;
  }

  isLoad.value = true;
  const response = await addUserAnswerUsingPost({
    choices: choices.value,
    appId: props.appId,
    id: id.value,
  });
  if (response.data.code === 0) {
    await router.push({
      path: `/answer/result/${response.data.data}`,
      replace: true,
    });
  } else {
    message.error("提交失败，" + response.data.message);
  }
  isLoad.value = false;
};
</script>

<style scoped>
#DoAnswerPage {
  margin: 30px;
}

.doAnswerCart {
  min-width: 500px;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px 50px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 1px 1px #e8e8e8;
}
</style>
