<template>
  <a-form
    :model="formParams"
    layout="inline"
    style="margin: 0 0 10px auto"
    label-align="left"
    auto-label-width
    @submit="search"
  >
    <a-form-item field="id" label="ID：">
      <a-input v-model="formParams.id" placeholder="请输入ID..." allow-clear />
    </a-form-item>
    <a-form-item field="userId" label="用户ID：">
      <a-input
        v-model="formParams.userId"
        placeholder="请输入..."
        allow-clear
      />
    </a-form-item>
    <a-form-item field="title" label="标题：">
      <a-input v-model="formParams.title" placeholder="请输入..." allow-clear />
    </a-form-item>
    <a-form-item field="description" label="描述：">
      <a-input
        v-model="formParams.description"
        placeholder="请输入..."
        allow-clear
      />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" html-type="submit" style="width: 120px"
        >搜索
      </a-button>
    </a-form-item>
  </a-form>

  <a-space size="large">
    <a-form-item>
      <a-button type="primary" @click="add" style="margin: 5px; width: 100px">
        添加题库
      </a-button>
    </a-form-item>
    <a-form-item label="排序：">
      <a-radio-group v-model="formParams.sortOrder" type="button">
        <a-radio value="ascend">正序</a-radio>
        <a-radio value="descend">倒序</a-radio>
      </a-radio-group>
    </a-form-item>
    <a-form-item label="排序字段：">
      <a-radio-group v-model="formParams.sortField" type="button">
        <a-radio value="createTime">创建时间</a-radio>
        <a-radio value="updateTime">更新时间</a-radio>
      </a-radio-group>
    </a-form-item>
  </a-space>

  <div>
    <!--  添加窗口-->
    <a-modal v-model:visible="addVisible" draggable :footer="false">
      <template #title> 添加题库</template>
      <div>
        <a-form :model="addData">
          <a-form-item label="标题">
            <a-input
              v-model="addData.title"
              placeholder="请输入标题..."
              allow-clear
            />
          </a-form-item>
          <a-form-item label="描述">
            <a-textarea
              v-model="addData.description"
              placeholder="请输入描述..."
              allow-clear
            />
          </a-form-item>
          <a-form-item label="题库图标">
            <a-space size="large">
              <a-image
                v-if="addData.picture"
                :src="addData.picture"
                width="80px"
              ></a-image>
              <picture-upload
                biz="bank_icon"
                v-model="addData.picture"
                :onChange="(url) => (addData.picture = url)"
              />
            </a-space>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="addSubmit"> 添加</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
    <!--  修改窗口-->
    <a-modal v-model:visible="updateVisible" draggable :footer="false">
      <template #title> 添加题库</template>
      <div>
        <a-form :model="oldData">
          <a-form-item label="标题">
            <a-input
              v-model="oldData.title"
              placeholder="请输入标题..."
              allow-clear
            />
          </a-form-item>
          <a-form-item label="描述">
            <a-textarea
              v-model="oldData.description"
              placeholder="请输入描述..."
              allow-clear
            />
          </a-form-item>
          <a-form-item label="题目图标">
            <a-space size="large">
              <a-image
                v-if="oldData.picture"
                :src="oldData.picture"
                width="80px"
              ></a-image>
              <picture-upload
                biz="bank_icon"
                v-model="oldData.picture"
                :onChange="(url) => (oldData.picture = url)"
              />
            </a-space>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="updateSubmit"> 修改</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
    <!--  管理窗口-->
    <a-modal v-model:visible="manageVisible" draggable :footer="false">
      <template #title> 管理题库</template>
      <div>
        <a-transfer
          show-search
          :data="allList"
          v-model:model-value="existList"
          one-way
          :source-input-search-props="{
            placeholder: '搜索不存在的题目',
          }"
          :target-input-search-props="{
            placeholder: '搜索已存在的题目',
          }"
          :title="['不存在的题目', '已存在的题目']"
        >
        </a-transfer>
        <a-button
          type="primary"
          @click="allSave()"
          style="margin: 20px 0 0 200px"
        >
          保存
        </a-button>
      </div>
    </a-modal>
    <!--  排序窗口-->
    <a-modal v-model:visible="manageVisible" draggable :footer="false">
      <template #title> 排序题目</template>
      <div>
        <a-transfer
          show-search
          :data="allList"
          v-model:model-value="existList"
          one-way
          :source-input-search-props="{
            placeholder: '搜索不存在的题目',
          }"
          :target-input-search-props="{
            placeholder: '搜索已存在的题目',
          }"
          :title="['不存在的题目', '已存在的题目']"
        >
        </a-transfer>
        <a-button
          type="primary"
          @click="allSave()"
          :loading="batchLoading"
          style="margin: 20px 0 0 200px"
        >
          保存
        </a-button>
      </div>
    </a-modal>
  </div>
  <!--  表格-->
  <a-table
    :columns="columns"
    :data="dataList"
    :pagination="{
      showTotal: true,
      pageSize: searchParams.pageSize,
      current: searchParams.current,
      total,
    }"
    :stripe="true"
    :bordered="{ cell: true }"
    @page-change="onPageChange"
    :loading="loading"
  >
    <template #picture="{ record }">
      <a-image width="64" :src="record.picture" />
    </template>
    <template #reviewStatus="{ record }">
      {{ REVIEW_STATUS_MAP[record.reviewStatus] }}
    </template>
    <template #reviewTime="{ record }">
      {{ dayjs(record.reviewTime).format("YYYY-MM-DD") }}
    </template>
    <template #createTime="{ record }">
      {{ dayjs(record.createTime).format("YYYY-MM-DD") }}
    </template>
    <template #updateTime="{ record }">
      {{ dayjs(record.updateTime).format("YYYY-MM-DD") }}
    </template>
    <template #optional="{ record }">
      <a-space>
        <a-button
          type="outline"
          status="success"
          @click="manageBank(record.id)"
        >
          管理
        </a-button>
        <a-button type="outline" status="success" @click="orderBank(record.id)">
          排序
        </a-button>
        <a-button type="outline" @click="update(record.id)">修改</a-button>
        <a-button type="outline" status="danger" @click="doDelete(record.id)"
          >删除
        </a-button>
      </a-space>
    </template>
    <template #tags="{ record }">
      <a-space wrap>
        <a-tag v-for="(tag, index) of record.tags" :key="index" color="green"
          >{{ tag }}
        </a-tag>
      </a-space>
    </template>
  </a-table>
</template>
<script setup lang="ts">
import { ref, watchEffect } from "vue";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import dayjs from "dayjs";
import { REVIEW_STATUS_MAP } from "@/constant/appEnum";

import {
  addInterviewQuestionBankUsingPost,
  deleteInterviewQuestionBankUsingPost,
  getInterviewQuestionBankVoByIdUsingGet,
  listInterviewQuestionBankByPageUsingPost,
  updateInterviewQuestionBankUsingPost,
} from "@/request/api/interviewQuestionBankController";
import PictureUpload from "@/components/picture/PictureUpload.vue";
import { listAllInterviewQuestionUsingGet } from "@/request/api/interviewQuestionController";
import { batchUpdateInterviewQuestionBankUsingPost } from "@/request/api/interviewQuestionBankQuestionController";
//初始值
const initParams = {
  current: 1,
  pageSize: 10,
  sortOrder: "ascend",
  sortField: "createTime",
};
//搜索参数
const formParams = ref<API.InterviewQuestionBankQueryRequest>({
  ...initParams,
});
//传递参数
const searchParams = ref<API.InterviewQuestionBankQueryRequest>({});
//数据
const dataList = ref<API.InterviewQuestionBankVO[]>([]);
//总值
const total = ref<number>(0);
const loading = ref(false);
/**
 * 加载数据
 */
const loadData = async () => {
  loading.value = true;
  const response = await listInterviewQuestionBankByPageUsingPost(
    searchParams.value
  );
  if (response.data.code === 0) {
    dataList.value = response.data.data?.records || [];
    total.value = response.data.data?.total || 0;
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
  loading.value = false;
};
/**
 * 搜索
 */
const search = () => {
  searchParams.value = {
    ...initParams,
    ...formParams.value,
  };
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * 当分页变化时，改变搜索条件，触发数据加载
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};
/**
 * 添加
 */
const addVisible = ref(false);
const add = async () => {
  addVisible.value = true;
};
//添加的数据
const addData = ref<API.InterviewQuestionBankAddRequest>({});

/**
 * 提交
 */
const addSubmit = async () => {
  const res = await addInterviewQuestionBankUsingPost(addData.value);
  if (res.data.code === 0) {
    loadData();
    message.success("添加成功");
    addVisible.value = false;
    addData.value = {};
  } else {
    message.error("添加失败，" + res.data.message);
  }
};

/**
 * 修改
 */
//旧的数据
const oldData = ref<API.InterviewQuestionBankVO>({});
const updateVisible = ref(false);
const update = async (id: number) => {
  updateVisible.value = true;
  const response = await getInterviewQuestionBankVoByIdUsingGet({
    id: id,
  });
  if (response.data.code === 0) {
    oldData.value = response.data.data || {};
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
};
const updateSubmit = async () => {
  updateVisible.value = true;
  const response = await updateInterviewQuestionBankUsingPost({
    ...oldData.value,
  });
  if (response.data.code === 0) {
    loadData();
    message.success("修改成功");
    updateVisible.value = false;
  } else {
    message.error("修改失败，" + response.data.message);
  }
};

/**
 * 删除
 */
const doDelete = async (id: number) => {
  if (!confirm("确定要删除吗？")) {
    return;
  }
  const res = await deleteInterviewQuestionBankUsingPost({ id });
  if (res.data.code === 0) {
    loadData();
  } else {
    message.error("删除失败，" + res.data.message);
  }
};
/**
 * 管理题库
 */
//所有题目
const allList = ref<any>([]);
//已存在的题目
const existList = ref();
const bankId = ref(0);

const manageVisible = ref(false);
const orderVisible = ref(false);
const batchLoading = ref(false);
// 打开管理界面
const manageBank = async (id: number) => {
  bankId.value = id;
  manageVisible.value = true;
  if (id == null || id == 0) {
    message.error("题库ID不能为空");
    manageVisible.value = false;
    return;
  }
  await loadListQuestion(id);
};

// 打开排序界面
const orderBank = async (id: number) => {
  bankId.value = id;
  orderVisible.value = true;
  if (id == null || id == 0) {
    message.error("题库ID不能为空");
    orderVisible.value = false;
    return;
  }
  await loadListQuestion(id);
};

const loadListQuestion = async (id: number) => {
  //搜索所有题目
  const res = await listAllInterviewQuestionUsingGet({
    bankId: 0,
  });
  if (res.data.code === 0 && res.data.data) {
    allList.value = res.data.data.map((item) => ({
      value: `${item.id}`,
      label: `${item.title}`,
    }));
  } else {
    message.error("加载数据失败，" + res.data.message);
  }
  //搜索已存在的题库内的题目
  const response = await listAllInterviewQuestionUsingGet({
    bankId: id,
  });
  if (response.data.code === 0 && response.data.data) {
    existList.value = response.data.data.map((item) => item.id);
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
};

/**
 * 保存题库
 */
const allSave = async () => {
  if (!confirm("确定要保存吗？")) {
    return;
  }
  batchLoading.value = true;
  const res = await batchUpdateInterviewQuestionBankUsingPost({
    id: bankId.value,
    idList: existList.value,
  });
  if (res.data.code === 0) {
    message.success("保存成功");
    manageVisible.value = false;
  } else {
    message.error("保存失败，" + res.data.message);
  }
  batchLoading.value = false;
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "用户ID",
    dataIndex: "userId",
  },
  {
    title: "标题",
    dataIndex: "title",
  },
  {
    title: "描述",
    dataIndex: "description",
  },
  {
    title: "题库图标",
    dataIndex: "picture",
    slotName: "picture",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    slotName: "createTime",
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    slotName: "updateTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];
</script>
