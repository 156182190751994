<template>
  <a-trigger trigger="click">
    <span>
      <icon-share-alt />
      <a-typography-text> 分享 </a-typography-text>
    </span>
    <template #content>
      <div class="trigger">
        <h3>分享链接</h3>
        <a-typography-paragraph copyable>
          {{ shareLink }}
        </a-typography-paragraph>
        <h3 style="margin-top: 25px">手机扫码查看</h3>
        <a-image :src="codeImg" />
      </div>
    </template>
  </a-trigger>
</template>

<script setup lang="ts">
import QRCode from "qrcode";
import { defineProps, ref, withDefaults } from "vue";

interface Props {
  shareLink: string;
}

const props = withDefaults(defineProps<Props>(), {
  shareLink: () => {
    return "";
  },
});

const codeImg = ref();
// 分享链接
const link =
  props.shareLink == ""
    ? `${window.location.href}`
    : `${"https://" + window.location.host}/${props.shareLink}`;
const shareLink = `${link}`;
// 分享

/**
 * 生成二维码
 */
QRCode.toDataURL(link)
  .then((url: any) => {
    codeImg.value = url;
  })
  .catch((err: any) => {
    console.error(err);
  });
</script>

<style scoped>
.trigger {
  width: auto;
  padding: 10px 24px;
  background-color: var(--color-bg-popup);
  border-radius: 4px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
}
</style>
