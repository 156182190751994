<template>
  <div class="commentCart">
    <a-comment align="right" :avatar="userAvatar">
      <template #actions>
        <a-button type="secondary" @click="cancel"> 取消</a-button>
        <a-button type="primary" @click="comment"> 回复</a-button>
      </template>
      <template #content>
        <a-input v-model="commentText" placeholder="有什么想说的..." />
      </template>
    </a-comment>
    <a-divider />
    <a-spin :loading="commentLoading" tip="评论正在加载" style="width: 100%">
      <a-list
        class="list-demo-action-layout"
        :bordered="false"
        :data="dataList"
      >
        <template #item="{ item }">
          <a-list-item class="list-item" action-layout="vertical">
            <a-comment
              :author="item.userName"
              :avatar="item.userAvatar"
              :content="item.content"
              :datetime="formatDateTime(item.createTime)"
            >
              <template #actions>
                <span class="action" @click="like(item)">
                  <span v-if="item.isThumb">
                    <IconHeartFill :style="{ color: '#f53f3f' }" />
                  </span>
                  <span v-else>
                    <IconHeart />
                  </span>
                  {{ item.thumbNum }}
                </span>
                <a-popover
                  :title="'回复：@' + item.userName"
                  trigger="click"
                  position="rt"
                  style="width: 500px"
                  @popup-visible-change="replyText = ''"
                >
                  <span class="action">
                    <icon-message />
                    回复
                  </span>
                  <template #content>
                    <a-space>
                      <a-input
                        v-model="replyText"
                        placeholder="有什么想说的..."
                        style="width: 400px"
                        allow-clear
                      />
                      <a-button type="primary" @click="replyL1(item)">
                        回复
                      </a-button>
                    </a-space>
                  </template>
                </a-popover>
                <span class="action" v-if="item.isMy" @click="remove(item.id)">
                  <icon-delete />
                </span>
              </template>
              <a-comment
                :avatar="data.userAvatar"
                :content="data.content"
                :datetime="formatDateTime(data.createTime)"
                :key="data.id"
                v-for="data in item.replies"
              >
                <template #actions>
                  <span class="action" @click="like(data)">
                    <span v-if="data.isThumb">
                      <IconHeartFill :style="{ color: '#f53f3f' }" />
                    </span>
                    <span v-else>
                      <IconHeart />
                    </span>
                    {{ data.thumbNum }}
                  </span>
                  <a-popover
                    :title="'回复：@' + item.userName"
                    trigger="click"
                    position="rt"
                    style="width: 500px"
                  >
                    <span class="action">
                      <icon-message />
                      回复
                    </span>
                    <template #content>
                      <a-space>
                        <a-input
                          v-model="replyText"
                          placeholder="有什么想说的..."
                          style="width: 400px"
                        />
                        <a-button type="primary" @click="replyL2(data, item)">
                          回复
                        </a-button>
                      </a-space>
                    </template>
                  </a-popover>
                  <span
                    class="action"
                    v-if="data.isMy"
                    @click="remove(data.id)"
                  >
                    <icon-delete />
                  </span>
                </template>
                <template #author>
                  <span v-if="data.toUserName == null">
                    {{ data.userName }}
                  </span>
                  <span v-else>
                    {{ data.userName }} <icon-arrow-right />
                    {{ data.toUserName }}
                  </span>
                </template>
              </a-comment>
            </a-comment>
          </a-list-item>
        </template>
      </a-list>
    </a-spin>
  </div>
</template>

<script setup lang="ts">
import { IconMessage } from "@arco-design/web-vue/es/icon";
import API from "@/api";
import { defineProps, ref, watchEffect, withDefaults } from "vue";
import { useLoginUserStore } from "@/store/userStore";
import {
  addCommentUsingPost,
  deleteCommentUsingPost,
  doCommentThumbUsingPost,
  getCommentVoByTargetIdUsingPost,
} from "@/request/api/commentController";
import dayjs from "dayjs";
import message from "@arco-design/web-vue/es/message";

interface Props {
  bizId: number;
  bizType: string;
}

const props = withDefaults(defineProps<Props>(), {
  bizId: () => {
    return 0;
  },
  bizType: () => {
    return "";
  },
});

//查询登录用户
const loginUser = ref<API.UserVO>(useLoginUserStore().loginUser);
const userAvatar = ref<string>(
  loginUser.value.userAvatar == null
    ? "https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/9eeb1800d9b78349b24682c3518ac4a3.png~tplv-uwbnlip3yd-webp.webp"
    : loginUser.value.userAvatar
);

const dataList = ref<API.CommentVO[]>([]);

//查询参数
const searchParams = ref<API.InterviewQuestionBankQueryRequest>({
  current: 1,
  pageSize: 20,
});

// 评论内容
const commentText = ref<string>();
// 回复内容
const replyText = ref<string>();
// 评论加载中
const commentLoading = ref(false);

/**
 * 加载评论
 */
const loadComment = async () => {
  if (props.bizId == 0 || props.bizType == "") {
    return;
  }
  commentLoading.value = true;
  const response = await getCommentVoByTargetIdUsingPost({
    bizId: props.bizId,
    bizType: props.bizType,
  });
  if (response.data.code === 0 && response.data.data) {
    dataList.value = response.data.data;
  } else {
    dataList.value = [];
  }
  commentLoading.value = false;
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadComment();
});

/**
 * 格式化日期时间
 */
const formatDateTime = (dateTime: any) => {
  if (dateTime == null) {
    return "";
  }
  const format = dayjs(dateTime).format("YYYY-MM-DD HH:mm:ss");
  return format;
};
/**
 * 取消评论
 */
const cancel = () => {
  commentText.value = "";
};

/**
 * 取消评论
 */
const remove = async (id: number) => {
  const response = await deleteCommentUsingPost({ id: id });
  if (response.data.code === 0) {
    message.success("删除成功");
    loadComment();
  } else {
    message.error("删除失败，" + response.data.message);
  }
};

/**
 * 点赞
 */
const like = (item: any) => {
  if (item.isThumb) {
    item.thumbNum--;
  } else {
    item.thumbNum++;
    message.success("点赞成功");
  }
  item.isThumb = !item.isThumb;
  doCommentThumbUsingPost({ bizId: item.id, bizType: "Comment" });
};
/**
 * 回复
 * @param id
 * @param userId
 */
const replyL2 = async (data: any, item: any) => {
  if (replyText.value == null || replyText.value == "") {
    return;
  }
  const response = await addCommentUsingPost({
    bizId: props.bizId,
    bizType: props.bizType,
    pid: item.id,
    toUserId: data.userId,
    content: replyText.value,
  });
  if (response.data.code === 0) {
    message.success("评论成功");
    replyText.value = "";
    loadComment();
  } else {
    message.error("评论失败，" + response.data.message);
  }
};
/**
 * 回复
 */
const replyL1 = async (item: any) => {
  if (replyText.value == null || replyText.value == "") {
    return;
  }
  const response = await addCommentUsingPost({
    bizId: props.bizId,
    bizType: props.bizType,
    pid: item.id,
    content: replyText.value,
  });
  if (response.data.code === 0) {
    message.success("评论成功");
    replyText.value = "";
    loadComment();
  } else {
    message.error("评论失败，" + response.data.message);
  }
};

/**
 * 进行评论
 */
const comment = async () => {
  if (commentText.value == null || commentText.value == "") {
    return;
  }
  const response = await addCommentUsingPost({
    bizId: props.bizId,
    bizType: props.bizType,
    content: commentText.value,
    pid: 0,
  });
  if (response.data.code === 0) {
    message.success("评论成功");
    commentText.value = "";
    loadComment();
  } else {
    message.error("评论失败，" + response.data.message);
  }
};
</script>

<style scoped>
.commentCart {
  margin-top: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
}

.action {
  display: inline-block;
  padding: 0 4px;
  color: var(--color-text-1);
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.1s ease;
}

.action:hover {
  background: var(--color-fill-3);
}
</style>
