<template>
  <div class="postHome">
    <a-row justify="center">
      <a-col span="16">
        <div class="post">
          <!--  写帖子 -->
          <div class="postCart">
            <div style="padding: 0 0 10px 10px">
              <a-space>
                <a-button type="primary">随便聊</a-button>
                <a-tooltip content="可以上传图片且可编辑样式">
                  <a-button @click="onClickMenuItem('/post/edit/0')">
                    写文章
                  </a-button>
                </a-tooltip>
              </a-space>
            </div>
            <a-textarea
              v-model="post.content"
              :auto-size="{
                minRows: 2,
                maxRows: 5,
              }"
              show-word-limit
              :max-length="5000"
              placeholder="快来和大家讨论吧~"
              class="postText"
            />
            <a-row style="width: auto; font-size: 15px; margin-top: 10px">
              <a-col flex="auto" style="margin-left: 10px">
                <a-space>
                  <a-popover position="bl" trigger="click">
                    <a-button type="text">
                      <icon-face-smile-fill />
                      表情
                    </a-button>
                    <template #content>
                      <EmojiPicker :native="true" @select="onClickEmoji" />
                    </template>
                  </a-popover>
                  <topic-select
                    v-model="post.tags"
                    placeholder="选择你的话题"
                    limit="5"
                  />
                </a-space>
              </a-col>
              <a-col flex="auto"></a-col>
              <a-col flex="70px">
                <a-button
                  type="primary"
                  shape="round"
                  size="large"
                  @click="publish"
                >
                  发布
                </a-button>
              </a-col>
            </a-row>
          </div>
          <div class="context" style="margin-top: 20px">
            <a-space>
              <a-input-search
                placeholder="搜索你感兴趣的帖子吧"
                v-model="searchText"
                @search="search"
                @keydown.enter="search"
              />
              <topic-select
                v-model="searchParams.tags"
                placeholder="选择你感兴趣的话题"
                limit="5"
              />
            </a-space>
            <a-tabs
              size="large"
              centered
              @tabClick="
                (key) => {
                  searchParams.sortField = key;
                }
              "
            >
              <a-tab-pane key="createTime">
                <template #title> 最新</template>
              </a-tab-pane>
              <a-tab-pane key="thumbNum">
                <template #title> 最热</template>
              </a-tab-pane>
            </a-tabs>
            <div class="postList">
              <a-list
                :bordered="false"
                :data="dataList"
                :pagination-props="{
                  pageSize: searchParams.pageSize,
                  current: searchParams.current,
                  total,
                }"
                :stripe="true"
                @page-change="onPageChange"
                :loading="loading"
              >
                <template #item="{ item }">
                  <a-list-item class="list-item" action-layout="vertical">
                    <template #actions>
                      <span class="action" @click="onLikeChange(item)">
                        <span v-if="item.isThumb">
                          <IconHeartFill :style="{ color: '#f53f3f' }" />
                        </span>
                        <span v-else>
                          <IconHeart />
                        </span>
                        {{ item.thumbNum }}
                      </span>
                      <span class="action" @click="onStarChange(item)">
                        <span v-if="item.isFavour">
                          <IconStarFill style="color: #fff35d" />
                        </span>
                        <span v-else>
                          <IconStar />
                        </span>
                        {{ item.favourNum }}
                      </span>
                      <span
                        class="action"
                        @click="onClickMenuItem(`/post/detail/${item.id}`)"
                      >
                        <icon-message />回复
                      </span>
                    </template>
                    <a-list-item-meta style="padding: 10px 0">
                      <template #title>
                        <a-space>
                          <a-avatar>
                            <img alt="avatar" :src="item.user.userAvatar" />
                          </a-avatar>
                          <div>
                            <a-typography-text
                              style="font-size: 16px; font-weight: bold"
                            >
                              {{ item.user.userName }}
                            </a-typography-text>
                            <br />
                            <a-typography-text style="color: #9f9f9f">
                              {{
                                dayjs(item.createTime).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              }}
                            </a-typography-text>
                          </div>
                        </a-space>
                      </template>
                      <template #description>
                        <div
                          @click="onClickMenuItem(`/post/detail/${item.id}`)"
                          class="postContent"
                        >
                          <div v-show="item.title">
                            <a-typography-text
                              style="font-size: 20px; margin-left: 18px"
                            >
                              {{ item.title }}
                            </a-typography-text>
                          </div>
                          <md-viewer :value="item.content" />
                          <br />
                        </div>
                        <a-image-preview-group v-if="item.imgList.length > 0">
                          <a-space size="large" style="margin: 10px 20px 0">
                            <a-image
                              v-for="img in item.imgList"
                              :key="img"
                              :src="img"
                              width="200"
                            />
                          </a-space>
                        </a-image-preview-group>
                        <div v-if="item.tagList.length > 0">
                          <a-space style="margin: 10px 20px 0">
                            <a-tag v-for="tag in item.tagList" :key="tag">
                              {{ tag }}
                            </a-tag>
                          </a-space>
                        </div>
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                </template>
              </a-list>
            </div>
          </div>
        </div>
      </a-col>
      <a-col span="6">
        <user-sider biz-type="post" />
      </a-col>
    </a-row>
  </div>
</template>
<script setup lang="ts">
import API from "@/api";
import { ref, watchEffect } from "vue";
import message from "@arco-design/web-vue/es/message";
import router from "@/router";
import {
  addPostUsingPost,
  listPostVoByPageUsingPost,
} from "@/api/postController";
import dayjs from "dayjs";
import {
  doPostFavourUsingPost,
  doPostThumbUsingPost,
} from "@/request/api/postController";
import UserSider from "@/components/sider/UserSider.vue";
import MdViewer from "@/components/md/MdViewer.vue";
import TopicSelect from "@/components/selecter/TopicSelecter.vue";
import EmojiPicker from "vue3-emoji-picker";
import "vue3-emoji-picker/css";

//查询参数
const searchParams = ref<API.PostQueryRequest>({
  current: 1,
  pageSize: 10,
  sortField: "createTime",
  sortOrder: "descend",
});

//总值
const total = ref<number>(0);
//当前页
const current = ref<number>(0);
//是否显示加载
const loading = ref(true);
// 帖子列表
const dataList = ref<API.PostVO[]>([]);
//搜索关键词
const searchText = ref<string>("");
// 帖子内容
const post = ref<API.PostAddRequest>({
  content: "",
  tags: [],
});
/**
 * 搜索
 */
const search = () => {
  searchParams.value = {
    ...searchParams.value,
    current: 1,
    searchText: searchText.value,
  };
};

/**
 * 加载数据
 */
const loadData = async () => {
  loading.value = true;
  const response = await listPostVoByPageUsingPost(searchParams.value);
  if (response.data.code === 0 && response.data.data) {
    dataList.value = response.data.data?.records || [];
    total.value = response.data.data?.total || 0;
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
  loading.value = false;
};

/**
 * 当分页变化时，改变搜索条件，触发数据加载
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

/**
 * 发布
 */
const publish = async () => {
  if (post.value.content == "") {
    message.error("请输入帖子内容");
    return;
  }
  const response = await addPostUsingPost(post.value);
  if (response.data.code === 0 && response.data.data) {
    message.success("发布成功");
    post.value.content = "";
    post.value.tags = [];
    loadData();
  } else {
    message.error("发布失败，" + response.data.message);
  }
};
/**
 * 点击菜单项
 * @param id
 */
const onClickMenuItem = async (url: string) => {
  router.push(url);
};

const onClickEmoji = (emoji: any) => {
  post.value.content += emoji.i;
};
/**
 * 点赞
 */
const onLikeChange = (item: any) => {
  if (item.isThumb == true) {
    item.thumbNum--;
  } else {
    item.thumbNum++;
  }
  item.isThumb = !item.isThumb;
  doPostThumbUsingPost({ bizId: item.id, bizType: "Post" });
};

/**
 * 收藏
 */
const onStarChange = (item: any) => {
  if (item.isFavour == true) {
    item.favourNum--;
  } else {
    item.favourNum++;
  }
  item.isFavour = !item.isFavour;
  doPostFavourUsingPost({ bizId: item.id, bizType: "Post" });
};
/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  if (searchParams.value) {
    loadData();
  }
});
</script>
<style scoped>
.postHome {
  min-width: 50%;
  max-width: 1500px;
  margin: 0 auto;
}

.postCart {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 1px 1px #e8e8e8;
}

.postText {
  padding: 20px;
  border: 0px;
  border-radius: 20px;
}

.context {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 1px 1px #e8e8e8;
}

.action {
  padding: 0 4px;
  color: var(--color-text-1);
  line-height: 24px;
  background: transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.1s ease;
}

.action:hover {
  background: var(--color-fill-3);
}

.list-action-layout .image-area {
  width: 183px;
  height: 119px;
  overflow: hidden;
  border-radius: 2px;
}

.list-action-layout .list-item {
  padding: 20px 0;
  border-bottom: 1px solid var(--color-fill-3);
}

.list-action-layout .image-area img {
  width: 100%;
}

.list-action-layout .arco-list-item-action .arco-icon {
  margin: 0 4px;
}

.postContent {
  max-height: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
