<template>
  <div class="myUserPage">
    <div class="myUserForm">
      <a-space size="large">
        <a-avatar
          style="width: 60px; height: 60px"
          :image-url="loginUser.userAvatar"
        />
        <a-space direction="vertical" style="margin-top: -20px">
          <a-space size="large">
            <a-typography-title :heading="5">
              {{ loginUser.userName }}
            </a-typography-title>
            <a-space style="margin-top: 10px">
              <img src="../../assets/vip.svg" style="width: 20px" />
            </a-space>
          </a-space>

          <a-typography-text style="margin-top: -10px" type="secondary">
            {{ loginUser.userProfile ? loginUser.userProfile : "暂无简介" }}
          </a-typography-text>
        </a-space>
      </a-space>
    </div>
    <div class="myUserContent">
      <a-tabs lazy-load>
        <a-tab-pane key="post">
          <template #title>
            <icon-bookmark />
            帖子
          </template>
          <post-list :user-id="loginUser.id" />
        </a-tab-pane>
        <a-tab-pane key="app">
          <template #title>
            <icon-apps />
            应用
          </template>
          <app-list :user-id="loginUser.id" />
        </a-tab-pane>
        <a-tab-pane key="postFavour">
          <template #title>
            <icon-star />
            帖子收藏
          </template>
          <post-favour-list />
        </a-tab-pane>
        <a-tab-pane key="appFavour">
          <template #title>
            <icon-star />
            应用收藏
          </template>
          <app-favour-list />
        </a-tab-pane>
        <a-tab-pane key="userAnswer">
          <template #title>
            <icon-clock-circle />
            结果答案记录
          </template>
          <answer-list :user-id="loginUser.id" />
        </a-tab-pane>
        <a-tab-pane key="brushingRecords">
          <template #title>
            <icon-clock-circle />
            刷题记录
          </template>
          <calender-chart />
        </a-tab-pane>
        <a-tab-pane key="setting">
          <template #title>
            <icon-settings />
            设置
          </template>
          <user-edit-page />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script setup lang="ts">
import API from "@/api";
import { ref } from "vue";
import { useLoginUserStore } from "@/store/userStore";
import CalenderChart from "@/components/checkIn/CalenderChart.vue";
import PostList from "@/views/user/PostList.vue";
import AppList from "@/views/user/AppList.vue";
import AnswerList from "@/views/user/AnswerList.vue";
import UserEditPage from "@/components/trigger/UserEditPage.vue";
import AppFavourList from "@/views/user/AppFavourList.vue";
import PostFavourList from "@/views/user/PostFavourList.vue";
//查询登录用户
const loginUserStore = useLoginUserStore();
const loginUser = ref<API.UserVO>(loginUserStore.loginUser);
</script>

<style scoped>
.myUserPage {
  max-width: 1400px;
  margin: 0 auto;
}

.myUserForm {
  padding: 20px 50px;
  background-color: white;
  border-radius: 20px;
}

.myUserContent {
  margin-top: 20px;
  padding: 20px 50px;
  background-color: white;
  border-radius: 20px;
}
</style>
