<template>
  <div id="addScoringResultForm">
    <a-space>
      <h2>评分结果{{ updateId == 0 ? "创建" : "修改" }}</h2>
      <div>
        <a-button @click="visible = true">
          <h2>查看案例</h2>
        </a-button>
        <a-modal v-model:visible="visible" width="700px" :footer="false">
          <template #title>
            <h2>案例</h2>
          </template>
          <div v-if="app.appType === APP_TYPE_ENUM.TEST">
            <img src="../../../assets/answerCase1.png" style="width: 600px" />
            <img src="../../../assets/answerCase2.png" style="width: 600px" />
          </div>
          <div v-else>
            <img src="../../../assets/answerCase3.png" style="width: 600px" />
            <img src="../../../assets/answerCase4.png" style="width: 600px" />
          </div>
        </a-modal>
      </div>
    </a-space>
    <br />
    <a-form
      :model="form"
      :style="{ width: '400px' }"
      label-align="left"
      auto-label-width
      @submit="submitScoringResult"
    >
      <a-form-item label="应用 ID ">
        {{ props.appId }}
      </a-form-item>
      <a-form-item label="修改的结果 ID " v-if="updateId != 0">
        <a-space size="large">
          {{ updateId }}
          <a-button type="text" @click="cancelUpdate">取消</a-button>
        </a-space>
      </a-form-item>
      <a-space label="" v-if="updateId" size="large"></a-space>
      <a-form-item label="评分结果名称" required>
        <a-input
          v-model="form.resultName"
          placeholder="请输结果名称..."
          allow-clear
        />
      </a-form-item>
      <a-form-item label="结果描述">
        <a-input
          v-model="form.resultDesc"
          placeholder="请输入结果描述..."
          allow-clear
        />
      </a-form-item>
      <a-form-item label="评分结果图标" tooltip="输入图标链接">
        <picture-upload
          biz="result_picture"
          :value="form.picture"
          :onChange="(url) => (form.picture = url)"
        />
      </a-form-item>
      <a-form-item
        label="评分结果集"
        required
        v-if="app.appType === APP_TYPE_ENUM.TEST"
      >
        <a-input-tag
          v-model="form.resultProp"
          placeholder="请输结果结果集（按回车即可）"
          allow-clear
        ></a-input-tag>
      </a-form-item>
      <a-form-item
        label="结果得分范围"
        required
        v-if="app.appType === APP_TYPE_ENUM.SCORE"
      >
        <a-input-number
          v-model="form.resultScoreRange"
          placeholder="请输结果得分范围..."
          allow-clear
        ></a-input-number>
      </a-form-item>

      <a-button
        type="primary"
        html-type="submit"
        style="width: 100px; margin: 0 auto 10px"
        >{{ updateId == 0 ? "创建" : "修改" }}评分结果
      </a-button>
    </a-form>
  </div>

  <div class="resultTable">
    <h2>评分结果管理</h2>
    <a-form
      layout="inline"
      style="margin: 0 0 10px auto"
      label-align="left"
      auto-label-width
      @submit="search"
    >
      <a-form-item field="resultName" label="结果名：">
        <a-input
          v-model="searchParams.resultName"
          placeholder="请输入..."
          allow-clear
        />
      </a-form-item>
      <a-form-item field="resultDesc" label="结果描述：">
        <a-input
          v-model="searchParams.resultDesc"
          placeholder="请输入..."
          allow-clear
        />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit" style="width: 120px"
          >搜索
        </a-button>
      </a-form-item>
    </a-form>
    <a-table
      :columns="columns"
      :data="dataList"
      :pagination="{
        showTotal: true,
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total,
      }"
      :stripe="true"
      :bordered="{ cell: true }"
      @page-change="onPageChange"
    >
      <template #resultPicture="{ record }">
        <a-image width="64" :src="record.resultPicture" />
      </template>
      <template #createTime="{ record }">
        {{ dayjs(record.createTime).format("YYYY-MM-DD") }}
      </template>
      <template #optional="{ record }">
        <a-space>
          <a-button type="primary" @click="doUpdate(record)">修改</a-button>
          <a-popconfirm content="确定要删除吗" @ok="doDelete(record.id)">
            <a-button status="danger">删除</a-button>
          </a-popconfirm>
        </a-space>
      </template>
    </a-table>
  </div>
</template>
<script setup lang="ts">
import { defineProps, ref, watchEffect, withDefaults } from "vue";
import API from "@/api";
import {
  addScoringResultUsingPost,
  deleteScoringResultUsingPost,
  editScoringResultUsingPost,
  listMyScoringResultVoByPageUsingPost,
} from "@/api/scoringResultController";
import message from "@arco-design/web-vue/es/message";
import dayjs from "dayjs";
import { useRouter } from "vue-router";
import { APP_TYPE_ENUM } from "@/constant/appEnum";
import { getAppVoByIdUsingGet } from "@/api/appController";
import PictureUpload from "@/components/picture/PictureUpload.vue";

interface Props {
  appId: number;
}

//
const props = withDefaults(defineProps<Props>(), {
  appId: () => {
    return 0;
  },
});

const router = useRouter();
// 添加表单或修改
const form = ref<API.ScoringResultAddRequest>({});
//要更新的Id
const updateId = ref<number>(0);

//传递参数
const searchParams = ref<API.ScoringResultQueryRequest>({
  appId: props.appId,
  current: 1,
  pageSize: 10,
  sortField: "createTime",
  sortOrder: "descend",
});
//数据
const dataList = ref<API.ScoringResultVO[]>([]);
//总值
const total = ref<number>(0);
//应用数据
const app = ref<API.AppVO>({});
const visible = ref(false);
/**
 * 加载数据
 */
const loadData = async () => {
  if (!props.appId) {
    return;
  }
  //获取应用信息
  const appRes = await getAppVoByIdUsingGet({
    id: props.appId,
  });
  if (appRes.data.code === 0 && appRes.data.data) {
    app.value = appRes.data.data;
  } else {
    message.error("获取应用信息失败，" + appRes.data.message);
  }
  const response = await listMyScoringResultVoByPageUsingPost(
    searchParams.value
  );
  if (response.data.code === 0) {
    dataList.value = response.data.data?.records || [];
    total.value = response.data.data?.total || 0;
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
};
/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * 当分页变化时，改变搜索条件，触发数据加载
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};
/**
 * 搜索
 */
const search = () => {
  loadData();
};
/**
 * 创建或编辑评分结果
 */
const submitScoringResult = async () => {
  if (props.appId === 0 || !props.appId) {
    message.error("appId不存在，请重新先选择应用");
    return;
  }
  let response: any;
  //有就是修改
  if (updateId.value) {
    response = await editScoringResultUsingPost({
      ...form.value,
      id: updateId.value as any,
    });
    //有就是修改
  } else {
    response = await addScoringResultUsingPost({
      ...form.value,
      appId: props.appId as any,
    });
  }
  if (response.data.code === 0) {
    message.success("操作成功!");
    form.value = {};
    loadData();
  } else {
    message.error("操作失败，" + response.data.message);
  }
  updateId.value = undefined;
};

/**
 * 更新
 */
const doUpdate = (scoringResult: API.ScoringResultVO) => {
  updateId.value = scoringResult.id;
  form.value = scoringResult;
};
/**
 * 更新
 */
const cancelUpdate = () => {
  updateId.value = 0;
  form.value = {};
};

/**
 * 删除
 */
const doDelete = async (id: number) => {
  const res = await deleteScoringResultUsingPost({ id });
  if (res.data.code === 0) {
    loadData();
  } else {
    message.error("删除失败，" + res.data.message);
  }
};

const columns = [
  {
    title: "结果名称",
    dataIndex: "resultName",
  },
  {
    title: "结果描述",
    dataIndex: "resultDesc",
  },
  {
    title: "结果图片",
    dataIndex: "resultPicture",
    slotName: "resultPicture",
  },
  {
    title: "结果属性",
    dataIndex: "resultProp",
  },
  {
    title: "结果分数范围",
    dataIndex: "resultScoreRange",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    slotName: "createTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];
</script>

<style scoped>
#addScoringResultForm {
  margin: 30px 200px;
  padding: 30px 50px;
  background-color: white;
  border-radius: 20px;
  box-shadow: #9f9f9f;
}

.resultTable {
  margin: 30px 200px;
  padding: 30px 50px;
  background-color: white;
  border-radius: 20px;
  box-shadow: #9f9f9f;
}
</style>
