<template>
  <a-form
    :model="formParams"
    layout="inline"
    style="margin: 0 0 10px auto"
    label-align="left"
    auto-label-width
    @submit="search"
  >
    <a-form-item field="id" label="ID：">
      <a-input v-model="formParams.id" placeholder="请输入ID..." allow-clear />
    </a-form-item>
    <a-form-item field="userId" label="用户ID：">
      <a-input
        v-model="formParams.userId"
        placeholder="请输入..."
        allow-clear
      />
    </a-form-item>
    <a-form-item field="title" label="标题：">
      <a-input v-model="formParams.title" placeholder="请输入..." allow-clear />
    </a-form-item>
    <a-form-item field="content" label="内容：">
      <a-input
        v-model="formParams.content"
        placeholder="请输入..."
        allow-clear
      />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" html-type="submit" style="width: 120px"
        >搜索
      </a-button>
    </a-form-item>
  </a-form>
  <a-space size="large">
    <a-form-item>
      <a-button @click="router.push('/post/edit/0')" type="primary"
        >添加帖子
      </a-button>
    </a-form-item>
    <a-form-item label="排序：">
      <a-radio-group v-model="formParams.sortOrder" type="button">
        <a-radio value="ascend">正序</a-radio>
        <a-radio value="descend">倒序</a-radio>
      </a-radio-group>
    </a-form-item>
    <a-form-item label="排序字段：">
      <a-radio-group v-model="formParams.sortField" type="button">
        <a-radio value="createTime">创建时间</a-radio>
        <a-radio value="updateTime">更新时间</a-radio>
      </a-radio-group>
    </a-form-item>
  </a-space>
  <a-table
    :columns="columns"
    :data="dataList"
    :pagination="{
      showTotal: true,
      pageSize: searchParams.pageSize,
      current: searchParams.current,
      total,
    }"
    :stripe="true"
    :bordered="{ cell: true }"
    :loading="loading"
    @page-change="onPageChange"
  >
    <template #content="{ record }">
      <a-button
        @click="$modal.info({ title: '查看问题内容', content: record.content })"
        >查看内容
      </a-button>
    </template>

    <template #imgList="{ record }">
      <a-button
        type="primary"
        v-if="record.imgList?.length > 0"
        @click="showImgList(record)"
        >查看图片
      </a-button>
    </template>

    <template #createTime="{ record }">
      {{ dayjs(record.createTime).format("YYYY-MM-DD") }}
    </template>
    <template #updateTime="{ record }">
      {{ dayjs(record.updateTime).format("YYYY-MM-DD") }}
    </template>
    <template #optional="{ record }">
      <a-space>
        <a-button type="outline" @click="doUpdate(record.id)">修改</a-button>
        <a-button type="outline" status="danger" @click="doDelete(record.id)"
          >删除
        </a-button>
      </a-space>
    </template>
    <template #tags="{ record }">
      <a-space wrap>
        <a-tag v-for="(tag, index) of record.tags" :key="index" color="green"
          >{{ tag }}
        </a-tag>
      </a-space>
    </template>
  </a-table>
  <a-modal v-model:visible="imgListVisible" :mask="false">
    <template #title>图片预览</template>
    <a-list>
      <a-list-item v-for="(img, index) of post?.imgList" :key="index">
        <a-image :src="img" width="100" height="100" />
      </a-list-item>
    </a-list>
  </a-modal>
</template>
<script setup lang="ts">
import { ref, watchEffect } from "vue";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import dayjs from "dayjs";
import {
  deletePostUsingPost,
  listPostByPageUsingPost,
} from "@/api/postController";
import router from "@/router";
//初始值
const initParams = {
  current: 1,
  pageSize: 10,
  sortOrder: "ascend",
  sortField: "createTime",
};
//搜索参数
const formParams = ref<API.PostQueryRequest>({
  ...initParams,
});
//传递参数
const searchParams = ref<API.PostQueryRequest>({});
//数据
const dataList = ref<API.Post[]>([]);
//总值
const total = ref<number>(0);
const loading = ref(false);

const imgListVisible = ref(false);

const post = ref<API.Post>({});
const showImgList = (item: any) => {
  post.value = item;
  imgListVisible.value = true;
};
/**
 * 加载数据
 */
const loadData = async () => {
  loading.value = true;
  const response = await listPostByPageUsingPost(searchParams.value);
  if (response.data.code === 0) {
    dataList.value = response.data.data?.records || [];
    total.value = response.data.data?.total || 0;
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
  loading.value = false;
};
/**
 * 搜索
 */
const search = () => {
  searchParams.value = { ...initParams, ...formParams.value };
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * 当分页变化时，改变搜索条件，触发数据加载
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

/**
 * 更新
 */
const doUpdate = (id: number) => {
  router.push(`/post/edit/${id}`);
};

/**
 * 删除
 */
const doDelete = async (id: number) => {
  const res = await deletePostUsingPost({ id });
  if (res.data.code === 0) {
    loadData();
  } else {
    message.error("删除失败，" + res.data.message);
  }
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "用户ID",
    dataIndex: "userId",
  },
  {
    title: "标题",
    dataIndex: "title",
  },
  {
    title: "内容",
    dataIndex: "content",
    slotName: "content",
  },
  {
    title: "图片",
    dataIndex: "imgList",
    slotName: "imgList",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    slotName: "createTime",
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    slotName: "updateTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
  {
    title: "标签",
    slotName: "tags",
  },
];
</script>
