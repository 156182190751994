<template>
  <div id="app">
    <template v-if="route.path.startsWith('/user')">
      <user-layout />
    </template>
    <template v-else-if="route.path.startsWith('/admin')">
      <admin-layout></admin-layout>
    </template>
    <template v-else>
      <basic-layout />
    </template>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import BasicLayout from "@/layouts/BasicLayout.vue";
import UserLayout from "@/layouts/UserLayout.vue";
import { onMounted } from "vue";
import AdminLayout from "@/layouts/AdminLayout.vue";

const route = useRoute();
/**
 * 全局初始化函数
 */
const doInit = () => {
  console.log();
};

onMounted(() => {
  doInit();
});
</script>
