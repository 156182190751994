<template>
  <div class="interviewQuestionDetailPage">
    <a-row class="all">
      <a-col span="4">
        <div class="interviewQuestionList">
          <a-menu v-model="dataList" :selected-keys="questionId">
            <template #title> 题目列表</template>
            <a-input-search
              placeholder="搜索题目..."
              v-model="searchText"
              @change="search"
              allow-clear
              style="margin-bottom: 10px"
            />
            <a-menu-item
              v-for="data in dataList"
              :key="data.id"
              @click="onClickMenuItem(data.id)"
            >
              <span> {{ data.priority }}. {{ data.title }} </span>
            </a-menu-item>
            <a-pagination
              style="margin: 0 20px"
              :total="total"
              :page-size="searchParams.pageSize"
              v-model:current="searchParams.current"
              simple
            />
          </a-menu>
        </div>
      </a-col>
      <a-col span="14">
        <div class="interviewQuestionDetail">
          <a-layout-content hide-trigger collapsible>
            <!--  题目信息 -->
            <a-spin
              :loading="questionLoading"
              tip="题目正在加载"
              style="width: 100%"
            >
              <div class="questionCart">
                <a-form-item style="padding: 0px 20px">
                  <a-typography-title :heading="2" bold>
                    {{ data.title }}
                  </a-typography-title>
                </a-form-item>
                <a-form-item style="padding: 0px 20px" v-if="data.content">
                  <a-typography-text
                    style="margin-top: -20px; padding-right: 40px"
                  >
                    {{ data.content }}
                  </a-typography-text>
                </a-form-item>
                <a-form-item style="padding: 0px 20px">
                  <a-space>
                    <a-tag v-for="tag in data.tagList" :key="tag"
                      >{{ tag }}
                    </a-tag>
                  </a-space>
                </a-form-item>
                <a-divider />
                <a-row
                  style="font-size: 15px; padding-bottom: 10px"
                  justify="space-between"
                >
                  <a-col :span="2" style="margin: 0 auto">
                    <a-space class="action">
                      <IconEye />
                      <a-typography-text> {{ data.viewNum }}</a-typography-text>
                    </a-space>
                  </a-col>

                  <a-col :span="2" style="margin: 0 auto">
                    <a-space @click="onLikeChange" class="action">
                      <span v-if="like">
                        <IconHeartFill :style="{ color: '#f53f3f' }" />
                      </span>
                      <span v-else>
                        <IconHeart />
                      </span>
                      <a-typography-text>
                        {{ data.thumbNum }}
                      </a-typography-text>
                    </a-space>
                  </a-col>

                  <a-col :span="2" style="margin: 0 auto">
                    <a-space @click="onStarChange" class="action">
                      <span v-if="star">
                        <IconStarFill style="color: #fff35d" />
                      </span>
                      <span v-else>
                        <IconStar />
                      </span>
                      <a-typography-text>
                        {{ data.favourNum }}
                      </a-typography-text>
                    </a-space>
                  </a-col>
                  <a-col :span="2" style="margin: 0 auto">
                    <a-space class="action">
                      <share-trigger></share-trigger>
                    </a-space>
                  </a-col>
                </a-row>
              </div>
            </a-spin>

            <div class="context" style="margin-top: 20px">
              <a-tabs size="large" centered>
                <a-tab-pane key="1">
                  <template #title>
                    <IconEye />
                    查看答案
                  </template>
                  <a-spin
                    :loading="questionLoading"
                    tip="答案正在加载"
                    style="width: 100%"
                  >
                    <md-viewer :value="data.answer"></md-viewer>

                    <div v-if="data.answer === '该面试题需要VIP才能查看答案'">
                      <a-button
                        @click="clickVip"
                        shape="round"
                        size="large"
                        type="primary"
                        status="warning"
                        style="width: 100px"
                        >开通VIP
                      </a-button>
                    </div>
                  </a-spin>
                </a-tab-pane>
                <a-tab-pane key="2">
                  <template #title>
                    <IconEyeInvisible />
                    隐藏答案
                  </template>
                  <a-empty>
                    <template #image>
                      <icon-eye-invisible />
                    </template>
                    答案已隐藏
                  </a-empty>
                </a-tab-pane>
              </a-tabs>
            </div>

            <div class="next" style="margin-top: 20px" v-if="props.bankId != 0">
              <a-row>
                <a-col flex="50px" style="margin: 0 auto">
                  <a-link>上一题</a-link>
                </a-col>
                <a-col flex="auto"></a-col>
                <a-col flex="50px" style="margin: 0 auto">
                  <a-link>下一题</a-link>
                </a-col>
              </a-row>
            </div>
            <div class="comment">
              <comment-cart
                :biz-id="questionId"
                biz-type="InterviewQuestion"
              ></comment-cart>
            </div>
          </a-layout-content>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script setup lang="ts">
import API from "@/api";
import { defineProps, onMounted, ref, watchEffect, withDefaults } from "vue";
import message from "@arco-design/web-vue/es/message";
import {
  doInterviewQuestionFavourUsingPost,
  doInterviewQuestionThumbUsingPost,
  getInterviewQuestionVoByIdUsingGet,
  listInterviewQuestionVoByPageUsingPost,
  listMyInterviewQuestionFavourPostByPageUsingPost,
} from "@/request/api/interviewQuestionController";
import ShareTrigger from "@/components/trigger/ShareTrigger.vue";
import CommentCart from "@/components/list/CommentCart.vue";
import MdViewer from "@/components/md/MdViewer.vue";
import router from "@/router";

interface Props {
  bankId: number;
  id: number;
  userId: number;
}

const props = withDefaults(defineProps<Props>(), {
  bankId: () => {
    return 0;
  },
  id: () => {
    return 0;
  },
  userId: () => {
    return 0;
  },
});

//查询参数
const searchParams = ref<API.InterviewQuestionBankQueryRequest>({
  current: 1,
  pageSize: 20,
  sortOrder: "descend",
  sortField: "createTime",
});

//查询参数
const searchBankParams = ref<API.InterviewQuestionBankQueryRequest>({
  current: 1,
  pageSize: 20,
  id: props.bankId,
  searchText: "",
});

const questionId = ref<number>(props.id);
const no = ref<number>(1);
const data = ref<API.InterviewQuestionVO>({});
const dataList = ref<API.InterviewQuestionVO[]>([]);
const total = ref<number>(0);
//搜索关键词
const searchText = ref<string>("");
const like = ref<boolean>(false);
const star = ref<boolean>(false);
const questionLoading = ref<boolean>(false);
/**
 * 搜索
 */
const search = async () => {
  //如果不是题库内就搜索所有的题目
  if (props.bankId == 0) {
    const res = await listInterviewQuestionVoByPageUsingPost({
      searchText: searchText.value,
    });
    if (res.data.code === 0) {
      dataList.value = res.data.data?.records || [];
      total.value = res.data.data?.total || 0;
    } else {
      message.error("加载数据失败，" + res.data.message);
    }
  } else if (props.userId != 0) {
    const res = await listMyInterviewQuestionFavourPostByPageUsingPost({
      searchText: searchText.value,
      ...searchParams.value,
    });
    if (res.data.code === 0) {
      dataList.value = res.data.data?.records || [];
    } else {
      message.error("加载数据失败，" + res.data.message);
    }
  } else {
    //如果是题库内就搜索题库内的题目
    const res = await listInterviewQuestionVoByPageUsingPost(
      searchBankParams.value
    );
    if (res.data.code === 0) {
      dataList.value = res.data.data?.records || [];
    } else {
      message.error("加载数据失败，" + res.data.message);
    }
  }
};
/**
 * 加载数据
 */
const loadData = async () => {
  if (!questionId.value || questionId.value <= 0) {
    return;
  }
  console.log("loadData", questionId.value);
  questionLoading.value = true;
  const response = await getInterviewQuestionVoByIdUsingGet({
    id: questionId.value,
  });

  if (response.data.code === 0 && response.data.data) {
    data.value = response.data.data;
    like.value = data.value.isThumb;
    star.value = data.value.isFavour;
  } else {
    message.error("加载题目数据失败，" + response.data.message);
    message.error("请重新选择题目");
  }
  questionLoading.value = false;
};
/**
 * 加载左侧列表数据
 */
const loadListData = async () => {
  let response = null;
  //如果不是题库内就搜索所有的题目
  if (props.bankId != 0) {
    //如果是题库内就搜索题库内的题目
    response = await listInterviewQuestionVoByPageUsingPost({
      searchText: searchText.value,
      questionBankId: props.bankId,
      ...searchParams,
    });
  } else if (props.userId != 0) {
    //如果是收藏的题目
    response = await listMyInterviewQuestionFavourPostByPageUsingPost(
      searchParams.value
    );
  } else {
    response = await listInterviewQuestionVoByPageUsingPost(searchParams.value);
  }
  if (response.data.code === 0 && response.data.data) {
    dataList.value = response.data.data?.records || [];
    total.value = response.data.data?.total || 0;
    dataList.value.map((item, index) => {
      const current = searchParams.value.current as number;
      const pageSize = searchParams.value.pageSize as number;
      // 给标题加序号
      item.priority = index + 1 + (current - 1) * pageSize;
    });
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
  if (questionId.value == -1) {
    questionId.value = dataList.value[0]?.id as number;
  }
};

/**
 * 点击菜单项
 * @param id
 */
const onClickMenuItem = (id: number) => {
  if (questionId.value == id) return;
  questionId.value = id;
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
  loadListData();
});

onMounted(() => {
  loadListData();
  if (props.id == 0) {
    questionId.value = dataList.value[0]?.id as number;
  } else {
    questionId.value = props.id;
  }
});

/**
 * 点赞
 */
const onLikeChange = () => {
  if (like.value) {
    data.value.thumbNum--;
  } else {
    data.value.thumbNum++;
  }
  like.value = !like.value;
  doInterviewQuestionThumbUsingPost({
    bizId: questionId.value,
    bizType: "InterviewQuestion",
  });
};

/**
 * 收藏
 */
const onStarChange = () => {
  if (star.value) {
    data.value.favourNum--;
  } else {
    data.value.favourNum++;
  }
  star.value = !star.value;
  doInterviewQuestionFavourUsingPost({
    bizId: questionId.value,
    bizType: "InterviewQuestion",
  });
};

/**
 * 开通vip
 */
const clickVip = () => {
  router.push("/");
};
</script>
<style scoped>
.all {
  justify-content: center;
  max-width: 2000px;
  margin: 0 auto;
}

.interviewQuestionDetail {
  margin: 0 20px;
}

.questionCart {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 1px 1px #e8e8e8;
}

.interviewQuestionList {
  padding: 20px 10px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 1px 1px #e8e8e8;
}

.context {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 1px 1px #e8e8e8;
}

.comment {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 1px 1px #e8e8e8;
}

.sider {
}

.sider-menu {
  height: 400px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 1px 1px #e8e8e8;
}

.next {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 1px 1px #e8e8e8;
}

.action {
  padding: 0 4px;
  color: var(--color-text-1);
  line-height: 24px;
  background: transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.1s ease;
}

.action:hover {
  background: var(--color-fill-3);
}
</style>
