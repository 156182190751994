<template>
  <div id="searchDiv" style="margin-bottom: 30px">
    <a-form layout="inline" auto-label-width v-if="props.bankId == 0">
      <a-form-item style="margin: 0 auto">
        <a-input-search
          placeholder="你想知道什么面试题"
          search-button
          v-model="searchText"
          @search="searchMysql"
          @keyup.enter="searchMysql"
        >
          <template #button-icon>
            <icon-search />
          </template>
          <template #button-default> 搜索</template>
        </a-input-search>
      </a-form-item>
    </a-form>
  </div>
  <!--  题库列表 -->
  <div class="interviewQuestionListDiv">
    <div class="list-title">
      <a-row>
        <a-col flex="auto">
          <a-space size="large">
            <a-select
              placeholder="请选择题库"
              v-if="props.bankId == 0"
              allow-clear
              allow-search
              @click="loadBankList"
              @clear="searchParams.questionBankId = null"
            >
              <template #prefix>题库：</template>
              <a-option
                v-for="item in bankList"
                :key="item.id"
                @click="searchParams.questionBankId = item.id"
              >
                {{ item.title }}
              </a-option>
            </a-select>
            <a-select
              allow-clear
              v-model="searchParams.needVip"
              style="width: 150px"
            >
              <template #prefix>会员专属：</template>
              <a-option :value="1">是</a-option>
              <a-option :value="0">否</a-option>
            </a-select>
            <TagSelect
              v-model="searchParams.tags"
              placeholder="可选5个标签，可搜索"
              prefix="标签:"
              :limit="5"
            />
          </a-space>
        </a-col>
        <a-col flex="240px" v-if="props.bankId != 0">
          <a-space>
            题目:
            <a-input-search
              style="max-width: 200px"
              placeholder="你想知道什么面试题"
              v-model="searchText"
              @search="searchMysql"
              @keyup.enter="searchMysql"
            >
            </a-input-search>
          </a-space>
        </a-col>
      </a-row>
    </div>
    <a-table
      :data="dataList"
      :loading="loading"
      :bordered="false"
      :columns="columns"
      :pagination="{
        showTotal: true,
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total,
      }"
      @page-change="onPageChange"
      @row-click="click"
    >
      <template #priority="{ record }">
        <a-typography-text type="primary" style="font-size: 16px">
          {{ record.priority }}. {{ record.title }}
        </a-typography-text>
      </template>

      <template #tags="{ record }">
        <a-space>
          <a-tag v-for="tag in record.tagList" :key="tag">
            {{ tag }}
          </a-tag>
        </a-space>
      </template>
      <template #needVip="{ record }">
        <a-tag
          v-if="record.needVip == 1"
          color="#ffb400"
          style="margin-left: -5px"
        >
          VIP
        </a-tag>
      </template>
    </a-table>
  </div>
</template>
<script setup lang="ts">
import { defineProps, ref, watchEffect, withDefaults } from "vue";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import router from "@/router";
import {
  listInterviewQuestionVoByPageUsingPost,
  searchQuestionVoByPageUsingPost,
} from "@/request/api/interviewQuestionController";
import { TableData } from "@arco-design/web-vue";
import { listInterviewQuestionBankVoByPageUsingPost } from "@/request/api/interviewQuestionBankController";
import TagSelect from "@/components/selecter/TagSelecter.vue";

interface Props {
  bankId: number;
  searchParams: API.InterviewQuestionQueryRequest;
}

const props = withDefaults(defineProps<Props>(), {
  bankId: () => {
    return 0;
  },
  searchParams: () => {
    return {};
  },
});

//搜索关键词
const searchText = ref<string>("");
const searchBankText = ref<string>("");
//搜索参数
const searchParams = ref<API.InterviewQuestionQueryRequest>({
  ...props.searchParams,
  current: 1,
  pageSize: 20,
  questionBankId: props.bankId,
});
//数据
const dataList = ref<API.InterviewQuestionVO[]>([]);
const bankList = ref<API.InterviewQuestionBankVO[]>([]);
//总值
const total = ref<number>();
//加载
const loading = ref<boolean>();

/**
 * 搜索
 */
const searchEs = async () => {
  if (searchText.value == "") {
    await loadDataList();
    return;
  }
  const res = await searchQuestionVoByPageUsingPost({
    ...searchParams.value,
    searchText: searchText.value,
  });
  if (res.data.code === 0) {
    dataList.value = res.data.data?.records || [];
    total.value = res.data.data?.total || 0;
  } else {
    message.error("加载数据失败，" + res.data.message);
  }
};

/**
 * 搜索
 */
const searchMysql = async () => {
  loading.value = true;
  // 如果 questionBankId 存在，则按题库查询
  const response = await listInterviewQuestionVoByPageUsingPost({
    ...searchParams.value,
    searchText: searchText.value,
  });
  if (response.data.code === 0) {
    dataList.value = response.data.data?.records || [];
    total.value = response.data.data?.total || 0;
    dataList.value.map((item, index) => {
      const current = searchParams.value.current;
      const pageSize = searchParams.value.pageSize;
      // 给标题加序号
      item.priority = index + 1 + (current - 1) * pageSize;
    });
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
  loading.value = false;
};
/**
 * 加载数据
 */
const loadDataList = async () => {
  loading.value = true;
  // 如果 questionBankId 存在，则按题库查询
  const response = await listInterviewQuestionVoByPageUsingPost({
    ...searchParams.value,
  });
  if (response.data.code === 0) {
    dataList.value = response.data.data?.records || [];
    total.value = response.data.data?.total || 0;
    dataList.value.map((item, index) => {
      const current = searchParams.value.current;
      const pageSize = searchParams.value.pageSize;
      // 给标题加序号
      item.priority = index + 1 + (current - 1) * pageSize;
    });
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
  loading.value = false;
};

const loadBankList = async () => {
  const response = await listInterviewQuestionBankVoByPageUsingPost({
    searchText: searchBankText.value,
  });
  if (response.data.code === 0) {
    bankList.value = response.data.data?.records || [];
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
};
/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadDataList();
});

/**
 * 当分页变化时，改变搜索条件，触发数据加载
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

/**
 * 点击题目
 */
const click = (record: TableData) => {
  if (props.bankId == 0) {
    router.push({
      path: `/interviewQuestion/detail/${record.id}`,
    });
  } else {
    router.push({
      path: `/interviewQuestion/detail/${props.bankId}/${record.id}`,
    });
  }
};

const columns = [
  {
    title: "题目",
    dataIndex: "priority",
    slotName: "priority",
    sortable: {
      sortDirections: ["ascend", "descend"],
    },
  },
  {
    title: "VIP",
    slotName: "needVip",
    dataIndex: "needVip",
    width: 100,
  },
  {
    title: "标签",
    slotName: "tags",
    dataIndex: "tags",
    width: 300,
  },
];
</script>
<style scoped>
.interviewQuestionListDiv {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 1px 1px #e8e8e8;
}

.list-title {
  margin-bottom: 20px;
}
</style>
