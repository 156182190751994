<template>
  <a-row justify="center">
    <a-col span="20">
      <interview-question-bank-page :page-size="8" />
      <div class="interviewQuestionListHome">
        <div style="font-size: 24px; font-weight: bold">面试题目</div>
        <a-row class="interviewQuestionListHome" justify="center">
          <a-col span="17">
            <!--  题库列表 -->
            <interview-question-table :search-params="searchParams" />
          </a-col>
          <a-col span="7" style="margin-top: 62.5px">
            <user-sider biz-type="question" />
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>

<script setup lang="ts">
import { ref } from "vue";
import API from "@/api";
import UserSider from "@/components/sider/UserSider.vue";

import InterviewQuestionTable from "@/components/list/InterviewQuestionTable.vue";
import InterviewQuestionBankPage from "@/views/interview/interviewQuestionBank/InterviewQuestionBankPage.vue";

//搜索参数
const searchParams = ref<API.InterviewQuestionQueryRequest>({
  current: 1,
  pageSize: 20,
  sortField: "createTime",
  sortOrder: "descend",
});
</script>

<style scoped>
.interviewQuestionListHome {
  width: 1200px;
  margin: 0 auto;
}

</style>
